import { TableColumns } from "../../TypeScript_Types/TableColumns";

//this is the names of the columns in experiement list table

export const columnsNames: Readonly<TableColumns[]> = [
  {
    title: 'Experiment Name',
    key: 'name'
  },
  {
    title: 'Date Create',
    key: 'timeStamp'
  },
  {
    title: '#Folder',
    key: 'numberFolders'
  },
  {
    title: '#Permutations',
    key: 'numberPermutations'
  },
  {
    title: 'Actions',
    key: 'actions'
  }
]
