//This has the menu items to be displayed in the side navabar of all authenticated pages.
//Refer HOC > LayoutWrap.tsx
//This component uses current url "location" to show the active menu state

import React from 'react'
import { NavLink } from 'react-router-dom'

//styled components
import { Menu, Icon } from 'antd'

//ant d components
const MenuItem = Menu.Item

//typescript
interface Props {
  location: string
  Logout: () => void
}

//JSX

const SidebarMenu = ({ location, Logout }: Props) => {
  return (
    <Menu mode="inline" theme="dark" selectedKeys={[location]}>
      <MenuItem key="/experiments_list">
        <NavLink to="/experiments_list">
          <Icon type="experiment" style={{ fontSize: '18px' }} />
          <span>Experiments</span>
        </NavLink>
      </MenuItem>

      <MenuItem key="/features_list">
        <NavLink to="/features_list">
          <Icon type="control" style={{ fontSize: '18px' }} />
          <span>Features</span>
        </NavLink>
      </MenuItem>

      <MenuItem key="/brands_list">
        <NavLink to="/brands_list">
          <Icon type="book" style={{ fontSize: '18px' }} />
          <span>Brands</span>
        </NavLink>
      </MenuItem>

      <MenuItem key="/logout">
        <div onClick={Logout}>
          <Icon type="logout" style={{ fontSize: '18px' }} />
          <span>Logout</span>
        </div>
      </MenuItem>
    </Menu>
  )
}

export default SidebarMenu
