//This is the setup for  instantiating required constructor fields and connecting the endpoint
//client will be imported in index.js and hooked to Apollo Provider

import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  HttpLink,
  from
} from 'apollo-boost'
import { onError } from 'apollo-link-error'

//constant
import { Auth } from '../Constants/auth'

//utilities
import { EnvironmentURL } from './EnvironmentURL'
import { Logout } from './Logout'

//Apollo cache
const cache = new InMemoryCache()

//get auth token written in local storage.
const getAuthToken = () => {
  const token = localStorage.getItem(Auth.AUTH_TOKEN)
  return token ? `JWT ${token}` : ''
}

// the endpoint link
// Note - also check for the baseURL in RESTSetup.tsx if any changes
const endpoint = EnvironmentURL()

const httpLink = new HttpLink({
  //uri setup for staging or production env, comment the below code for dev proxy
  uri: `${endpoint}/graphql`
  //setup for dev proxy
  // uri: "/graphql"
})

//global default options - change here to override
const defaultOptions: any = {
  watchQuery: {
    fetchPolicy: 'cache-and-network'
  },
  query: {
    fetchPolicy: 'network-only'
  }
}

//array of unauthorized messages
const unAuthorizedMessages = [
  Auth.LOGOUT_TOKEN.valueOf(),
  Auth.INVALID_TOKEN.valueOf()
]

//afterware
const unAuthorizedInterceptor = onError(({ graphQLErrors }) => {
  //note - backend is not sending unauth error in graphQLErrors & not networkError.
  //if the graphQLErrors message is changed in the backend, it will have to be updated in the 'Auth' enum.
  if (
    graphQLErrors &&
    unAuthorizedMessages.includes(graphQLErrors[0].message)
  ) {
    //logout user
    Logout()
  }
})

//middleware header config
const requestMiddleware = new ApolloLink((operation, forward) => {
  // Use the setContext method to set the HTTP headers.
  operation.setContext({
    headers: {
      authorization: getAuthToken()
    }
  })
  return forward(operation)
})

// apollo client set up to connect to the Apollo Provider - refer index.js
export const client = new ApolloClient({
  // do not change the position of 'httpLink' in the concat Link array
  link: from([requestMiddleware, unAuthorizedInterceptor, httpLink]),
  cache,
  defaultOptions,
  resolvers: {}
})

//writing auth flag to cache during app load
cache.writeData({
  data: {
    isAuthenticated: !!getAuthToken(),
    __typename: 'Auth'
  }
})

