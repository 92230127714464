//Container - login

import React, { Fragment, useState } from 'react'
import { Formik } from 'formik'
import { useMutation, useApolloClient } from '@apollo/react-hooks'

//styled components
import { Row, Col } from 'antd'

//graphql
import { LOGIN_USER } from '../../Graqhql/Login'

//utilities
import ErrorBoundary from '../../Utilities/ErrorBoundary';

//functional components
import LayoutWrap from '../../HOC/LayoutWrap'
import LoginForm from '../../Components/Login/LoginForm'
import ErrorAlert from '../../Components/Reusable_UI/Alerts/ErrorAlerts'
import PageTitle from '../../Components/Reusable_UI/Headers_Titles/PageTitles'

//const title of login page
const PAGE_TITLE = 'Sign In'

//const form fields initial state
const initialFieldValues = { username: '', password: '' }

//types for form fields state
interface FormValues {
  username: string
  password: string
}

const Login: React.FC<any> = props => {
  //local state
  const [loginError, setLoginError] = useState()

  const client = useApolloClient()

  //use mutation hook - extract mutate function, token and save it to the local storage when the request is completed.
  //NOTE - the storage will be changed to improve security

  const [tokenAuth, { loading }] = useMutation(LOGIN_USER, {
    onCompleted({ tokenAuth }) {
      localStorage.setItem('token', tokenAuth.token)
      client.writeData({
        data: { isAuthenticated: !!tokenAuth.token, __typename: 'Auth' }
      })
    },
    onError(error) {
      setLoginError(error)
    }
  })

  //handle submit function
  const handleSubmit = (values: FormValues) => {
    tokenAuth({
      variables: { username: values.username, password: values.password }
    })
  }

  return (
    <LayoutWrap props={props}>
      <Row type="flex" justify="center">
        <Col span={7}>
          {/* title of the page */}
          <PageTitle titleName={PAGE_TITLE} />

          {/* on error during mutation display error message */}
          {loginError && (
            <ErrorAlert
              message={
                loginError.message ? loginError.message : 'Something went wrong'
              }
            />
          )}

          <Formik initialValues={initialFieldValues} onSubmit={handleSubmit}
              validateOnBlur={false}>
            {({ submitCount, values }) => (
              <Fragment>
                {/* login form */}
                <ErrorBoundary>
                <LoginForm
                  submitCount={submitCount}
                  values={values}
                  loading={loading}
                />
                </ErrorBoundary>
              </Fragment>
            )}
          </Formik>
        </Col>
      </Row>
    </LayoutWrap>
  )
}

export default Login
