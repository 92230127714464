//container - edit brand.
import React, { Fragment, useContext, useRef, useEffect, useState } from 'react'
import { Formik } from 'formik'
import { useMutation, useQuery } from '@apollo/react-hooks'

//graphql
import { EDIT_BRAND } from '../../Graqhql/Brands/BrandsMutations'
import { GET_BRANDS, GET_BRAND } from '../../Graqhql/Brands/BrandQuery'

//context
import { EditModalCTX } from '../../Contexts/EditModalContext'

//typescript types
import { BrandsFormValues } from '../../TypeScript_Types/Brands'

//utilities
import ErrorBoundary from '../../Utilities/ErrorBoundary'

//functional components
import EditBrandsForm from '../../Components/Brands/Create_Edit_BrandsForm'
import ModalWrap from '../../Components/Reusable_UI/Modal/ModalFormWrap'
import ErrorAlert from '../../Components/Reusable_UI/Alerts/ErrorAlerts'
import { LoadingSpinner } from '../../Components/Reusable_UI/Loaders_Spinners/LoadingSpinner'

//initial fields
let initialFieldValues: BrandsFormValues = {
  name: '',
  description: '',
  primaryBrandContact: '',
  customerSuccessRep: ''
}

interface Props {
  editBrandId: string
}

//const modal buttons
const buttons = [
  {
    buttonName: 'Edit',
    key: 'edit'
  }
]

//JSX
const EditBrand: React.FC<Props> = ({ editBrandId }) => {
  //local states
  const [brandInfoError, setBrandInfoError] = useState()
  const [brandInfo, setBrandInfo] = useState(initialFieldValues)

  //context
  const {
    handleVisible,
    setEditSuccess,
    setEditErrorMsg,
    editError,
    setEditError,
    editErrorMsg,
    viewEditFormModal
  } = useContext(EditModalCTX)

  //get brand details
  const { data: brandData, loading: brandLoading } = useQuery(GET_BRAND, {
    variables: { id: editBrandId },
    onError(error) {
      setBrandInfoError(error)
    }
  })

  //update intial state
  useEffect(() => {
    if (brandData) {
      setBrandInfo(brandData.brand)
    }
  }, [brandData])

  //ref
  const resetRef: any = useRef(null)

  //useEffect - resetForm do not move calling ref to handleVisible function it will change the transform origin of the modal
  useEffect(() => {
    if (viewEditFormModal) {
      resetRef.current && resetRef.current.click()
    }
  }, [viewEditFormModal])

  //graphql mutation
  const [editBrand, { loading }] = useMutation(EDIT_BRAND, {
    onCompleted() {
      setEditSuccess(true)
      handleVisible()
    },
    onError(error) {
      setEditErrorMsg(error.message)
      setEditError(true)
    },
    refetchQueries: [{ query: GET_BRANDS }]
  })

  //call mutation and submit form
  const handleSubmit = (values: BrandsFormValues) => {
    //mutation input arg
    let input = {
      name: values.name,
      description: values.description,
      primaryBrandContact: values.primaryBrandContact,
      customerSuccessRep: values.customerSuccessRep
    }

    editBrand({ variables: { input, id: editBrandId } })
  }

  return (
    <Fragment>
      {/* edit brand form */}
      <Formik
        enableReinitialize
        initialValues={brandInfo}
        onSubmit={handleSubmit}
        validateOnBlur={false}
      >
        {({ isSubmitting, submitCount, submitForm, handleReset }) => (
          <ModalWrap
            title="Edit Brand"
            buttons={buttons}
            submitForm={submitForm}
            loading={loading}
            handleVisible={handleVisible}
            viewFormModal={viewEditFormModal}
          >
            {/* error alert mutation request, NOTE - success is handled in the parent component */}
            {editError && (
              <ErrorAlert
                message={editErrorMsg ? editErrorMsg : 'Error updating brands.'}
                close={true}
                onErrorClose={() => {
                  setEditError(false)
                  setEditErrorMsg('')
                }}
              />
            )}

            {/* error fetch the brand info */}
            {brandInfoError ? (
              <ErrorAlert
                message={
                  brandInfoError.message
                    ? brandInfoError.message
                    : 'Error fetching brand details.'
                }
              />
            ) : (
              <Fragment>
                {/* display css loader while the brand details are getting downloaded */}
                {brandLoading ? (
                  <LoadingSpinner />
                ) : (
                  <ErrorBoundary>
                    <EditBrandsForm
                      isSubmitting={isSubmitting}
                      submitCount={submitCount}
                    />
                  </ErrorBoundary>
                )}
              </Fragment>
            )}

            {/* reset form div ref */}
            <div onClick={handleReset} ref={resetRef} />
          </ModalWrap>
        )}
      </Formik>
    </Fragment>
  )
}

export default EditBrand
