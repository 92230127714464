import { PermutationFormValues } from '../TypeScript_Types/Permutations'
import { numberWithCommas } from './Format'
import { AllFeaturesItem } from '../TypeScript_Types/Features'
import { ExperimentDiscreteSet } from '../TypeScript_Types/ExperimentTypes'

// clone permutation data helper 
export const formatPermutationData = (
  permutation: any
): PermutationFormValues => {
  const formatNumber = (val: string): string =>
    numberWithCommas(parseFloat(val))

  return {
    name: permutation.name,
    estCost: permutation.estCost ? formatNumber(permutation.estCost) : 0,
    actualCost: permutation.actualCost
      ? formatNumber(permutation.actualCost)
      : 0,
    estImpressions: permutation.estImpressions
      ? formatNumber(permutation.estImpressions)
      : 0,
    actualImpressions: permutation.actualImpressions
      ? formatNumber(permutation.actualImpressions)
      : 0,
    iscs: permutation.iscs,
    notes: permutation.notes,
    channel: permutation.channel,
    medium: permutation.medium,
    brands: permutation.brand.map((brand: { id: string }) => brand?.id),
    folder: permutation.folder,
    variables: permutation.variables?.map(
      (variable: { discreteValue: {id: string} }) => variable.discreteValue.id
    ),
    attributes: permutation.attributes?.map(
      (attribute: { id: string }) => attribute.id
    )
  }
}

export const reduceDiscreteSet = (features: any[]): any =>
  features.reduce(function(
    acc: Array<AllFeaturesItem>,
    obj: ExperimentDiscreteSet
  ) {
    //find if the feature id is already present in the accumulator
    const objIndex = acc.findIndex(
      object => object.id === obj.discreteValue.feature.id
    )

    //create custom object
    if (objIndex === -1) {
      const filteredFeature: AllFeaturesItem = {
        id: obj.discreteValue.feature.id,
        name: obj.discreteValue.feature.name,
        discreteValues: [
          {
            id: obj.discreteValue.id,
            value: obj.discreteValue.value
          }
        ]
      }
      acc.push(filteredFeature)
    } else {
      const objDiscreteValues = {
        id: obj.discreteValue.id,
        value: obj.discreteValue.value
      }
      acc[objIndex].discreteValues.push(objDiscreteValues)
    }
    return acc
  },
  [])
