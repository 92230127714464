//this component will generate form layout for create and edit permutations

import React, { useEffect, useState } from 'react'
import moment from 'moment'
//form handler
import { Form, Field } from 'formik'

//styled components
import {
  Row,
  Col,
  Form as AntForm,
  Input,
  TreeSelect,
  Descriptions,
  Collapse,
  Select
} from 'antd'

//utilities
import {
  validateRequiredArray,
  validateRequired
} from '../../Utilities/Validate'

//typeScript
import {
  PermutationFormsProps,
  PermutationFormField
} from '../../TypeScript_Types/Permutations'
import {
  AllFeaturesItem,
  FeatureTree,
  FeaturePreview,
  DiscreteValues
} from '../../TypeScript_Types/Features'
import { ExperimentDiscreteSet } from '../../TypeScript_Types/ExperimentTypes'

//functional components
import { AntInput } from '../Reusable_UI/Forms/FormFields'
import { AntSelectWithVal } from '../Reusable_UI/Forms/SelectWithVal'
import { checkDecimalNumeric } from '../../Utilities/CheckNumeric'
import { AllBrandItem } from '../../TypeScript_Types/Brands'
import { GetFeaturesTree } from '../../Utilities/FeaturesTree'
import { formatPermutationName } from '../../Utilities/Format'
import { reduceDiscreteSet } from '../../Utilities/Permutation'

//ant design component destructuring
const FormItem = AntForm.Item
const { SHOW_CHILD } = TreeSelect
const DescriptionsItem = Descriptions.Item
const { Panel } = Collapse
const { Option } = Select

const CreateEditPermutation = ({
  submitCount,
  setFieldValue,
  permutationData,
  values,
  initialValues,
  initalDataReady,
  setInitialDataReady
}: PermutationFormsProps): JSX.Element => {
  //local states
  const [featureTreeOptionalData, setFeatureTreeOptionalData]: [
    Array<FeatureTree>,
    any
  ] = useState([])
  const [featureTreeExpData, setFeatureTreeExpData]: [
    Array<FeatureTree>,
    any
  ] = useState([])
  const [optFeatureUpdated, setOptFeatureUpdate] = useState(false)
  const [expFeatureUpdated, setExpFeatureUpdate] = useState(false)
  const [selectedExpFeaturesPreview, setSelectedExpFeaturePreview]: [
    Array<FeaturePreview>,
    any
  ] = useState([])
  const [selectedOptFeaturesPreview, setSelectedOptFeaturePreview]: [
    Array<FeaturePreview>,
    any
  ] = useState([])
  const [brandsList, setBrandsList]: [Array<AllBrandItem>, any] = useState([])
  const [showISCS, setShowISCS] = useState(false)

  //useEffects
  useEffect(() => {
    if (values.channel === 'TV') setShowISCS(true)
    else setShowISCS(false)
  }, [values])

  useEffect(() => {
    if (initialValues) {
      const clonePermutationValues: { [id: string]: any } = initialValues
      const fields = Object.keys(initialValues)
      fields.forEach((field: string) => {
        const permutationValue = clonePermutationValues[field]
        if (permutationValue)
          setFieldValue(field as PermutationFormField, permutationValue)
      })
    }
  }, [initialValues, setFieldValue])

  //update experiment name
  useEffect(() => {
    if (permutationData) {
      const permutationName = formatPermutationName({
        values,
        brandsList,
        permutationData,
        date: moment()
          .utc()
          .format('YYYY-MM')
      })

      setFieldValue('name', permutationName)
    }
  }, [values, permutationData, setFieldValue, brandsList])

  //create custom brandsList for selection from the experimentBrands
  useEffect(() => {
    if (permutationData && initalDataReady) {
      const customBrandsList = permutationData.experiment.experimentBrandSet.map(
        brands => {
          return {
            id: brands.id,
            name: brands.brand.name
          }
        }
      )
      setBrandsList(customBrandsList)

      if (initalDataReady) {
        setInitialDataReady(false)
      }
    }
  }, [permutationData, initalDataReady, setInitialDataReady])

  // create optional & experiment feature data
  useEffect(() => {
    if (permutationData && initalDataReady) {
      //optional features tree
      {
        const optionalFeaturesTree = GetFeaturesTree(
          permutationData.features.filter(feature => !feature.archived)
        )
        setFeatureTreeOptionalData(optionalFeaturesTree)
      }

      //create the tree hierarchial structure from EXPERIMENT FEATURE NAMES
      const customExpFeatureData = reduceDiscreteSet(
        permutationData.experiment.experimentDiscreteSet
      )

      {
        const expFeaturesTree = GetFeaturesTree(customExpFeatureData)
        setFeatureTreeExpData(expFeaturesTree)
      }

      if (initalDataReady) {
        setInitialDataReady(false)
      }
    }
  }, [permutationData, initalDataReady, setInitialDataReady])

  //create preview for selected experimentFeatures
  useEffect(() => {
    //FEATURES - create an oject of selected featureNames with features.
    // since only the IDs are stored when values are selected in the form this is required to create a preview of
    // selected featureNames with their corresponding features.

    //PREVIEW EXPERIMENT FEATURE NAMES - VARIABLES
    const selectedExptFeaturesPreview = (inputValue: Array<string>): any => {
      const selectedFeatures = permutationData.experiment.experimentDiscreteSet.reduce(
        function(acc: Array<FeaturePreview>, obj: ExperimentDiscreteSet) {
          if (inputValue.includes(obj.discreteValue.id)) {
            //find if the feature already exists in the accumulator
            const objIndex = acc.findIndex(
              object => object.featureId === obj.discreteValue.feature.id
            )
            if (objIndex === -1) {
              //create custom object
              const filteredFeature = {
                featureId: obj.discreteValue.feature.id,
                featureName: obj.discreteValue.feature.name,
                discreteValues: [
                  {
                    id: obj.discreteValue.id,
                    value: obj.discreteValue.value
                  }
                ]
              }
              acc.push(filteredFeature)
            } else {
              //replace featureNames in existing object
              acc[objIndex].discreteValues.push({
                id: obj.discreteValue.id,
                value: obj.discreteValue.value
              })
            }
          }
          return acc
        },
        []
      )

      return selectedFeatures
    }

    //PREVIEW OPTIONAL FEATURE NAMES - ATTRIBUTES
    const selectedFeaturesPreview = (inputValue: Array<string>): any => {
      const selectedFeatures = permutationData.features.reduce(function(
        acc: Array<FeaturePreview>,
        obj: AllFeaturesItem
      ) {
        if (obj.discreteValues.some(item => inputValue.includes(item.id))) {
          // filter the featureNames data from feature(obj) that consist the featureName id
          const filterDiscreteValues: Array<DiscreteValues> = obj.discreteValues.filter(
            (featureItem: any) => inputValue.includes(featureItem.id)
          )
          //find if the feature already exists in the accumulator
          const objIndex = acc.findIndex(object => object.featureId === obj.id)
          if (objIndex === -1) {
            //create custom object
            const filteredFeature = {
              featureId: obj.id,
              featureName: obj.name,
              discreteValues: [...filterDiscreteValues]
            }
            acc.push(filteredFeature)
          } else {
            //replace featureNames in existing object
            acc[objIndex].discreteValues = filterDiscreteValues
          }
        }
        return acc
      },
      [])
      return selectedFeatures
    }

    //only if the values is not undefined and experiement feature has been updated this block will run.
    if ((values.variables.length > 0 && initalDataReady) || expFeatureUpdated) {
      //create a new array consisting the feature of the selected discrete values.
      const expFeatureData = selectedExptFeaturesPreview(values.variables)
      setSelectedExpFeaturePreview(expFeatureData)
      setExpFeatureUpdate(false)
    }

    //only if the values is not undefined and experiement feature has been updated this block will run.
    if (
      (values.attributes.length > 0 && initalDataReady) ||
      optFeatureUpdated
    ) {
      //create a new array consisting the feature of the selected discrete values.
      const optFeatureData = selectedFeaturesPreview(values.attributes)
      setSelectedOptFeaturePreview(optFeatureData)
      setOptFeatureUpdate(false)
    }

    if (initalDataReady) {
      setInitialDataReady(false)
    }
  }, [
    values,
    permutationData,
    expFeatureUpdated,
    optFeatureUpdated,
    setInitialDataReady,
    initalDataReady
  ])

  //handle cost changes - verify input and format output with commas
  const handleCost = (e: any, fieldName: any): void => {
    const checkCostVal = checkDecimalNumeric(e.target.value.replace(/[,]/g, ''))
    //format text for UI
    if (checkCostVal === null) {
      e.preventDefault()
    } else {
      setFieldValue(fieldName, checkCostVal)
    }
  }

  return (
    <Form>
      {/* name */}
      <Field
        label="Name"
        name="name"
        type="text"
        readOnly
        placeholder="Permutation name"
        submitCount={submitCount}
        component={AntInput}
      />

      {/* brands */}
      <Field
        label="Select brands from experiment"
        name="brands"
        mode="multiple"
        placeholder="Brands"
        submitCount={submitCount}
        validate={validateRequiredArray}
        selectOptions={brandsList ? brandsList : []}
        optionName="name"
        optionValue="id"
        component={AntSelectWithVal}
        filterPropName="data-searchkey"
      />

      {/* channels and mediums from experiment */}
      <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, 20]}>
        <Col xs={{ span: 12 }}>
          {/* channel */}
          <Field name="channel" validate={validateRequired}>
            {({ field, form }: any) => (
              <FormItem
                label="Channel"
                //field validation
                help={
                  (!values.channel && submitCount > 0) ||
                  (form.touched.channel && !values.channel)
                    ? 'Channel not selected.'
                    : false
                }
                validateStatus={
                  (!values.channel && submitCount > 0) ||
                  (form.touched.channel && !values.channel)
                    ? 'error'
                    : 'success'
                }
              >
                <Select
                  {...field}
                  placeholder="Select channel"
                  allowClear
                  onChange={value => {
                    setFieldValue('channel', value)
                  }}
                  onBlur={() => form.setFieldTouched(field.name, true)}
                >
                  {permutationData.experiment.channels &&
                    permutationData.experiment.channels.map(
                      (channel, index: number) => (
                        <Option key={index} value={channel.toUpperCase()}>
                          {channel}
                        </Option>
                      )
                    )}
                </Select>
              </FormItem>
            )}
          </Field>
        </Col>

        <Col xs={{ span: 12 }}>
          {/* medium */}
          <Field name="medium" validate={validateRequired}>
            {({ field, form }: any) => (
              <FormItem
                label="Medium"
                //field validation
                help={
                  (!values.medium && submitCount > 0) ||
                  (form.touched.medium && !values.medium)
                    ? 'Medium not selected.'
                    : false
                }
                validateStatus={
                  (!values.medium && submitCount > 0) ||
                  (form.touched.medium && !values.medium)
                    ? 'error'
                    : 'success'
                }
              >
                <Select
                  {...field}
                  placeholder="Select medium"
                  allowClear
                  onChange={value => setFieldValue('medium', value)}
                  onBlur={() => form.setFieldTouched(field.name, true)}
                >
                  {permutationData.experiment.mediums &&
                    permutationData.experiment.mediums.map(
                      (medium, index: number) => (
                        <Option key={index} value={medium.toUpperCase()}>
                          {medium}
                        </Option>
                      )
                    )}
                </Select>
              </FormItem>
            )}
          </Field>
        </Col>
      </Row>

      <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, 20]}>
        <Col xs={{ span: 12 }}>
          {/* Folder */}
          <Field
            label="Folder"
            name="folder"
            placeholder="Select folder"
            selectOptions={permutationData.folders}
            optionName="name"
            optionValue="id"
            submitCount={submitCount}
            component={AntSelectWithVal}
          />
        </Col>

        <Col xs={{ span: 12 }} className={showISCS ? '' : 'v-hidden'}>
          {/* iscs */}
          <Field
            label="ISCS code"
            name="iscs"
            type="text"
            allowClear
            placeholder="ISCS code"
            submitCount={submitCount}
            component={AntInput}
          />
        </Col>
      </Row>

      <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, 20]}>
        <Col xs={{ span: 12 }}>
          {/* Estimated impressions */}
          <Field name="estImpressions">
            {({ field, form }: any) => (
              <FormItem label="Estimated impressions">
                <Input
                  {...field}
                  placeholder="Estimated impressions"
                  onChange={(e: any) => handleCost(e, 'estImpressions')}
                  onBlur={() => form.setFieldTouched('estImpressions', true)}
                />
              </FormItem>
            )}
          </Field>
        </Col>

        <Col xs={{ span: 12 }}>
          {/* Impression to date ~ actual impressions */}
          <Field name="actualImpressions">
            {({ field, form }: any) => (
              <FormItem label="Actual impressions">
                <Input
                  {...field}
                  placeholder="Actual impressions"
                  onChange={(e: any) => handleCost(e, 'actualImpressions')}
                  onBlur={() => form.setFieldTouched('actualImpressions', true)}
                />
              </FormItem>
            )}
          </Field>
        </Col>
      </Row>

      <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, 20]}>
        <Col xs={{ span: 12 }}>
          {/* Estimated cost */}
          <Field name="estCost">
            {({ field, form }: any) => (
              <FormItem label="Estimated spend">
                <Input
                  {...field}
                  placeholder="Estimated spend"
                  allowClear
                  prefix="$"
                  onChange={(e: any) => handleCost(e, 'estCost')}
                  onBlur={() => form.setFieldTouched('estCost', true)}
                />
              </FormItem>
            )}
          </Field>
        </Col>

        <Col xs={{ span: 12 }}>
          {/* Spend to date ~ actual cost */}
          <Field name="actualCost">
            {({ field, form }: any) => (
              <FormItem label="Spend to Date">
                <Input
                  {...field}
                  placeholder="Spend to Date"
                  allowClear
                  prefix="$"
                  onChange={(e: any) => handleCost(e, 'actualCost')}
                  onBlur={() => form.setFieldTouched('actualCost', true)}
                />
              </FormItem>
            )}
          </Field>
        </Col>
      </Row>

      {/* Experiment features */}
      <Field name="variables" validate={validateRequiredArray}>
        {({ field, form }: any) => (
          <FormItem
            label="Features from experiment"
            // field validation
            help={
              (form.errors.variables && submitCount > 0) ||
              (form.touched.variables && form.errors.variables)
                ? 'Variables not selected'
                : false
            }
            validateStatus={
              (form.errors.variables && submitCount > 0) ||
              (form.touched.variables && form.errors.variables)
                ? 'error'
                : 'success'
            }
          >
            <TreeSelect
              {...field}
              value={values.variables}
              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
              treeData={featureTreeExpData}
              searchPlaceholder="Select experiment feature values"
              treeCheckable={true}
              allowClear
              showCheckedStrategy={SHOW_CHILD}
              onChange={value => {
                setFieldValue('variables', value)
                setExpFeatureUpdate(true)
              }}
              onBlur={() => form.setFieldTouched(field.name, true)}
              treeNodeFilterProp="title"
            />
          </FormItem>
        )}
      </Field>

      {/* selected experiment features panel */}
      <FormItem>
        <Collapse bordered>
          <Panel header="Preview selected experiment features" key="1">
            {selectedExpFeaturesPreview.length > 0 ? (
              <Descriptions
                layout="vertical"
                bordered
                size="small"
                style={{ background: 'white' }}
              >
                {selectedExpFeaturesPreview.map(previewItem => (
                  <DescriptionsItem
                    label={previewItem.featureName}
                    key={previewItem.featureId}
                  >
                    {previewItem.discreteValues.map(item => (
                      <p key={item.id} style={{ marginBottom: '5px' }}>
                        {item.value}
                      </p>
                    ))}
                  </DescriptionsItem>
                ))}
              </Descriptions>
            ) : (
              <div style={{ textAlign: 'center' }}>
                <p>Features not selected.</p>
              </div>
            )}
          </Panel>
        </Collapse>
      </FormItem>

      {/* optional features - attributes */}
      {/* validate={validateRequiredArray} disabled, field set to optional */}
      <Field name="attributes">
        {({ field, form }: any) => (
          <FormItem label="Optional features (attributes)">
            <TreeSelect
              {...field}
              value={values.attributes}
              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
              treeData={featureTreeOptionalData}
              searchPlaceholder="Select optional feature values"
              treeCheckable={true}
              allowClear
              showCheckedStrategy={SHOW_CHILD}
              onChange={value => {
                setFieldValue('attributes', value)
                setOptFeatureUpdate(true)
              }}
              onBlur={() => form.setFieldTouched(field.name, true)}
              treeNodeFilterProp="title"
            />
          </FormItem>
        )}
      </Field>

      {/* selected optional features panel */}
      <FormItem>
        <Collapse bordered>
          <Panel header="Preview selected optional features" key="1">
            {selectedOptFeaturesPreview.length > 0 ? (
              <Descriptions
                layout="vertical"
                bordered
                size="small"
                style={{ background: 'white' }}
              >
                {selectedOptFeaturesPreview.map(previewItem => (
                  <DescriptionsItem
                    label={previewItem.featureName}
                    key={previewItem.featureId}
                  >
                    {previewItem.discreteValues.map(item => (
                      <p key={item.id} style={{ marginBottom: '5px' }}>
                        {item.value}
                      </p>
                    ))}
                  </DescriptionsItem>
                ))}
              </Descriptions>
            ) : (
              <div style={{ textAlign: 'center' }}>
                <p>Features not selected.</p>
              </div>
            )}
          </Panel>
        </Collapse>
      </FormItem>

      {/* nodes */}
      <Field
        label="Notes"
        name="notes"
        type="text"
        placeholder="Permutation notes"
        submitCount={submitCount}
        component={AntInput}
      />
    </Form>
  )
}

export default CreateEditPermutation
