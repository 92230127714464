import { TableColumns } from "../../TypeScript_Types/TableColumns";

//this is the names of the columns in permutations list table

export const columnsNames: Readonly<TableColumns[]> = [
  {
    title: 'Name',
    key: 'name'
  },
  {
    title: 'Features',
    key: 'features'
  },
  {
    title: '#Flowcodes',
    key: 'flowcodes'
  },
  {
    title: 'Date created',
    key: 'date'
  },
  {
    title: 'Actions',
    key: 'actions'
  }
]
