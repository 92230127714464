//this is the context to handle the modal interaction & states between a container/component buttons and modal.
//Edit modal is used by table/list component and a "Edit" modal component.

//note - context cannot be used in two or more differnt components from the same form/page

import React, { createContext, useState, useEffect } from 'react'

//create context
export const EditModalCTX: any = createContext({})

const EditModalProvider: React.FC<any> = props => {
  const [editSuccess, setEditSuccess] = useState(false)
  const [editError, setEditError] = useState(false)
  const [editErrorMsg, setEditErrorMsg] = useState('')
  //show or hide state of modal
  const [viewEditFormModal, setViewEditFormModal] = useState(false)

  // mount timer and unmount cleanup - set timer for 2secs and remove timer when the component is unmounted
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (editSuccess) {
        setEditSuccess(false)
      }
    }, 2000)

    return () => {
      // clear timeout
      return clearTimeout(timeout)
    }
  }, [editSuccess])

  const handleVisible = () => {
    if (viewEditFormModal) {
      setEditError(false)
      setEditErrorMsg('')
    }
    setViewEditFormModal(prevState => !prevState)
  }

  return (
    <EditModalCTX.Provider
      value={{
        viewEditFormModal,
        handleVisible,
        editSuccess,
        setEditSuccess,
        editError,
        setEditError,
        editErrorMsg,
        setEditErrorMsg
      }}
    >
      {props.children}
    </EditModalCTX.Provider>
  )
}

export default EditModalProvider
