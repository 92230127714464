//This component contains the route/url information of all pages in the app.

import React from 'react'
import { Route, Switch, withRouter, Redirect } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'

//utils
import { IS_AUTHENTICATED } from '../Graqhql/Authenticated'

//Components - NOTE - lazy loading (async) was removed to avoid the fallback loader

//brands
import BrandList from '../Containers/BrandsList/Brands'
//experiments
import CreateExperiment from '../Containers/Experiment/CreateExperiment/CreateExperiment'
import ExperimentsList from '../Containers/ExperimentsList/ExperimenstList'
import EditExperiment from '../Containers/Experiment/EditExperiment/EditExperiment'
import ViewExperiment from '../Containers/Experiment/ViewExperiment/ViewExperiment'
//features
import FeaturesList from '../Containers/FeaturesList/Features'
//permutations
import ViewPermutation from '../Containers/Permutations/ViewPermutation'
//user
import Login from '../Containers/Login/Login'
import Register from '../Containers/Register/Register'

//JSX
const AppRoutes: React.FC = () => {
  // get authenticated state to switch the authenticated or unauthenticated pages
  const { data } = useQuery(IS_AUTHENTICATED)
  const authenticated = data ? data.isAuthenticated : false

  return (
    <Switch>
      {/* routes in reverse alphabetical order */}

      {/* view permutation */}
      <Route
        path="/:exp/:id/view_permutation"
        component={(props: any) =>
          authenticated ? (
            <ViewPermutation {...props} />
          ) : (
            <Redirect to={{ pathname: '/', state: { from: props.location } }} />
          )
        }
      />

      {/* view experiment */}
      <Route
        path="/:id/view_experiment"
        component={(props: any) =>
          authenticated ? (
            <ViewExperiment {...props} />
          ) : (
            <Redirect to={{ pathname: '/', state: { from: props.location } }} />
          )
        }
      />

      {/* register - create user*/}
      <Route
        path="/register"
        component={(props: any) =>
          authenticated ? (
            <Redirect
              to={{
                pathname: '/experiments_list',
                state: { from: props.location }
              }}
            />
          ) : (
            <Register {...props} />
          )
        }
      />

      {/* features - features list*/}

      <Route
        path="/features_list"
        component={(props: any) =>
          authenticated ? (
            <FeaturesList {...props} />
          ) : (
            <Redirect to={{ pathname: '/', state: { from: props.location } }} />
          )
        }
      />

      {/* experiment list */}
      <Route
        path="/experiments_list"
        component={(props: any) =>
          authenticated ? (
            <ExperimentsList {...props} />
          ) : (
            <Redirect to={{ pathname: '/', state: { from: props.location } }} />
          )
        }
      />

      {/* edit experiment */}
      <Route
        path="/:id/edit_experiment"
        component={(props: any) =>
          authenticated ? (
            <EditExperiment {...props} />
          ) : (
            <Redirect to={{ pathname: '/', state: { from: props.location } }} />
          )
        }
      />

      {/* brand  list*/}
      <Route
        path="/brands_list"
        component={(props: any) =>
          authenticated ? (
            <BrandList {...props} />
          ) : (
            <Redirect to={{ pathname: '/', state: { from: props.location } }} />
          )
        }
      />

      {/* create experiment */}
      <Route
        path="/create_experiment"
        component={(props: any) =>
          authenticated ? (
            <CreateExperiment {...props} />
          ) : (
            <Redirect to={{ pathname: '/', state: { from: props.location } }} />
          )
        }
      />

      {/* login */}
      <Route
        path="/"
        component={(props: any) =>
          authenticated ? (
            <Redirect
              to={{
                pathname: '/experiments_list',
                state: { from: props.location }
              }}
            />
          ) : (
            <Login {...props} />
          )
        }
      />
    </Switch>
  )
}

export default withRouter(AppRoutes)
