//This is the component for generating the title in all pages,
//any changes here will reflect in all pages

import React from 'react'

//styled components
import { Typography } from 'antd'

//Ant d components
const { Title } = Typography

//props type
interface title {
  titleName: string
}

const PageTitle = ({ titleName }: title) => {
  return (
    <Title level={3} style={{ marginBottom: 20 }}>
      {titleName}
    </Title>
  )
}

export default PageTitle
