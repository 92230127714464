//This is the context to handle the modal interaction & states between a container/component buttons and modal.
//Create modal is used by table/list component and a "create" modal component.

//note - context cannot be used in two or more differnt components from the same form/page

import React, { createContext, useState, useEffect } from 'react'

//create context
export const DownloadModalContext: React.Context<any> = createContext({})

interface Props {
  children: React.ReactNode
}

const DownloadModalProvider: React.FC<any> = (props: Props) => {
  const [downloadSuccess, setDownloadSuccess] = useState(false)
  const [downloadError, setDownloadError] = useState(false)
  const [downloadErrorMsg, setDownloadErrorMsg] = useState('')
  const [viewDownloadFormModal, setDownloadViewFormModal] = useState(false)

  // mount timer and unmount cleanup - set timer for 2secs and remove timer when the component is unmounted
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (downloadSuccess) {
        setDownloadSuccess(false)
      }
    }, 2000)

    return (): void => {
      return clearTimeout(timeout)
    }
  }, [downloadSuccess])

  const handleVisible = (): void => {
    if (viewDownloadFormModal) {
      setDownloadError(false)
      setDownloadErrorMsg('')
    }
    setDownloadViewFormModal(prevState => !prevState)
  }
  return (
    <DownloadModalContext.Provider
      value={{
        viewDownloadFormModal,
        handleVisible,
        downloadSuccess,
        setDownloadSuccess,
        downloadError,
        setDownloadError,
        downloadErrorMsg,
        setDownloadErrorMsg
      }}
    >
      {props.children}
    </DownloadModalContext.Provider>
  )
}

export default DownloadModalProvider
