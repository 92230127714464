//typescript table columns
import { TableColumns } from "../../TypeScript_Types/TableColumns";

//this is the names of the columns in brands list table

export const columnsNames: Readonly<TableColumns[]> = [
  {
    title: 'Brand Name',
    key: 'name'
  },
  {
    title: 'Brand Contact',
    key: 'contact'
  },
  {
    title: 'Customer Success Rep',
    key: 'customer'
  },
  {
    title: 'Actions',
    key: 'actions'
  }
]