//Mutation - Flowcodes

import gql from 'graphql-tag'

//create batch (flowcodes)
export const CREATE_BATCH = gql`
  mutation(
    $permutationId: UUID
    $input: BatchInputType
    $attributes: [UUID]
    $permutationBrand: [UUID]
  ) {
    createBatch(
      permutationId: $permutationId
      input: $input
      attributes: $attributes
      permutationBrand: $permutationBrand
    ) {
      ok
      batch {
        id
      }
    }
  }
`

//delete batch
export const DELETE_BATCH = gql`
  mutation($id: ID!) {
    deleteBatch(id: $id) {
      ok
    }
  }
`

//update flowcodes
export const UPDATE_FLOWCODES = gql`
  mutation(
    $addBrand: [UUID]
    $deleteBrand: [UUID]
    $id: UUID!
    $input: FlowcodeInputType
  ) {
    updateFlowcode(
      id: $id
      input: $input
      deleteBrand: $deleteBrand
      addBrand: $addBrand
    ) {
      ok
    }
  }
`

//update batch
export const UPDATE_BATCH = gql`
  mutation(
    $addAttributes: [UUID]
    $deleteAttributes: [UUID]
    $id: ID!
    $input: BatchInputType
  ) {
    updateBatch(
      id: $id
      input: $input
      addAttributes: $addAttributes
      deleteAttributes: $deleteAttributes
    ) {
      ok
    }
  }
`

//build a batch
export const BUILD_BATCH = gql`
  mutation($id: ID!, $quantity: Int!) {
    buildBatch(id: $id, quantity: $quantity) {
      ok
    }
  }
`

//build all batch
export const BUILD_ALL = gql`
  mutation($permutationId: UUID!, $quantity: Int!) {
    buildBatches(permutationId: $permutationId, quantity: $quantity) {
      ok
    }
  }
`
