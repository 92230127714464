//This component will generate the flowcode details in a tabular form in view permutation
//This coponent will display the table of the permutation list

import React, { useContext, Fragment } from 'react'

//styled components
import { Dropdown, Menu, Icon, Divider } from 'antd'

//contexts
import { EditModalCTX } from '../../Contexts/EditModalContext'
import { BuildModalCTX } from '../../Contexts/BuildModalContext'

//constants
import { columnsNames } from '../../Constants/Flowcodes/Flowcodes'

//stylesheet
import '../Reusable_UI/Styles.less'

//functional component
import NoData from '../Reusable_UI/NoData/NoData'

//typescript
import { FlowcodesTableProps } from '../../TypeScript_Types/Flowcodes'

//inline styles
const menuStyle = {
  color: '#1890ff',
  cursor: 'pointer',
  margin: '0'
}

// TODO: update typings
const renderFlowcodeFeatures = (attributes: any): JSX.Element[] => {
  const featuresHash = attributes.reduce((prev: any, curr: any) => {
    const featureName = curr?.feature?.name
    if (prev[featureName]) prev[featureName] += `, ${curr.value}`
    else prev[featureName] = `${curr.value}`
    return prev
  }, {})
  const keys = Object.keys(featuresHash)
  const features = keys.map(key => (
    <div key={key}>
      <b>{key}:</b>
      {` `}
      {featuresHash[key]}
    </div>
  ))
  return features
}

//table header titles with center align text
const TableHeaderTitleCenter = ['Download', 'Preview', 'Actions']

const ViewFlowcodesTable = ({
  flowcodeData,
  listLoading,
  setPreviewQRId,
  deleteBatchId,
  handleDeleteBatch,
  setEditBatchId,
  setBuildBatchId,
  deleteLoading,
  downloadQRId,
  downloadQRLoading,
  handleDownloadQR
}: FlowcodesTableProps) => {
  //context
  const { handleVisible } = useContext(EditModalCTX)
  const { handleVisible: buildHandleVisible } = useContext(BuildModalCTX)

  // pop up action menu
  const menu = (rowItemsId: string, batchFlowcodesId: string) => {
    return (
      <Menu>
        <Menu.Item
          key="1"
          onClick={() => {
            setEditBatchId({ id: rowItemsId, flowcodeId: batchFlowcodesId })
            handleVisible()
          }}
        >
          Edit flowcode
        </Menu.Item>
        <Menu.Item key="2" onClick={() => handleDeleteBatch(rowItemsId)}>
          Delete flowcode
        </Menu.Item>
      </Menu>
    )
  }

  return (
    <div style={{ marginTop: 10 }} className="overflow-x-scroll">
      <table className="tableContainer">
        <thead className="tableHead">
          <tr>
            {columnsNames.map(item => (
              <th
                key={item.key}
                colSpan={item.title === 'Flowcodes' ? 4 : 1}
                style={
                  TableHeaderTitleCenter.includes(item.title)
                    ? { textAlign: 'center' }
                    : {}
                }
              >
                {item.title}
              </th>
            ))}
          </tr>
        </thead>

        {/* table list */}

        <tbody className="tableBody">
          {flowcodeData &&
            flowcodeData.permutationBatches.map(
              (rowItems, rowIndex: number) => {
                return (
                  <tr key={rowIndex}>
                    <td>{rowIndex + 1}</td>
                    {/* Type - convert the string to lowercase except the first char */}
                    <td>
                      {rowItems.type.charAt(0) +
                        rowItems.type.substring(1).toLowerCase()}
                    </td>
                    <td>{rowItems.id}</td>

                    {/* flowcodes */}
                    {rowItems.batchFlowcodes.length > 0 ? (
                      rowItems.batchFlowcodes.map(flowcode => (
                        <Fragment key={flowcode.id}>
                          <td>{flowcode.shorturl ? flowcode.shorturl : ''}</td>
                          <td>
                            {flowcode.redirectValue
                              ? flowcode.redirectValue
                              : ''}
                          </td>
                          <td className="flowcode-table__features">
                            {renderFlowcodeFeatures(
                              flowcode?.batch?.attributes
                            )}
                          </td>
                          <td>
                            {flowcode.flowcodeBrandSet &&
                              flowcode.flowcodeBrandSet
                                .map(item => item.permutationBrand.brands.name)
                                .join(', ')}
                          </td>
                          <td>{rowItems.isScan ? 'True' : 'False'}</td>
                        </Fragment>
                      ))
                    ) : (
                      <Fragment>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </Fragment>
                    )}

                    {/* preview icon */}
                    {rowItems.type === 'MANY' ? (
                      <td style={{ textAlign: 'center' }}>-</td>
                    ) : (
                      <Fragment>
                        {rowItems.isBuilt ? (
                          <td
                            className="tableIconDiv"
                            onClick={() => setPreviewQRId(rowItems.id)}
                          >
                            <Icon type="eye" className="tableIcons" />
                          </td>
                        ) : (
                          <td style={{ textAlign: 'center' }}>
                            <Icon
                              type="eye-invisible"
                              className="tableIcons"
                              style={{ color: 'grey' }}
                            />
                          </td>
                        )}
                      </Fragment>
                    )}

                    {/* download icon */}
                    {rowItems.id === downloadQRId && downloadQRLoading ? (
                      <td className="tableIconDiv">
                        {' '}
                        <Icon type="loading" />{' '}
                      </td>
                    ) : (
                      <Fragment>
                        {rowItems.isBuilt ? (
                          <td
                            onClick={() => handleDownloadQR(rowItems.id)}
                            className="tableIconDiv"
                          >
                            <Icon type="download" className="tableIcons" />
                          </td>
                        ) : (
                          <td style={{ textAlign: 'center' }}>-</td>
                        )}
                      </Fragment>
                    )}

                    {/* actions */}
                    {rowItems.id === deleteBatchId && deleteLoading ? (
                      <td style={{ textAlign: 'center' }}>
                        <Icon type="loading" />
                      </td>
                    ) : (
                      // action menu
                      <td>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          {/* In the actions column for ‘one’ flow codes - build shouldn’t be an option */}
                          {rowItems.type !== 'ONE' && (
                            <span
                              style={menuStyle}
                              onClick={() => {
                                setBuildBatchId(rowItems.id)
                                buildHandleVisible()
                              }}
                            >
                              Build
                            </span>
                          )}

                          {rowItems.type !== 'ONE' && (
                            <Divider type="vertical" />
                          )}

                          <Dropdown
                            overlay={() =>
                              menu(rowItems.id, rowItems.batchFlowcodes[0].id)
                            }
                            trigger={['click']}
                          >
                            <span style={menuStyle}>More</span>
                          </Dropdown>
                        </div>
                      </td>
                    )}
                  </tr>
                )
              }
            )}
        </tbody>
      </table>
      {(flowcodeData && flowcodeData.permutationBatches.length < 1) ||
      !flowcodeData ||
      listLoading ? (
        <NoData loading={listLoading} />
      ) : null}
    </div>
  )
}

export default ViewFlowcodesTable
