//These are the validation rules for the form input fields

//validate email
export const validateEmail = (value: string): string | undefined => {
  let errors

  if (!value) {
    errors = 'Email is required.'
  } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
    errors = 'Invalid email address!'
  }

  return errors
}

//validate required value => min 1 char
export const validateRequired = (value: any): string | undefined => {
  let errors

  if (!value) {
    errors = 'Cannot be empty.'
    return errors
  }
}

//validate required for arrays
export const validateRequiredArray = (
  value: any,
  ignoreValidation?: boolean
): string | undefined => {
  let errors

  if (ignoreValidation) return

  if (value.length === 0) {
    errors = 'Cannot be empty.'
    return errors
  }
}

//validate value length => min 5 char, mainly used for password
export const validateLength = (value: string): string | undefined => {
  const MIN_LENGTH = 5
  let errors

  if (value.length < MIN_LENGTH) {
    errors = 'Must be at least 5 characters long.'
  }
  return errors
}
