//This component will create input search field using ant d.
import React from 'react'

//styled components
import { Input } from 'antd'

//ant d components
const { Search } = Input

//props to receive
interface Props {
  handleSearch: (value: string) => void
  placeholderVal: string
  disabled: boolean
}

export const SearchField = ({
  handleSearch,
  placeholderVal,
  disabled
}: Props) => {
  const handleChange = (e: any) => {
    handleSearch(e.target.value)
  }

  return (
    <Search
      placeholder={placeholderVal}
      style={{ width: 200 }}
      disabled={disabled}
      onChange={handleChange}
    />
  )
}
