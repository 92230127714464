// mutation - folder

import gql from 'graphql-tag'

//create folder
export const CREATE_FOLDER = gql`
  mutation($experimentId: UUID, $input: FolderInputType) {
    createFolder(experimentId: $experimentId, input: $input) {
      ok
    }
  }
`

//edit folder
export const EDIT_FOLDER = gql`
  mutation($id: UUID!, $input: FolderInputType) {
    updateFolder(id: $id, input: $input) {
      ok
    }
  }
`

//delete folder
export const DELETE_FOLDER = gql`
  mutation($id: UUID!) {
    deleteFolder(id: $id) {
      ok
    }
  }
`
