//This will wrap child component to render it in a modal form.

import React from 'react'
import { Modal, Button, Popconfirm } from 'antd'

// typescript types
interface Props {
  title: string
  buttons: Array<{}>
  submitForm: () => Promise<any>
  loading: boolean
  viewFormModal: boolean
  handleVisible: () => void
}

//JSX
const ModalWrap: React.FC<Props> = props => {
  //props
  const {
    title,
    buttons,
    submitForm,
    loading,
    viewFormModal,
    handleVisible
  } = props

  return (
    <Modal
      title={title}
      visible={viewFormModal}
      confirmLoading={false}
      onCancel={handleVisible}
      footer={[
        <Button key="back" onClick={handleVisible}>
          Cancel
        </Button>,

        //other buttons
        buttons.map((item: any) => (
          <Popconfirm
            title="Are you sure？"
            key={item.key}
            okText="Yes"
            cancelText="No"
            // if there is additional action over regular submit it can be done by
            //putting the function name in the value of action key of the button object
            onConfirm={() => {
              item.action && item.action()
              submitForm()
            }}
          >
            <Button type="primary" loading={loading}>
              {item.buttonName}
            </Button>
          </Popconfirm>
        ))
      ]}
    >
      {props.children}
    </Modal>
  )
}

export default ModalWrap
