//This component is used to toggle between editable and disabled input field

import React, { useRef, useState } from 'react'
//styled components
import { Form, Input } from 'antd'
//utility
import ToggleWrapper from './ToggleWrapper'

//ant component destructuring
const { Item } = Form

//typescript
interface ToggleInputProps {
  label: string
  value: string
  formattedValue?: string
  onElBlur?: () => void
  setFieldValue: (v: string) => any
  prefix?: string

  //disableInput is used in view permutation, once the final number is set to true
  //further editing must not be allowed
  disableInput?: boolean
}

//JSX
const ToggleEditInput = ({
  label,
  value,
  formattedValue,
  onElBlur,
  setFieldValue,
  prefix,
  disableInput = false
}: ToggleInputProps): JSX.Element => {
  //ref
  const ref = useRef()
  //local state
  const [isEdit, setIsEdit] = useState(false)

  return (
    <Item label={label}>
      <ToggleWrapper
        editable={isEdit}
        autoFocus
        elRef={ref}
        // static input element for view purpose
        staticEl={
          <div
            onClick={() => {
              !disableInput && setIsEdit(true)
            }}
          >
            <Input
              prefix={prefix}
              value={formattedValue || value}
              disabled
              className={
                !disableInput
                  ? 'hide-disabled c-pointer'
                  : 'hide-disabled d-pointer'
              }
            />
          </div>
        }
        // editable input element
        editableEl={
          <Input
            prefix={prefix}
            value={value}
            onChange={(e: any) => setFieldValue(e.target.value)}
            onBlur={() => {
              setIsEdit(false)
              if (onElBlur) onElBlur()
            }}
            ref={(node: any) => (ref.current = node)}
          />
        }
      />
    </Item>
  )
}

export default ToggleEditInput
