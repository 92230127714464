//Context - Build.
// This is the context for handling the build of a batch individually.

import React, { createContext, useState, useEffect } from 'react'

//create context
export const BuildModalCTX: any = createContext({})

const BuildModalProvider: React.FC<any> = props => {
  //local state
  const [buildSuccess, setBuildSuccess] = useState(false)
  const [buildError, setBuildError] = useState(false)
  const [buildErrorMsg, setBuildErrorMsg] = useState('')
  //show or hide state of modal
  const [buildFormModal, setViewFolderFormModal] = useState(false)

  // mount timer and unmount cleanup - set timer for 2secs and remove timer when the component is unmounted
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (buildSuccess) {
        setBuildSuccess(false)
      }
    }, 2000)

    return () => {
      // clear timeout
      return clearTimeout(timeout)
    }
  }, [buildSuccess])

  const handleVisible = () => {
    if (buildFormModal) {
      setBuildError(false)
      setBuildErrorMsg('')
    }
    setViewFolderFormModal(prevState => !prevState)
  }
  return (
    <BuildModalCTX.Provider
      value={{
        buildFormModal,
        handleVisible,
        buildSuccess,
        setBuildSuccess,
        buildError,
        setBuildError,
        buildErrorMsg,
        setBuildErrorMsg
      }}
    >
      {props.children}
    </BuildModalCTX.Provider>
  )
}

export default BuildModalProvider
