//Container- Edit experiment

import React, { Fragment, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Formik } from 'formik'
import {
  submitEditExperiment,
  createExperimentFormData
} from '../../../Utilities/Experiment'

//apollo hooks
import { useQuery, useMutation } from '@apollo/react-hooks'

//ant d styled components
import { Breadcrumb } from 'antd'

//graphql
import { GET_EDIT_EXPERIMENT } from '../../../Graqhql/Experiment/QueryExperiment'
import { UPDATE_EXPERIMENT } from '../../../Graqhql/Experiment/MutationExperiment'

//typeScript
import { ExperimentFormValues } from '../../../TypeScript_Types/ExperimentTypes'

//utilities
import ErrorBoundary from '../../../Utilities/ErrorBoundary'

//functional components
import LayoutWrap from '../../../HOC/LayoutWrap'
import PageTitle from '../../../Components/Reusable_UI/Headers_Titles/PageTitles'
import EditExperimentForm from '../../../Components/Experiment/Create_Edit_ExperimentForm'
import ErrorAlert from '../../../Components/Reusable_UI/Alerts/ErrorAlerts'
import { LoadingSpinner } from '../../../Components/Reusable_UI/Loaders_Spinners/LoadingSpinner'

//const title of edit experiment page
const PAGE_TITLE = 'Edit Experiment'

//const form fields initial state
const initialFieldValues: ExperimentFormValues = {
  name: '',
  brands: [],
  experiment_identifier: '', // eslint-disable-line
  startDate: null,
  startTime: null,
  endDate: null,
  endTime: null,
  channels: [],
  mediums: [],
  discreteValues: [],
  manager: '',
  isCac: false
}

// TODO: create props interface

/* eslint-disable react/prop-types */

//React FC
const EditExperiement: React.FC<any> = props => {
  //experiment form data state
  const [experimentData, setExperimentData] = useState(initialFieldValues)
  const [updateError, setUpdateError] = useState()
  const [initialBrands, setInitialBrands] = useState()
  const [initialFeatures, setInitialFeatures] = useState()
  const [expError, setExpError] = useState()
  //this flag will indicate initial data load
  const [initalDataReady, setInitialDataReady] = useState(false)

  //extract experiment id
  const experimentId = props.match.params.id
  const decodedExperimentId = window.atob(experimentId).split(':')[1]

  //get the experiment details
  const { data: expData, loading: expLoading } = useQuery(GET_EDIT_EXPERIMENT, {
    variables: { id: decodedExperimentId },
    onError(error) {
      setExpError(error)
    }
  })

  //create the initial form data
  useEffect(() => {
    if (expData) {
      const {
        experimentData,
        initialBrands,
        initialFeatures
      } = createExperimentFormData(expData)
      setExperimentData(experimentData)
      setInitialBrands(initialBrands)
      setInitialFeatures(initialFeatures)
    }
  }, [expData])

  // When the experiment data has updated successfully, change the inital data ready to true
  useEffect(() => {
    setInitialDataReady(true)
  }, [experimentData, expData])

  //submit mutation
  const [updateExperiment, { loading: updateLoading }] = useMutation(
    UPDATE_EXPERIMENT,
    {
      onCompleted() {
        props.history.push(`/${experimentId}/view_experiment`)
      },
      onError(error) {
        setUpdateError(error)
      }
    }
  )

  const handleSubmit = (values: ExperimentFormValues): void => {
    submitEditExperiment(
      values,
      initialBrands,
      initialFeatures,
      updateExperiment,
      decodedExperimentId
    )
  }

  //breadcrumb navigation menu
  const EditExperimentBreadcrumb = (
    <Breadcrumb style={{ marginBottom: '20px' }}>
      <Breadcrumb.Item>
        <Link to={`/${experimentId}/view_experiment`}>View experiment</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>Edit experiment</Breadcrumb.Item>
    </Breadcrumb>
  )

  return (
    <LayoutWrap props={props}>
      {/* breadcrumb navigation menu */}
      {EditExperimentBreadcrumb}

      {/* page title */}
      <PageTitle titleName={PAGE_TITLE} />

      {/* error during update */}
      {updateError && (
        <ErrorAlert
          message={
            updateError.message ? updateError.message : 'Something went wrong'
          }
          onErrorClose={() => setUpdateError(undefined)}
          close={true}
        />
      )}

      {/* if error during loading experiment data */}
      {expError ? (
        <ErrorAlert
          message={expError.message ? expError.message : 'Something went wrong'}
        />
      ) : (
        <Fragment>
          {expLoading ? (
            <LoadingSpinner />
          ) : (
            <Formik
              enableReinitialize
              initialValues={experimentData}
              onSubmit={handleSubmit}
              validateOnBlur={false}
            >
              {({ submitCount, values, submitForm, setFieldValue }) => (
                <ErrorBoundary>
                  <EditExperimentForm
                    isEdit
                    initalDataReady={initalDataReady}
                    setInitialDataReady={setInitialDataReady}
                    submitCount={submitCount}
                    values={values}
                    submitForm={submitForm}
                    expData={expData}
                    submitLoading={updateLoading}
                    setFieldValue={setFieldValue}
                    buttonName="EDIT"
                  />
                </ErrorBoundary>
              )}
            </Formik>
          )}
        </Fragment>
      )}
    </LayoutWrap>
  )
}

export default EditExperiement
