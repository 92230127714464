//View permutation form - This component will display the details of an experiment.
//The form "Field" component is from the resuable UI components - refer /Reusable_UI

import React, { useState, Fragment, useEffect } from 'react'

//styled components
import {
  Row,
  Col,
  Input,
  Icon,
  Tooltip,
  Switch,
  Form as AntForm,
  Button,
  Popconfirm,
  Alert
} from 'antd'
import { Formik } from 'formik'
import * as _ from 'lodash'

//functional components
import {
  ToggleSelect,
  ToggleListSelect,
  ToggleEditInput,
  ToggleTreeSelect
} from '../Reusable_UI/Forms/Toggle'

//utilities
import { numberWithCommas, formatPermutationName } from '../../Utilities/Format'
import { GetFeaturesTree } from '../../Utilities/FeaturesTree'
import { accumulateFeatures3 } from '../../Utilities/Experiment'

// ant d
const FormItem = AntForm.Item
const { TextArea } = Input

//typescript
interface Props {
  permutationDetails: any
  updatePermutation: (perm: any) => void
  expFeatures: any[]
}

//inline styles
const toggleIconDiv = {
  display: 'flex',
  alignItems: 'center',
  height: 115
}

const toggleIcon = { fontSize: '22px' }

const ViewPermutationDetails = ({
  permutationDetails,
  updatePermutation,
  expFeatures
}: Props): JSX.Element => {
  // local state
  const [showForm, setShowForm] = useState(true)
  const [variablesTreeData, setVariablesTreeData] = useState()
  const [attributesTreeData, setAttributesTreeData] = useState()

  //extract experiment from permtuation details
  const { experiment } = permutationDetails

  //generate the features tree structure for selection choices
  useEffect(() => {
    setVariablesTreeData(GetFeaturesTree(expFeatures))
  }, [expFeatures])

  //filter features to not allow the listing of archived features
  useEffect(() => {
    const filteredFeatures = permutationDetails.features.filter(
      ({ archived }: { archived: boolean }) => !archived
    )
    setAttributesTreeData(GetFeaturesTree(filteredFeatures))
  }, [permutationDetails.features])

  // show of hide permutation details
  const showHideFormToggle = (): void => {
    setShowForm(prevState => !prevState)
  }

  //create a dropdown for feature variables & attributes
  const getFeaturesList = (features: any[]): any[] =>
    accumulateFeatures3(features)

  //save the selected features variables
  const getFeaturesVariables = (features: any[]): any[] => {
    const objects: any[] = []
    experiment.experimentDiscreteSet.forEach((discreteFeature: any) => {
      if (features.includes(discreteFeature.discreteValue.id)) {
        objects.push(discreteFeature)
      }
    })
    return objects
  }

  //save selected attributes
  const getFeaturesAttributes = (features: string[]): any[] => {
    const objects: any[] = []
    permutationDetails.features.forEach((featureObject: any) => {
      featureObject.discreteValues.forEach((discreteFeature: any) => {
        if (features.includes(discreteFeature.id)) {
          objects.push({
            ...discreteFeature,
            feature: { id: featureObject.id, name: featureObject.name }
          })
        }
      })
    })
    return objects
  }

  //save brand objects
  const getBrandObjects = (input: any): any[] =>
    experiment.experimentBrandSet.filter((b: any) => input.includes(b.brand.id))

  //submit edits
  const submitChanges = (values: any): void => {
    const permutationName = formatPermutationName({
      values: {
        ...values,
        variables: values.variables.map(({ id }: { id: string }) => id),
        brands: values.brand.map(
          ({ brand: { id } }: { brand: { id: string } }) => id
        )
      },
      brandsList: experiment.brands,
      permutationData: permutationDetails
    })

    updatePermutation({
      name: permutationName,
      estCost: values.estCost,
      actualCost: values.actualCost,
      estImpressions: values.estImpressions,
      actualImpressions: values.actualImpressions,
      iscs: values.iscs,
      notes: values.notes,
      channel: values.channel,
      medium: values.medium,
      folder: values.folder,
      variables: values.variables,
      brands: values.brand,
      attributes: values.attributes,
      finalNumber: values.finalNumber
    })
  }

  //check the hasFolder
  const hasFolder = Boolean(
    permutationDetails.folders && permutationDetails?.folders?.length
  )

  return (
    <AntForm>
      <Formik
        initialValues={permutationDetails.permutation}
        enableReinitialize
        onSubmit={submitChanges}
      >
        {({ values, initialValues, setFieldValue }) => {
          const showSave = !_.isEqual(values, initialValues)

          const permutationName = formatPermutationName({
            values: {
              ...values,
              variables: values.variables.map(({ id }: { id: string }) => id),
              brands: values.brand.map(
                ({ brand: { id } }: { brand: { id: string } }) => id
              )
            },
            brandsList: experiment.brands,
            permutationData: permutationDetails
          })

          //show or hide ISCS field
          const showISCS = values.channel === 'TV'

          return (
            <Fragment>
              {/* Alert */}
              {values.finalNumber && (
                <div style={{ margin: '15px 0px' }}>
                  <Alert
                    type="info"
                    message="This permutation cannot be edited."
                    showIcon
                  />
                </div>
              )}

              {/* experiment details */}
              <FormItem label="Experiment name">
                <TextArea
                  value={permutationDetails.experiment.name}
                  autoSize={true}
                  style={{ resize: 'none' }}
                  spellCheck={false}
                  disabled
                  className="hide-disabled"
                />
              </FormItem>

              {/* Group / Folder */}
              {hasFolder && (
                <ToggleSelect
                  name="folder"
                  displayValue={
                    values.folder?.name ||
                    permutationDetails.folders?.find(
                      (f: any) => f.id === values.folder?.id
                    )?.name
                  }
                  selectValue={values.folder?.id}
                  fieldValue={(value: string) =>
                    permutationDetails.folders.find((f: any) => f.id === value)
                  }
                  options={permutationDetails.folders}
                  optionValue={(o: any) => o.id}
                  optionName={(o: any) => o.name}
                  setValue={(value: any) => setFieldValue('folder', value)}
                  disableSelect={values.finalNumber}
                />
              )}

              {/* permutation section */}
              <Row gutter={16}>
                <Col xs={{ span: 1 }} style={toggleIconDiv}>
                  <Tooltip title={showForm ? 'Show less' : 'Show more'}>
                    <Icon
                      type={showForm ? 'minus-square' : 'plus-square'}
                      style={toggleIcon}
                      onClick={showHideFormToggle}
                    />
                  </Tooltip>
                </Col>

                {/* permutaiton name */}
                <Col xs={{ span: 11 }}>
                  <FormItem label="Permutation name">
                    <TextArea
                      value={permutationName}
                      autoSize={true}
                      style={{ resize: 'none' }}
                      disabled
                      className="hide-disabled"
                    />
                  </FormItem>
                </Col>

                {/* permutation ID */}
                <Col xs={{ span: 11 }}>
                  <FormItem label="Permutation ID">
                    <TextArea
                      value={values.id}
                      autoSize={true}
                      style={{ resize: 'none' }}
                      spellCheck={false}
                      disabled
                      className="hide-disabled"
                    />
                  </FormItem>
                </Col>
              </Row>

              {showForm && (
                <Fragment>
                  {/* brands */}
                  <ToggleListSelect
                    name="brand"
                    displayValue={experiment.brands.filter((b: any) =>
                      values.brand.some((br: any) => br.brand.id === b.id)
                    )}
                    selectValue={values.brand.map((b: any) => b.brand.id)}
                    fieldValue={(v: string) => v}
                    options={experiment.brands}
                    optionValue={(o: any) => o.id}
                    optionName={(o: any) => o.name}
                    setFieldValue={(value: string) => {
                      setFieldValue('brand', getBrandObjects(value))
                    }}
                    // if the final number is true, disable the list
                    disableList={values.finalNumber}
                  />

                  <Row gutter={16}>
                    <Col xs={{ span: showISCS ? 8 : 12 }}>
                      {/* Channel */}
                      <ToggleSelect
                        name="channel"
                        displayValue={values.channel.toLowerCase()}
                        selectValue={values.channel.toLowerCase()}
                        fieldValue={(value: string) => value}
                        options={experiment.channels}
                        optionValue={(o: string) => o}
                        optionName={(o: string) => o}
                        setValue={(value: any) => {
                          //if the channel is already set to TV, reset ISCS field on updating channel
                          if (showISCS) {
                            setFieldValue('iscs', '')
                          }
                          //update channel
                          setFieldValue('channel', value.toUpperCase())
                        }}
                        // if the final number is true, disable the list
                        disableSelect={values.finalNumber}
                      />
                    </Col>

                    <Col xs={{ span: showISCS ? 8 : 12 }}>
                      {/* Medium*/}
                      <ToggleSelect
                        name="medium"
                        displayValue={values.medium.toLowerCase()}
                        selectValue={values.medium.toLowerCase()}
                        fieldValue={(value: string) => value}
                        options={experiment.mediums}
                        optionValue={(o: string) => o}
                        optionName={(o: string) => o}
                        setValue={(value: any) =>
                          setFieldValue('medium', value.toUpperCase())
                        }
                        disableSelect={values.finalNumber}
                      />
                    </Col>

                    {showISCS && (
                      <Col xs={{ span: 8 }}>
                        {/* iscs code */}
                        <ToggleEditInput
                          label="ISCS code"
                          value={values.iscs}
                          setFieldValue={(v: string) =>
                            setFieldValue('iscs', v)
                          }
                          disableInput={values.finalNumber}
                        />
                      </Col>
                    )}
                  </Row>

                  <Row gutter={16}>
                    <Col xs={{ span: 5 }}>
                      {/* Estimated impressions*/}
                      <ToggleEditInput
                        label="Estimated impressions"
                        value={numberWithCommas(values.estImpressions)}
                        setFieldValue={(v: string) =>
                          setFieldValue('estImpressions', v)
                        }
                        disableInput={values.finalNumber}
                      />
                    </Col>

                    <Col xs={{ span: 5 }}>
                      {/* Actual impressions */}
                      <ToggleEditInput
                        label="Actual impressions"
                        value={numberWithCommas(values.actualImpressions)}
                        setFieldValue={(v: string) =>
                          setFieldValue('actualImpressions', v)
                        }
                        disableInput={values.finalNumber}
                      />
                    </Col>

                    <Col xs={{ span: 5 }}>
                      {/* Estimated spend */}
                      <ToggleEditInput
                        label="Estimated spend"
                        prefix="$"
                        formattedValue={
                          values.estCost
                            ? parseFloat(values.estCost)
                                .toFixed(2)
                                .replace(/\d{1,3}(?=(\d{3})+(?!\d))/g, '$&,')
                            : ''
                        }
                        value={values.estCost}
                        setFieldValue={(v: string) =>
                          setFieldValue('estCost', v)
                        }
                        disableInput={values.finalNumber}
                      />
                    </Col>

                    <Col xs={{ span: 5 }}>
                      {/* Spend to Date */}
                      <ToggleEditInput
                        label="Spend to date"
                        prefix="$"
                        formattedValue={
                          values.actualCost
                            ? parseFloat(values.actualCost)
                                .toFixed(2)
                                .replace(/\d{1,3}(?=(\d{3})+(?!\d))/g, '$&,')
                            : ''
                        }
                        value={values.actualCost}
                        setFieldValue={(v: string) =>
                          setFieldValue('actualCost', v)
                        }
                        disableInput={values.finalNumber}
                      />
                    </Col>

                    <Col xs={{ span: 4 }}>
                      {/* Final numbers - if confirmed update permutation*/}
                      <FormItem label="Final Numbers">
                        <Tooltip title={values.finalNumber ? 'Yes' : 'No'}>
                          <Popconfirm
                            title={
                              'Are you sure these numbers are final? Once you confirm the permutation will no longer be editable'
                            }
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => {
                              //the visual toggle change will not happen, submission must take place immediately
                              values.finalNumber = true
                              submitChanges(values)
                            }}
                            disabled={values.finalNumber}
                          >
                            <Switch
                              checked={values.finalNumber}
                              disabled={values.finalNumber}
                            />
                          </Popconfirm>
                        </Tooltip>
                      </FormItem>
                    </Col>
                  </Row>

                  {/* features - variables*/}
                  <ToggleTreeSelect
                    name="variables"
                    label="Features - Variables"
                    selectedItems={getFeaturesList(values.variables)}
                    selectValue={values.variables.map(
                      (v: any) => v.discreteValue.id
                    )}
                    treeData={variablesTreeData}
                    filterProp="title"
                    setFieldValue={(value: any) => {
                      setFieldValue('variables', getFeaturesVariables(value))
                    }}
                    disableEdit={values.finalNumber}
                  />

                  {/* attributes */}
                  <ToggleTreeSelect
                    name="attributes"
                    label="Features - Attributes"
                    selectedItems={getFeaturesList(values.attributes)}
                    selectValue={values.attributes.map((a: any) => a.id)}
                    treeData={attributesTreeData}
                    filterProp="title"
                    setFieldValue={(value: any) => {
                      setFieldValue('attributes', getFeaturesAttributes(value))
                    }}
                    disableEdit={values.finalNumber}
                  />

                  {/* Notes */}
                  <ToggleEditInput
                    label="Notes"
                    value={values.notes}
                    setFieldValue={(v: string) => setFieldValue('notes', v)}
                    disableInput={values.finalNumber}
                  />

                  {showSave && (
                    <Row>
                      <Col span={24} style={{ textAlign: 'right' }}>
                        <Popconfirm
                          title="Are you sure？"
                          okText="Yes"
                          cancelText="No"
                          onConfirm={() => submitChanges(values)}
                        >
                          <Button type="primary">SAVE</Button>
                        </Popconfirm>
                      </Col>
                    </Row>
                  )}
                </Fragment>
              )}
            </Fragment>
          )
        }}
      </Formik>
    </AntForm>
  )
}

export default ViewPermutationDetails
