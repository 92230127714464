//This component will be rendered when the table data is being fetched (loading state)
//or the data is empty

import React from 'react'
import { Spin } from 'antd'

//asset
import NoDataSvg from '../../../assets/NoData.svg'

//props
interface Props {
  loading: boolean
}

const NoData = ({ loading }: Props) => {
  return (
    <div
      style={{
        width: '100%',
        padding: '16px',
        background: '#FFFFFF',
        position: 'relative'
      }}
    >
      <div style={{ margin: '32px 0px', textAlign: 'center' }}>
        <img src={NoDataSvg} alt="No data" />
        <p>No Data</p>
      </div>
      {loading && (
        <div
          style={{
            width: '100%',
            height: '100%',
            background: '#FFFFFF',
            opacity: '.5'
          }}
        >
          <Spin style={{ position: 'absolute', top: '50%', left: '50%' }} />
        </div>
      )}
    </div>
  )
}

export default NoData
