//This container is used to display the batch (Flowcodes) QR.

import React, { useEffect, useState, Fragment } from 'react'

//styled components
import { BlockPicker, ColorResult } from 'react-color'
import { Modal, Typography } from 'antd'

//REST
import API from '../../Utilities/RESTSetup'

//constants
import { DEFAULT_FLOWCODE_COLOR } from '../../Constants/Flowcodes/Flowcodes'

//utilities
import ErrorBoundary from '../../Utilities/ErrorBoundary'

//functional components
import { LoadingSpinner } from '../../Components/Reusable_UI/Loaders_Spinners/LoadingSpinner'
import ErrorAlert from '../../Components/Reusable_UI/Alerts/ErrorAlerts'


//ant component destructuring
const { Text } = Typography

//types
interface Props {
  previewBatchId: string
  setPreviewQRId: React.Dispatch<any>
}

//JSX
const PreviewQR = ({ previewBatchId, setPreviewQRId }: Props): JSX.Element => {
  //local states
  const [imageLink, setImageLink] = useState()
  const [imageFileName, setImageFileName] = useState()
  const [downloadErrorMsg, setDownloadQRErrorMsg] = useState()
  const [downloadQRLoading, setDownloadQRLoading] = useState(false)
  const [flowcodeColor, setFlowcodeColor] = useState(DEFAULT_FLOWCODE_COLOR)

  //get preview image urls
  useEffect(() => {
    setDownloadQRLoading(true)
    let unmounted = false
    const source = API.CancelToken.source()

    //API config
    const config: any = {
      headers: {
        'Content-Type': 'application/json'
      },
      responseType: 'blob',
      params: {
        defaultColor: flowcodeColor?.replace(/#/gi, '')
      }
    }

    //API call
    API.get(`/v1/flowcode/${previewBatchId}/`, config)
      .then((response: any) => {
        if (!unmounted) {
          const url = window.URL.createObjectURL(response.data)
          let filename = response.request.getResponseHeader(
            'Content-Disposition'
          )
          filename = filename.split('"')[1]

          setImageFileName(filename)
          setImageLink(url)
          setDownloadQRLoading(false)
        }
      })
      .catch((error: any) => {
        if (!unmounted) {
          if (error.response && error.response.status === 401) {
            setDownloadQRErrorMsg(
              'Your session has expired. Please log in again'
            )
            // add auto logout step
          } else if (error.response && error.response.data.detail) {
            setDownloadQRErrorMsg(error.response.data.detail)
          } else {
            setDownloadQRErrorMsg('Something went wrong')
          }
          setDownloadQRLoading(false)
        }
      })
    return function() {
      unmounted = true
      source.cancel('Cancelling in cleanup')
    }
  }, [previewBatchId, flowcodeColor])

  const handleClose = (): void => {
    setPreviewQRId(null)
  }

  return (
    <Fragment>
      {downloadErrorMsg ? (
        <ErrorAlert
          message={downloadErrorMsg}
          close={true}
          onErrorClose={() => {
            setDownloadQRErrorMsg(undefined)
            handleClose()
          }}
        />
      ) : (
        <Modal
          title="Preview Flowcode"
          visible={!!previewBatchId}
          onCancel={handleClose}
          onOk={handleClose}
        >
          {downloadQRLoading ? (
            <LoadingSpinner />
          ) : (
            <ErrorBoundary>
              <Text>{`File - ${imageFileName}`}</Text>
              <img src={imageLink} alt="QR" style={{ width: '100%' }} />
              <strong>Color:</strong>
              <div className="color-picker-container">
                <BlockPicker
                  color={flowcodeColor}
                  triangle="hide"
                  onChangeComplete={(color: ColorResult) =>
                    setFlowcodeColor(color.hex)
                  }
                />
              </div>
            </ErrorBoundary>
          )}
        </Modal>
      )}
    </Fragment>
  )
}

export default PreviewQR
