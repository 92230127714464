//this component will generate form layout for create and edit flowcodes

import React, { useEffect, useState } from 'react'
import {
  getLandingPageType,
  landingPageValidator,
  LandingPageOptions
} from '@dtx-company/flowcode-utils'

//form handler
import { Form, Field } from 'formik'
import {
  Row,
  Col,
  Form as AntForm,
  TreeSelect,
  Descriptions,
  Collapse,
  Tooltip,
  Switch
} from 'antd'

//constants
import { FlowcodesType } from '../../Constants/Flowcodes/FlowcodesType'

//typeScript
import {
  FeaturePreview,
  AllFeaturesItem
} from '../../TypeScript_Types/Features'
import { FlowcodesFormProps } from '../../TypeScript_Types/Flowcodes'

//utils
import { GetFeaturesTree } from '../../Utilities/FeaturesTree'

//functional components
import { AntInput, AntSelect } from '../Reusable_UI/Forms/FormFields'
import { AntSelectWithVal } from '../Reusable_UI/Forms/SelectWithVal'
import { validateRequired } from '../../Utilities/Validate'

//ant design destructuring
const FormItem = AntForm.Item
const { SHOW_CHILD } = TreeSelect
const DescriptionsItem = Descriptions.Item
const { Panel } = Collapse

interface AddRemoveButton {
  displayPlusButton: boolean
  displayMinusButton: boolean
  handleAddBtnClick?: () => void
  handleMinusBtnClick?: () => void
}

const AddRemoveButton = ({
  displayPlusButton,
  displayMinusButton,
  handleAddBtnClick = () => null,
  handleMinusBtnClick = () => null
}: AddRemoveButton): JSX.Element => (
  <Col span={2}>
    <div className="flex">
      {displayPlusButton && (
        <button
          className="landing-page-btn plus"
          type="button"
          onClick={handleAddBtnClick}
        >
          +
        </button>
      )}
      {displayMinusButton && (
        <button
          className="landing-page-btn minus"
          type="button"
          onClick={handleMinusBtnClick}
        >
          -
        </button>
      )}
    </div>
  </Col>
)

const CreateEditFlowcode = ({
  submitCount,
  flowcodesData,
  values,
  setFieldValue,
  flowcodeType,
  featuresData,
  initialDataReady,
  setInitialDataReady
}: FlowcodesFormProps): JSX.Element => {
  //local states
  const [brandsList, setBrandsList] = useState()
  const [featureTreeData, setFeatureTreeData] = useState()
  const [selectedFeaturesPreview, setSelectedFeaturePreview] = useState([])
  const [featureUpdated, setFeatureUpdated] = useState(false)
  // TOOD: refactor, add prefix key, make this infinite
  const [visibleLandingPages, setVisibleLandingPages] = useState<{
    [key: number]: boolean
  }>({
    1: true,
    2: !!values.redirectValue2,
    3: !!values.redirectValue3,
    4: !!values.redirectValue4,
    5: !!values.redirectValue5,
    6: !!values.redirectValue6
  })

  React.useEffect(() => {
    if (initialDataReady) {
      setVisibleLandingPages({
        1: true,
        2: !!values.redirectValue2,
        3: !!values.redirectValue3,
        4: !!values.redirectValue4,
        5: !!values.redirectValue5,
        6: !!values.redirectValue6
      })
    }
  }, [
    initialDataReady,
    values.redirectValue2,
    values.redirectValue3,
    values.redirectValue4,
    values.redirectValue5,
    values.redirectValue6
  ])

  const toggleVisibleLandingPage = (page: number): void => {
    setVisibleLandingPages(prev => {
      const isOpen = !prev[page]
      const redirectValue: any = `redirectValue${page}`
      if (!isOpen) setFieldValue(redirectValue, '', false)
      return {
        ...prev,
        [page]: isOpen
      }
    })
  }

  const validateRedirectValue = (value: string): string => {
    const id: LandingPageOptions = getLandingPageType(value)
    const isValid = landingPageValidator({
      id,
      url: value,
      data: value, // handle sms and mailto links
      options: {
        requireProtocol: true
      }
    })
    return !isValid ? 'Please enter a valid URL. Protocol is required.' : ''
  }

  const isLandingPageTwoVisible = visibleLandingPages[2]
  const isLandingPageThreeVisible = visibleLandingPages[3]
  const isLandingPageFourVisible = visibleLandingPages[4]
  const isLandingPageFiveVisible = visibleLandingPages[5]
  const isLandingPageSixVisible = visibleLandingPages[6]

  //create custom brandsList for selection from the permutations brands

  useEffect(() => {
    if (flowcodesData) {
      const customBrandsList = flowcodesData.permutation.permutationBrandSet.map(
        (item: { id: string; brands: { name: string } }) => {
          return {
            id: item.id,
            name: item.brands.name
          }
        }
      )
      setBrandsList(customBrandsList)
    }
  }, [flowcodesData])

  // create attributes features data tree
  useEffect(() => {
    if (featuresData && flowcodesData) {
      //create custom object for creating feature tree hierarchial structure from permutations attributes
      const treeData = GetFeaturesTree(featuresData)
      setFeatureTreeData(treeData)
    }
  }, [featuresData, flowcodesData])

  //create preview for selected features
  useEffect(() => {
    if (!flowcodesData) return
    //FEATURES - create an oject of selected featureNames with features.
    //PREVIEW FEATURE NAMES - ATTRIBUTES
    const selectedFeaturesPreview = (inputValue: Array<string>) => {
      const selectedFeatures = flowcodesData?.features?.reduce(function(
        acc: Array<FeaturePreview>,
        obj: AllFeaturesItem
      ) {
        //find if the feature already exists in the accumulator
        if (obj.discreteValues.some(item => inputValue.includes(item.id))) {
          // check if the feature already exist
          const objIndex = acc.findIndex(object => object.featureId === obj.id)
          //create a filtered discrete
          const filteredDiscrete = obj.discreteValues.filter(discrete =>
            inputValue.includes(discrete.id)
          )
          if (objIndex === -1) {
            //create custom object
            const customFeature = {
              featureId: obj.id,
              featureName: obj.name,
              discreteValues: [...filteredDiscrete]
            }
            acc.push(customFeature)
          } else {
            //replace attributes value in existing object
            acc[objIndex].discreteValues = [...filteredDiscrete]
          }
        }
        return acc
      },
      [])
      return selectedFeatures
    }

    //only if the values is not undefined and experiement feature has been updated this block will run.
    if ((values.attributes.length > 0 && initialDataReady) || featureUpdated) {
      //create a new array consisting the feature of the selected discrete values.
      const optFeatureData = selectedFeaturesPreview(values.attributes)
      setSelectedFeaturePreview(optFeatureData)
      setFeatureUpdated(false)

      if (initialDataReady) {
        setInitialDataReady(false)
      }
    }
  }, [
    values,
    flowcodesData,
    featureUpdated,
    featuresData,
    featureTreeData,
    initialDataReady,
    setInitialDataReady
  ])

  return (
    <Form>
      <Row gutter={10}>
        <Col span={12}>
          {/* flowcode type */}
          {/* note - In any one permutation there should only be flowcodes with either ‘one’ or ‘many’.
          Once the user creates a flow code with either, they should not be allowed to create another with the other type*/}

          <Field
            label="Flowcode type"
            name="type"
            allowClear
            placeholder="Select flowcode type"
            selectOptions={
              flowcodeType === ''
                ? FlowcodesType
                : [
                    flowcodeType.charAt(0) +
                      flowcodeType.substring(1).toLowerCase()
                  ]
            }
            submitCount={submitCount}
            validate={validateRequired}
            component={AntSelect}
          />
        </Col>
        <Col span={12}>
          <FormItem className="flex is-scan-input" label="Scannable">
            <Tooltip title={values.isScan ? 'Scannable' : 'Clickable'}>
              <Switch
                onClick={checked => setFieldValue('isScan', checked)}
                checked={values.isScan}
              />
            </Tooltip>
          </FormItem>
        </Col>
      </Row>
      <span>Landing Pages:</span>
      <section className="landing-page-inputs">
        <Row gutter={10}>
          <Col span={22}>
            <Field
              validate={validateRedirectValue}
              name="redirectValue1"
              type="text"
              allowClear
              placeholder="Landing page"
              submitCount={submitCount}
              component={AntInput}
            />
          </Col>
          <AddRemoveButton
            displayPlusButton={
              !isLandingPageTwoVisible && !isLandingPageThreeVisible
            }
            displayMinusButton={false}
            handleAddBtnClick={() => toggleVisibleLandingPage(2)}
          />
        </Row>
        {isLandingPageTwoVisible && (
          <Row gutter={10}>
            <Col span={22}>
              <Field
                validate={validateRedirectValue}
                name="redirectValue2"
                type="text"
                allowClear
                placeholder="Landing page"
                submitCount={submitCount}
                component={AntInput}
              />
            </Col>
            <AddRemoveButton
              displayPlusButton={!isLandingPageThreeVisible}
              displayMinusButton
              handleAddBtnClick={() => toggleVisibleLandingPage(3)}
              handleMinusBtnClick={() => toggleVisibleLandingPage(2)}
            />
          </Row>
        )}
        {isLandingPageThreeVisible && (
          <Row gutter={10}>
            <Col span={22}>
              <Field
                validate={validateRedirectValue}
                name="redirectValue3"
                type="text"
                allowClear
                placeholder="Landing page"
                submitCount={submitCount}
                component={AntInput}
              />
            </Col>
            <AddRemoveButton
              displayPlusButton={!isLandingPageFourVisible}
              displayMinusButton
              handleAddBtnClick={() => toggleVisibleLandingPage(4)}
              handleMinusBtnClick={() => toggleVisibleLandingPage(3)}
            />
          </Row>
        )}
        {isLandingPageFourVisible && (
          <Row gutter={10}>
            <Col span={22}>
              <Field
                validate={validateRedirectValue}
                name="redirectValue4"
                type="text"
                allowClear
                placeholder="Landing page"
                submitCount={submitCount}
                component={AntInput}
              />
            </Col>
            <AddRemoveButton
              displayPlusButton={!isLandingPageFiveVisible}
              displayMinusButton
              handleAddBtnClick={() => toggleVisibleLandingPage(5)}
              handleMinusBtnClick={() => toggleVisibleLandingPage(4)}
            />
          </Row>
        )}
        {isLandingPageFiveVisible && (
          <Row gutter={10}>
            <Col span={22}>
              <Field
                validate={validateRedirectValue}
                name="redirectValue5"
                type="text"
                allowClear
                placeholder="Landing page"
                submitCount={submitCount}
                component={AntInput}
              />
            </Col>
            <AddRemoveButton
              displayPlusButton={!isLandingPageSixVisible}
              displayMinusButton
              handleAddBtnClick={() => toggleVisibleLandingPage(6)}
              handleMinusBtnClick={() => toggleVisibleLandingPage(5)}
            />
          </Row>
        )}
        {isLandingPageSixVisible && (
          <Row gutter={10}>
            <Col span={22}>
              <Field
                validate={validateRedirectValue}
                name="redirectValue6"
                type="text"
                allowClear
                placeholder="Landing page"
                submitCount={submitCount}
                component={AntInput}
              />
            </Col>
            <AddRemoveButton
              displayPlusButton={!isLandingPageFiveVisible}
              displayMinusButton
              handleAddBtnClick={() => toggleVisibleLandingPage(5)}
              handleMinusBtnClick={() => toggleVisibleLandingPage(6)}
            />
          </Row>
        )}
      </section>

      {/* brands */}
      <Field
        label="Select brands from permutation"
        name="brand"
        mode="multiple"
        placeholder="Brands"
        submitCount={submitCount}
        selectOptions={brandsList}
        optionName="name"
        optionValue="id"
        component={AntSelectWithVal}
        filterPropName="data-searchkey"
      />

      {/* Flowcode features - permutation attributes*/}
      <Field name="attributes">
        {({ field, form }: any) => (
          <FormItem label="Flowcode features">
            <TreeSelect
              {...field}
              value={values.attributes}
              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
              treeData={featureTreeData}
              searchPlaceholder="Select feature values"
              treeCheckable={true}
              allowClear
              showCheckedStrategy={SHOW_CHILD}
              treeNodeFilterProp="title"
              onChange={value => {
                setFieldValue('attributes', value)
                setFeatureUpdated(true)
              }}
              onBlur={() => form.setFieldTouched(field.name, true)}
            />
          </FormItem>
        )}
      </Field>

      {/* flowcodes preview */}
      <FormItem>
        <Collapse bordered>
          <Panel header="Preview selected features" key="1">
            {selectedFeaturesPreview.length > 0 ? (
              <Descriptions
                layout="vertical"
                bordered
                size="small"
                style={{ background: 'white' }}
              >
                {selectedFeaturesPreview.map((previewItem: any) => (
                  <DescriptionsItem
                    label={previewItem.featureName}
                    key={previewItem.featureId}
                  >
                    {previewItem.discreteValues.map((item: any) => (
                      <p key={item.id} style={{ marginBottom: '5px' }}>
                        {item.value}
                      </p>
                    ))}
                  </DescriptionsItem>
                ))}
              </Descriptions>
            ) : (
              <div style={{ textAlign: 'center' }}>
                <p>Features not selected.</p>
              </div>
            )}
          </Panel>
        </Collapse>
      </FormItem>
    </Form>
  )
}

export default CreateEditFlowcode
