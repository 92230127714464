import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
//apollo
import { ApolloProvider } from '@apollo/react-hooks'

//service worker
import * as serviceWorker from './serviceWorker'

//contexts
import EditModalProvider from './Contexts/EditModalContext'
import CreateModalProvider from './Contexts/CreateModalContext'
import SideNavCtxProvider from './Contexts/SideNavToggle'

//styling
import './index.css'

//functional components
import App from './App'

//utilities
import { client } from './Utilities/ApolloSetup'

ReactDOM.render(
  // Apollo client provider
  <ApolloProvider client={client}>
    {/* React router */}
    <BrowserRouter>
      {/* contexts providers */}
      <CreateModalProvider>
        <EditModalProvider>
          <SideNavCtxProvider>
            <App />
          </SideNavCtxProvider>
        </EditModalProvider>
      </CreateModalProvider>
    </BrowserRouter>
  </ApolloProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
