import { ExperimentFormValues } from '../TypeScript_Types/ExperimentTypes'
import { Format } from '../Constants/dateTime'
import { getDateValue, getTimeValue } from './SplitDateTime'
import moment from 'moment'
import {
  AllFeaturesItem,
  FeaturePreview,
  DiscreteValues
} from '../TypeScript_Types/Features'

export const submitEditExperiment = (
  values: ExperimentFormValues,
  initialBrands: Array<any>,
  initialFeatures: Array<any>,
  updateExperiment: (variables: any) => void,
  experimentId: string
): void => {
  //convert dates to "YYYY-MM-DD" format and time to "HH:MM"
  let startDate
  let startTime
  let endDate
  let endTime
  let startDateTimeUTC = null
  let endDateTimeUTC = null

  //If start date is not null
  if (values.startDate) {
    startDate = moment(values.startDate).format(Format.convertUTCDateFormat)
    startTime = values.startTime
      ? moment(values.startTime).format(Format.timeFormat)
      : '00:00'
    //convert start date and time to UTC
    startDateTimeUTC = new Date(startDate + 'T' + startTime).toISOString()
  }

  //If end date is not null
  if (values.endDate) {
    endDate = moment(values.endDate).format(Format.convertUTCDateFormat)
    endTime = values.endTime
      ? moment(values.endTime).format(Format.timeFormat)
      : '00:00'
    //convert end date and time to UTC
    endDateTimeUTC = new Date(endDate + 'T' + endTime).toISOString()
  }

  //create add and delete brands list
  const deleteBrands =
    initialBrands.length > 0
      ? initialBrands
          .filter(
            (item: { brand: { id: string } }) =>
              !values.brands.includes(item.brand.id)
          )
          .map((brand: { id: string }) => brand.id)
      : []

  const addBrands =
    initialBrands.length > 0
      ? values.brands.filter((brand: string) =>
          initialBrands.some(
            (item: { brand: { id: string } }) => item.brand.id !== brand
          )
        )
      : values.brands

  //create add and delete features list
  // send experimentDiscreteSet > id for delete and discreteValue > id for add.
  const deleteDiscreteValues =
    initialFeatures.length > 0
      ? initialFeatures
          .filter(
            (feature: { discreteValue: { id: string } }) =>
              !values.discreteValues.includes(feature.discreteValue.id)
          )
          .map((item: { id: string }) => item.id)
      : []

  const addDiscreteValues =
    initialFeatures.length > 0
      ? values.discreteValues.filter(
          (value: string) =>
            !initialFeatures.some(
              (initFeature: { discreteValue: { id: string } }) =>
                initFeature.discreteValue.id === value
            )
        )
      : values.discreteValues
  //create input
  const input = {
    name: values.name,
    experiment_identifier: values.experiment_identifier, // eslint-disable-line
    channels: values.channels,
    mediums: values.mediums,
    startDate: startDateTimeUTC,
    endDate: endDateTimeUTC,
    manager: values.manager,
    isCac: values.isCac
  }
  // console.log({
  //   id: experimentId,
  //   input: input,
  //   addBrands,
  //   deleteBrands,
  //   addDiscreteValues,
  //   deleteDiscreteValues
  // })
  //call update mutation
  updateExperiment({
    variables: {
      id: experimentId,
      input: input,
      addBrands,
      deleteBrands,
      addDiscreteValues,
      deleteDiscreteValues
    }
  })
}

//Used to prepare data for the experiment form
export const createExperimentFormData = (expData: any): any => {
  const { experiment } = expData

  //get existing brands
  const brands = experiment.experimentBrandSet.map(
    (item: { brand: { id: string } }) => item.brand.id
  )
  
  //get existing discreteValues
  const discreteValues = experiment.experimentDiscreteSet.map(
    (item: { discreteValue: { id: string } }) => item.discreteValue.id
  )
  //get existing features
  // let features = experiment.discreteValues.map((item: {feature: {id: string}}) => item.feature.id);
  //split and format date and time
  const startDate = experiment.startDate
    ? moment(getDateValue(experiment.startDate), Format.dateFormat)
    : null
  const startTime = experiment.startDate
    ? moment(getTimeValue(experiment.startDate), Format.timeFormat)
    : null
  const endDate = experiment.endDate
    ? moment(getDateValue(experiment.endDate), Format.dateFormat)
    : null
  const endTime = experiment.endDate
    ? moment(getTimeValue(experiment.endDate), Format.timeFormat)
    : null
  //channels & mediums to upper case - to have it in sync with it's enums case
  const channels = experiment.channels.map((item: string) => item.toUpperCase())
  const mediums = experiment.mediums.map((item: string) => item.toUpperCase())

  const data = { ...experiment }
  // console.log(data)
  data.brands = brands
  data.discreteValues = discreteValues
  data.startDate = startDate
  data.startTime = startTime
  data.endDate = endDate
  data.endTime = endTime
  data.experiment_identifier = experiment.experimentIdentifier // eslint-disable-line
  data.channels = channels
  data.mediums = mediums
  data.manager = data.manager?.id
  return {
    experimentData: data,
    initialBrands: experiment.experimentBrandSet,
    initialFeatures: experiment.experimentDiscreteSet
  }
}

export const accumulateFeatures = (experiment: any): any => {
  const customFeatureData = experiment.discreteValues.reduce(function(
    acc: any,
    obj: any
  ) {
    //find if the feature already exists in the accumulator
    const objIndex = acc.findIndex(
      (object: any) => object.featureId === obj.feature.id
    )
    if (objIndex === -1) {
      //create custom object
      const customFeature = {
        featureId: obj.feature.id,
        featureName: obj.feature.name,
        discreteValues: [obj.value]
      }
      acc.push(customFeature)
    } else {
      //replace featureNames in existing object
      acc[objIndex].discreteValues.push(obj.value)
    }
    return acc
  },
  [])
  return customFeatureData
}

export const featuresAccumulator = (
  features: any,
  discreteValues: any
): any => {
  //create a new array consisting the feature with just the selected discrete values.
  const customFeatureData = features.reduce(function(
    acc: Array<FeaturePreview>,
    obj: AllFeaturesItem
  ) {
    if (
      obj.discreteValues.some(featureName =>
        discreteValues.includes(featureName.id)
      )
    ) {
      // filter the discreteValues data from feature(obj) that consist the featureName id
      const filterDiscreteValues: Array<DiscreteValues> = obj.discreteValues.filter(
        featureItem => discreteValues.includes(featureItem.id)
      )

      //find if the feature already exists in the accumulator
      const objIndex = acc.findIndex(accItem => accItem.featureId === obj.id)
      if (objIndex === -1) {
        //create custom object
        const filteredFeature = {
          featureId: obj.id,
          featureName: obj.name,
          discreteValues: [...filterDiscreteValues]
        }
        acc.push(filteredFeature)
      } else {
        //replace featureNames in existing object
        acc[objIndex].discreteValues = filterDiscreteValues
      }
    }
    return acc
  },
  [])
  return customFeatureData
}

export const accumulateFeatures2 = (expData: any, values: any): any => {
  return featuresAccumulator(expData.features, values.discreteValues)
}

export const accumulateFeatures3 = (features: any[]): any => {
  return features.reduce((acc: any[], obj: any) => {
    const objIndex = acc.findIndex(
      (item: any) =>
        item.featureId === obj.discreteValue?.feature?.id || obj.feature?.id
    )

    if (objIndex >= 0) {
      acc[objIndex].discreteValues = [
        ...acc[objIndex].discreteValues,
        { id: obj.id, value: obj.discreteValue?.value || obj.value }
      ]
    } else {
      acc.push({
        featureId: obj.discreteValue?.feature?.id || obj.feature?.id,
        featureName: obj.discreteValue?.feature?.name || obj.feature?.name,
        discreteValues: [
          {
            id: obj.discreteValue?.id || obj.id,
            value: obj.discreteValue?.value || obj.value
          }
        ]
      })
    }
    return acc
  }, [])
}
