//Login Form - This component will render the login form.
//The form "Field" component is from the resuable UI components - refer /Reusable_UI

import React from 'react'
import { Link } from 'react-router-dom'

//form handler
import { Form, Field } from 'formik'

//styled components
import { Icon, Button, Form as AntForm } from 'antd'

//functional components
import { AntInputPassword, AntInput } from '../Reusable_UI/Forms/FormFields'

//utilities
import { validateLength, validateRequired } from '../../Utilities/Validate'

//styles
import '../Reusable_UI/Styles.less'

//Antd components
const FormItem = AntForm.Item

//types for props from parent container Login.tsx
interface Props {
  loading: boolean
  submitCount: number
  values: any
}

const LoginForm = ({ loading, submitCount }: Props) => {
  return (
    <Form>
      <Field
        label="Username"
        name="username"
        type="text"
        validate={validateRequired}
        hasFeedback
        submitCount={submitCount}
        prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
        component={AntInput}
      />

      <Field
        label="Password"
        name="password"
        type="text"
        validate={validateLength}
        submitCount={submitCount}
        hasFeedback
        prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
        component={AntInputPassword}
      />
      
      <FormItem>
        {/* <Link to="/"> Forgot password</Link> */}
        <Button
          type="primary"
          htmlType="submit"
          disabled={loading}
          loading={loading}
          className="loginButton"
        >
          {' '}
          SIGN IN
        </Button>
        New User? <Link to="/register">Sign Up!</Link>
      </FormItem>
    </Form>
  )
}

export default LoginForm
