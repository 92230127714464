//mutation - login

import gql from 'graphql-tag'

export const LOGIN_USER = gql`
  mutation($username: String!, $password: String!) {
    tokenAuth(username: $username, password: $password) {
      token
    }
  }
`
