//This is a wrapper component wrapping the main content. It consists of a header, sidenav & footer.

import React, { Fragment, useContext } from 'react'
import { Link } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import { useApolloClient } from '@apollo/react-hooks'

//styled components
import { Layout, Icon, Tooltip } from 'antd'

//contexts
import { SideNavCtx } from '../Contexts/SideNavToggle'
import * as actions from '../Reducers/ActionTypes'

//utils
import { IS_AUTHENTICATED } from '../Graqhql/Authenticated'
import ErrorBoundary from '../Utilities/ErrorBoundary'

//images - logo
//import Logo from '../assets/dtx-company.png';
import Logo from '../assets/flowcode_white.png'

//functional component
import SideNavMenu from '../Components/Navbars/SideNavMenu'


//destructuring ant design components
const { Header, Content, Sider } = Layout

// inline styles
const logoDiv = {
  padding: '12px 14px',
  background: '#002140',
  transition: '0.1s all linear'
}

const collapsedLogoDiv = {
  padding: '12px 21px',
  background: '#002140',
  transition: '0.1s all linear'
}

const logoIcon = {
  height: '40px'
}

const header = {
  boxShadow: '0 2px 8px #d0d8e3',
  background: 'none',
  paddingLeft: 15,
  display: 'flex',
  alignItems: 'center'
}

const content = {
  margin: '32px 50px',
  minHeight: `calc(100vh)`,
  paddingBottom: 80
}

const collapsedIcon = {
  fontSize: '18px'
}

//JSX
const LayoutWrap: React.FC<any> = ({ props, children }) => {
  //apollo clients
  const client = useApolloClient()

  //contexts
  const { collapsed, dispatch } = useContext(SideNavCtx)

  //pathname
  const pathname = props.location.pathname

  // get authenticated state to display the sidebar only in authenticated components
  const { data } = useQuery(IS_AUTHENTICATED)
  const authenticated = data ? data.isAuthenticated : false

  const toggleSider = () => {
    dispatch({ type: actions.TOGGLE_SIDENAV, collapsed: !collapsed })
  }

  //logout function
  const Logout = () => {
    localStorage.removeItem('token')
    client.resetStore()
  }

  return (
    <div>
      <Layout>
        <Sider
          collapsible
          collapsed={collapsed}
          trigger={null}
          style={{ height: '100vh', position: 'fixed' }}
        >
          <Link to="/" role="navigation" aria-label="Home">
            <div style={collapsed ? collapsedLogoDiv : logoDiv}>
              <img src={Logo} alt="the dtx company" style={logoIcon} />
            </div>
          </Link>

          {/* if authenticated list the sider / side nav menu items */}
          {authenticated && (
            <Fragment>
              <SideNavMenu location={pathname} Logout={Logout} />
            </Fragment>
          )}
        </Sider>

        <Layout style={{ paddingLeft: collapsed ? '80px' : '200px' }}>
          <Header style={header}>
            <Tooltip
              title={collapsed ? 'Show sidebar' : 'Hide sidebar'}
              arrowPointAtCenter
              placement="right"
            >
              <Icon
                style={collapsedIcon}
                type={collapsed ? 'menu-unfold' : 'menu-fold'}
                onClick={toggleSider}
              />
            </Tooltip>
          </Header>

          <Content style={content}>
              <ErrorBoundary>
                {children}
              </ErrorBoundary>
          </Content>
        </Layout>
      </Layout>
    </div>
  )
}

export default LayoutWrap
