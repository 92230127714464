//This coponent will display the list of brands in a table

import React, { useContext } from 'react'

//styled components
import { Menu, Dropdown, Icon } from 'antd'

//stylesheet
import '../Reusable_UI/Styles.less'

//constants
import { columnsNames } from '../../Constants/Brands/BrandsList'

//context
import { EditModalCTX } from '../../Contexts/EditModalContext'

//typeScript
import { BrandsTableProps } from '../../TypeScript_Types/Brands'

//functional components
import NoData from '../Reusable_UI/NoData/NoData'

//React FC - JSX
const BrandsListTable = ({
  brandData,
  listLoading,
  setEditBrandId,
  deleteLoading,
  deleteId,
  handleBrandDelete
}: BrandsTableProps) => {
  //context
  const { handleVisible } = useContext(EditModalCTX)

  // pop up action menu
  const menu = (rowItems: any) => {
    return (
      <Menu>
        <Menu.Item
          key="1"
          onClick={() => {
            setEditBrandId(rowItems.id)
            handleVisible()
          }}
        >
          Edit
        </Menu.Item>
        <Menu.Item
          key="3"
          onClick={() => handleBrandDelete(rowItems.id, rowItems.name)}
        >
          Delete
        </Menu.Item>
      </Menu>
    )
  }

  return (
    <div style={{ marginTop: 10 }}>
      <table className="tableContainer">
        <thead className="tableHead">
          <tr>
            {columnsNames.map((item: any) => (
              <th key={item.key}>{item.title}</th>
            ))}
          </tr>
        </thead>

        {/* table list */}

        <tbody className="tableBody">
          {brandData &&
            brandData.map((rowItems: any, rowIndex) => {
              return (
                <tr key={rowIndex}>
                  <td>{rowItems.name}</td>
                  <td>{rowItems.primaryBrandContact}</td>
                  <td>{rowItems.customerSuccessRep}</td>
                  <td>
                    {rowItems.id === deleteId && deleteLoading ? (
                      <Icon type="loading" />
                    ) : (
                      <Dropdown
                        overlay={() => menu(rowItems)}
                        trigger={['click']}
                      >
                        <Icon type="more" />
                      </Dropdown>
                    )}
                  </td>
                </tr>
              )
            })}
        </tbody>
      </table>

      {/* no data or loading state */}
      {((brandData && brandData.length === 0) ||
        (!brandData && listLoading)) && <NoData loading={listLoading} />}
    </div>
  )
}

export default BrandsListTable
