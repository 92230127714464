//Edit flowcodes Form container

import React, { useContext, useState, useEffect, useRef, Fragment } from 'react'
import { Formik } from 'formik'
import { useQuery, useMutation } from '@apollo/react-hooks'

//context
import { EditModalCTX } from '../../Contexts/EditModalContext'

//graphql
import {
  GET_FLOWCODES_EDIT,
  GET_FLOWCODES_LIST
} from '../../Graqhql/Flowcodes/FlowcodesQuery'
import {
  UPDATE_BATCH,
  UPDATE_FLOWCODES
} from '../../Graqhql/Flowcodes/FlowcodesMutation'

//typescript
import { FlowcodesFormValues } from '../../TypeScript_Types/Flowcodes'

//utilities
import ErrorBoundary from '../../Utilities/ErrorBoundary'
import { splitURLs, constructRedirectValue } from '../../Utilities/Flowcode'

//functional component
import EditFlowcode from '../../Components/Flowcodes/Create_Edit_Flowcode'
import ModalWrap from '../../Components/Reusable_UI/Modal/ModalFormWrap'
import { LoadingSpinner } from '../../Components/Reusable_UI/Loaders_Spinners/LoadingSpinner'
import ErrorAlert from '../../Components/Reusable_UI/Alerts/ErrorAlerts'

//initial form fields
const initialFieldValues: FlowcodesFormValues = {
  type: '',
  brand: [],
  redirectValue: '',
  attributes: [],
  isScan: true,
  redirectValue1: '',
  redirectValue2: '',
  redirectValue3: '',
  redirectValue4: '',
  redirectValue5: '',
  redirectValue6: ''
}

//types for form fields state
interface FormValues {
  type: string
  brand: Array<string>
  redirectValue: string
  attributes: Array<string>
  isScan: boolean
  redirectValue1?: string
  redirectValue2?: string
  redirectValue3?: string
  redirectValue4?: string
  redirectValue5?: string
  redirectValue6?: string
}

interface Props {
  permutationId: string
  updateId: {
    id: string
    flowcodeId: string
  }
  flowcodeType: string
}

const EditFlowcodes = ({
  permutationId,
  updateId,
  flowcodeType
}: Props): JSX.Element => {
  //local state
  const [flowcodesFields, setFlowcodesFields] = useState(initialFieldValues)
  const [flowcodesFetchError, setFlowcodesFetchError] = useState()
  const [initialAttributes, setInitialAttributes] = useState()
  const [initialBrands, setInitialBrands] = useState()
  //for delete
  const [initialBrandsSet, setInitialBrandsSet] = useState()
  const [initialAttributesSet, setInitialAttributesSet] = useState()
  const [initialDataReady, setInitialDataReady] = useState(false)

  //ref to call reset form
  const resetRef: any = useRef(null)

  //context
  const {
    viewEditFormModal,
    handleVisible,
    setEditSuccess,
    editError,
    setEditError,
    editErrorMsg,
    setEditErrorMsg
  } = useContext(EditModalCTX)

  //get flowcodes options
  const { data: flowcodesData, loading: flowcodesLoading } = useQuery(
    GET_FLOWCODES_EDIT,
    {
      variables: {
        id: updateId.id,
        permutationId: permutationId,
        category: 'flowcode'
      },
      onError(error) {
        setFlowcodesFetchError(error)
      }
    }
  )

  //create the initial form data from the fetched data
  //Note - currently the program is build to support only one flowcode.
  useEffect(() => {
    if (flowcodesData) {
      const { batch } = flowcodesData
      const redirectValues = splitURLs(
        batch.batchFlowcodes[0].redirectValue
      ).reduce((prev, curr, i) => {
        prev[`redirectValue${i + 1}`] = curr
        return prev
      }, {} as any)
      // set the initial form data
      const initialData = {
        ...initialFieldValues,
        type: batch.type.charAt(0) + batch.type.substring(1).toLowerCase(),
        brand: batch.batchFlowcodes[0].flowcodeBrandSet.map(
          (item: any) => item.permutationBrand.id
        ),
        redirectValue: batch.batchFlowcodes[0].redirectValue, // remove this
        attributes: batch.attributes.map((item: { id: string }) => item.id),
        isScan: batch.isScan,
        ...redirectValues
      }
      //form fields
      setFlowcodesFields(initialData)
      //intial brands & attributes - add
      setInitialAttributes(batch.batchDiscreteSet)
      setInitialBrands(batch.batchFlowcodes[0].flowcodeBrandSet)
      //initial brands & attributes - delete
      setInitialAttributesSet(batch.batchDiscreteSet)
      setInitialBrandsSet(batch.batchFlowcodes[0].flowcodeBrandSet)
      setInitialDataReady(true)
    }
  }, [flowcodesData])

  //reset form on modal/form close action
  useEffect(() => {
    if (viewEditFormModal) {
      resetRef.current && resetRef.current.click()
    }
  }, [viewEditFormModal])

  //edit flowcodes mutation
  //update batch
  const [updateBatch, { loading: updateBatchLoading }] = useMutation(
    UPDATE_BATCH,
    {
      onError(error) {
        setEditError(true)
        setEditErrorMsg(error.message)
      }
      // refetchQueries: [{ query: GET_FLOWCODES_LIST, variables:{id: permutationId}  }]
    }
  )

  //update flowcodes
  const [updateFlowcodes, { loading: updateFlowcodeLoading }] = useMutation(
    UPDATE_FLOWCODES,
    {
      onCompleted() {
        //if create and clone is false, close the modal and set success to true.
        if (!editError) {
          setEditSuccess(true)
          handleVisible()
        }
      },
      onError(error) {
        setEditError(true)
        setEditErrorMsg(error.message)
      },
      refetchQueries: [
        { query: GET_FLOWCODES_LIST, variables: { id: permutationId } }
      ]
    }
  )

  //handle submit
  //Note - uncomment attributes and brand when fixed in backend
  const handleSubmit = (values: FormValues): void => {
    //update batch
    const inputBatch = {
      type: values.type.toUpperCase(),
      isScan: values.isScan
    }

    //attributes
    const deleteAttributes =
      initialAttributesSet.length > 0
        ? initialAttributesSet
            .filter(
              (attribute: { id: string; discreteValue: { id: string } }) =>
                !values.attributes.includes(attribute.discreteValue.id)
            )
            .map((filterAttribute: { id: string }) => filterAttribute.id)
        : []

    const addAttributes =
      initialAttributes.length > 0
        ? values.attributes.filter((attribute: string) =>
            initialAttributes.some((id: string) => id !== attribute)
          )
        : values.attributes
    //flowcode
    const inputFlowcode = { redirectValue: constructRedirectValue(values) }

    //brands
    const deleteBrand =
      initialBrandsSet.length > 0
        ? initialBrandsSet
            .filter(
              (flowcodeBrandSet: {
                id: string
                permutationBrand: { id: string }
              }) => !values.brand.includes(flowcodeBrandSet.permutationBrand.id)
            )
            .map((filterBrand: { id: string }) => filterBrand.id)
        : []

    const addBrand =
      initialBrands.length > 0
        ? values.brand.filter((brand: string) =>
            initialBrands.some((id: string) => id !== brand)
          )
        : values.brand

    updateBatch({
      variables: {
        id: updateId.id,
        input: inputBatch,
        deleteAttributes,
        addAttributes
      }
    })
    updateFlowcodes({
      variables: {
        id: updateId.flowcodeId,
        input: inputFlowcode,
        addBrand,
        deleteBrand
      }
    })
  }

  //const modal buttons
  const buttons = [
    {
      buttonName: 'Edit',
      key: 'edit'
    }
  ]

  const batchId = flowcodesData?.batch?.id
  const title = `Edit Flowcode - ${batchId || ''}`

  return (
    <Formik
      enableReinitialize
      initialValues={flowcodesFields}
      onSubmit={handleSubmit}
      validateOnBlur={false}
    >
      {({ submitCount, submitForm, values, setFieldValue, handleReset }) => (
        <ModalWrap
          title={title}
          buttons={buttons}
          submitForm={submitForm}
          loading={updateFlowcodeLoading || updateBatchLoading}
          handleVisible={handleVisible}
          viewFormModal={viewEditFormModal}
        >
          {/* Display error if occurred during fetching form options */}
          {flowcodesFetchError ? (
            <ErrorAlert
              message={
                flowcodesFetchError.message
                  ? flowcodesFetchError.message
                  : 'Something went wrong'
              }
            />
          ) : (
            <>
              {/* flowcodes error and loading */}
              {flowcodesLoading ? (
                <LoadingSpinner />
              ) : (
                <Fragment>
                  {/* error updating flowcodes  */}
                  {editError && (
                    <ErrorAlert
                      message={
                        editErrorMsg ? editErrorMsg : 'Something went wrong'
                      }
                      close={true}
                      onErrorClose={() => {
                        setEditError(false)
                        setEditErrorMsg('')
                      }}
                    />
                  )}
                  <ErrorBoundary>
                    <EditFlowcode
                      submitCount={submitCount}
                      flowcodesData={flowcodesData}
                      featuresData={
                        flowcodesData &&
                        flowcodesData.features?.filter(
                          ({ archived }: { archived: boolean }) => !archived
                        )
                      }
                      values={values}
                      flowcodeType={flowcodeType}
                      setFieldValue={setFieldValue}
                      initialDataReady={initialDataReady}
                      setInitialDataReady={setInitialDataReady}
                    />
                  </ErrorBoundary>
                </Fragment>
              )}
            </>
          )}
          {/* reset form div ref */}
          <div onClick={handleReset} ref={resetRef} />
        </ModalWrap>
      )}
    </Formik>
  )
}

export default EditFlowcodes
