import React, { createContext, useReducer } from 'react'
import { toggleSideNav } from '../Reducers/SidenavToggle'

//This context will provide the expanded or collapsed state of the side nav to the children components
//from local reducer
export const SideNavCtx: any = createContext({})

//Reducer inital state
const initialState = {
  collapsed: false
}

const SideNavCtxProvider = (props: any) => {
  const [{ collapsed }, dispatch] = useReducer(toggleSideNav, initialState)

  return (
    <SideNavCtx.Provider value={{ collapsed, dispatch }}>
      {props.children}
    </SideNavCtx.Provider>
  )
}

export default SideNavCtxProvider
