//SaveBuildBatch - invoked after successful create flowcode on type MANY
import React, { useContext, Fragment } from 'react'
import { Formik } from 'formik'
import { useMutation } from '@apollo/react-hooks'

//context
import { CreateModalCTX } from '../../Contexts/CreateModalContext'

//graphql
import { BUILD_BATCH } from '../../Graqhql/Flowcodes/FlowcodesMutation'

//utilities
import ErrorBoundary from '../../Utilities/ErrorBoundary'

//functional component
import ErrorAlert from '../../Components/Reusable_UI/Alerts/ErrorAlerts'
import BuildQuantityForm from '../../Components/Build/BuildQuantity'
import ModalWrap from '../../Components/Reusable_UI/Modal/ModalFormWrap'


//initial form fields
const initialFieldValues: FormValues = {
  quantity: 0
}

//types for form fields state
interface FormValues {
  quantity: number
}

interface Props {
  batchId: string
  saveAndBuild: boolean
  setCreateComplete: React.Dispatch<React.SetStateAction<boolean>>
  handleSaveBuild: () => void
}

const SaveBuildBatch: React.FC<Props> = ({
  batchId,
  saveAndBuild,
  handleSaveBuild,
  setCreateComplete
}) => {
  //context
  const {
    handleVisible,
    setCreateSuccess,
    setCreateError,
    setCreateErrorMsg,
    createError,
    createErrorMsg
  } = useContext(CreateModalCTX)

  //build batch mutation
  const [buildBatch, { loading: buildBatchLoading }] = useMutation(
    BUILD_BATCH,
    {
      onCompleted() {
        //reset save & build state
        handleSaveBuild()
        //reset create form complete state
        setCreateComplete(false)
        setCreateSuccess(true)
        handleVisible()
      },
      onError(error) {
        setCreateError(true)
        setCreateErrorMsg(error.message)
      }
    }
  )

  //handle submit
  const handleSubmit = (values: FormValues) => {
    buildBatch({ variables: { id: batchId, quantity: values.quantity } })
  }

  //const modal buttons
  const buttons = [
    {
      buttonName: 'Build',
      key: 'build'
    }
  ]

  return (
    <Formik
      initialValues={initialFieldValues}
      onSubmit={handleSubmit}
      validateOnBlur={false}
    >
      {({ submitCount, submitForm }) => (
        <ModalWrap
          title="Build batch"
          buttons={buttons}
          submitForm={submitForm}
          loading={buildBatchLoading}
          handleVisible={handleVisible}
          viewFormModal={saveAndBuild}
        >
          <Fragment>
            {/* error creating build  */}
            {createError && (
              <ErrorAlert
                message={
                  createErrorMsg ? createErrorMsg : 'Something went wrong'
                }
                close={true}
                onErrorClose={() => {
                  setCreateError(false)
                  setCreateErrorMsg('')
                }}
              />
            )}
            <ErrorBoundary>
              <BuildQuantityForm submitCount={submitCount} />
            </ErrorBoundary>
          </Fragment>
        </ModalWrap>
      )}
    </Formik>
  )
}

export default SaveBuildBatch
