import { AllBrandItem } from '../TypeScript_Types/Brands'

//format numbers with commas
export const numberWithCommas = (x: number): string => {
  return x
    ?.toString()
    .split(',')
    .join('')
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

//remove commas from a number/string
export const removeCommas = (x: any): string => {
  return x
    ?.toString()
    .split(',')
    .join('')
}

export interface FormatPermutationName {
  values: {
    medium: string
    brands: string[]
    variables: string[]
    channel: string
  }
  brandsList: AllBrandItem[]
  permutationData: {
    experiment: any
  }
  date?: string
}

export const formatPermutationName = ({
  values,
  brandsList,
  permutationData
}: FormatPermutationName): string => {
  const medium = values.medium ? values.medium : ''
  const brands = values.brands
    .map((brandId: string) => {
      const brand = brandsList.find(
        (brand: { id: string }) => brand?.id === brandId
      )
      return brand?.name
    })
    .join('-')
  const experimentDiscreteSet =
    permutationData?.experiment?.experimentDiscreteSet
  const features = values.variables
    .map((variableId: string) => {
      const feature = experimentDiscreteSet?.find(
        (experimentDiscreteValue: {
          id: string
          discreteValue: { id: string }
        }) =>
          experimentDiscreteValue?.discreteValue?.id === variableId || // create and edit have different data structure
          experimentDiscreteValue?.id === variableId
      )
      return feature?.discreteValue?.value?.toLowerCase()
    })
    .join('-')
  // const identifier = permutationData?.experiment?.experimentIdentifier || ''
  const channel = values.channel?.toLowerCase()
  return [
    brands,
    channel,
    medium.toLowerCase().replace(/,/g, '-'),
    // identifier,
    features
  ]
    .filter(Boolean)
    .join('_')
}
