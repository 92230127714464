//mutation - register

import gql from 'graphql-tag'

export const REGISTER_USER = gql`
  mutation($username: String!, $email: String!, $password: String!) {
    createUser(username: $username, email: $email, password: $password) {
      token
    }
  }
`
