//Container - create brand

import React, { Fragment, useContext, useRef, useEffect } from 'react'
import { Formik } from 'formik'
import { useMutation } from '@apollo/react-hooks'

//graphql
import { CREATE_BRAND } from '../../Graqhql/Brands/BrandsMutations'
import { GET_BRANDS } from '../../Graqhql/Brands/BrandQuery'

//context
import { CreateModalCTX } from '../../Contexts/CreateModalContext'

//typescript types
import { BrandsFormValues } from '../../TypeScript_Types/Brands'

//utilities
import ErrorBoundary from '../../Utilities/ErrorBoundary'

//functional components
import CreateBrandsForm from '../../Components/Brands/Create_Edit_BrandsForm'
import ModalWrap from '../../Components/Reusable_UI/Modal/ModalFormWrap'
import ErrorAlert from '../../Components/Reusable_UI/Alerts/ErrorAlerts'

//const form fields initial state
const initialFieldValues: BrandsFormValues = {
  name: '',
  description: '',
  primaryBrandContact: '',
  customerSuccessRep: ''
}

//const modal buttons
const buttons = [
  {
    buttonName: 'Create',
    key: 'create'
  }
]

//JSX
const CreateBrand: React.FC<any> = () => {
  //ref to call reset form
  const resetRef: any = useRef(null)

  //context
  const {
    viewCreateFormModal,
    handleVisible,
    setCreateSuccess,
    createError,
    setCreateError,
    createErrorMsg,
    setCreateErrorMsg
  } = useContext(CreateModalCTX)

  //resetForm when there is changed in the view state of the modal,
  //do not move the reference click to handleVisible function, it will change the animation direction of the modal
  useEffect(() => {
    resetRef.current && resetRef.current.click()
  }, [viewCreateFormModal])

  //create brand graphql mutation
  const [createBrand, { loading }] = useMutation(CREATE_BRAND, {
    onCompleted() {
      //on success set the sucess state to true and close the modal
      setCreateSuccess(true)
      handleVisible()
    },
    onError(error: any) {
      setCreateError(true)
      setCreateErrorMsg(error.message)
    },
    refetchQueries: [{ query: GET_BRANDS }]
  })

  // create brand submit
  const handleSubmit = (values: BrandsFormValues) => {
    createBrand({ variables: { input: values } })
  }

  //handle modal visible or hidden and reset state
  return (
    <Fragment>
      <Formik
        initialValues={initialFieldValues}
        onSubmit={handleSubmit}
        validateOnBlur={false}
      >
        {({ isSubmitting, submitCount, submitForm, handleReset }) => (
          <ModalWrap
            title="Create Brand"
            submitForm={submitForm}
            buttons={buttons}
            loading={loading}
            handleVisible={handleVisible}
            viewFormModal={viewCreateFormModal}
          >
            {/* error alert */}
            {createError && (
              <ErrorAlert
                message={
                  createErrorMsg ? createErrorMsg : 'Error creating brand.'
                }
                close={true}
                onErrorClose={() => {
                  setCreateError(false)
                  setCreateErrorMsg('')
                }}
              />
            )}

            {/* create brands form */}
            <ErrorBoundary>
              <CreateBrandsForm
                isSubmitting={isSubmitting}
                submitCount={submitCount}
              />
            </ErrorBoundary>

            {/* reset form div ref */}
            <div onClick={handleReset} ref={resetRef} />
          </ModalWrap>
        )}
      </Formik>
    </Fragment>
  )
}

export default CreateBrand
