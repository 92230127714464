//This component will display a spinning wheel with 'loading...' text

import React from 'react'

//functional components
import { Spin } from 'antd'

export const LoadingSpinner = () => {
  return (
    <div style={{ height: '100px' }}>
      <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
        <Spin tip="Loading..."></Spin>
      </div>
    </div>
  )
}
