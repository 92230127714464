//This component will create formik based input fields using ant d.

//NOTE - type="text" is necessary for "Input" type fields

import React from 'react'
import { FieldProps } from 'formik'

//styled component
import { Form, Input, TimePicker, DatePicker, Select } from 'antd'

//Ant d component destructuring
const FormItem = Form.Item
const { Option } = Select

const isValueInputType = (value: string): boolean =>
  ['Numerical', 'Freetext', 'Boolean'].includes(value)

//typescript for form fields
interface formFields {
  prefix: React.ReactNode
  label: string
  extra?: string
  hasFeedback?: boolean
  selectOptions: Array<string>
  type: string
  submitCount: number
  setFieldValue?: (name: string, value: any) => void
}

//type class
type Class = { new (...args: any[]): any }

//JSX
const CreateAntField = (AntComponent: Class) => ({
  field,
  form,
  hasFeedback,
  label,
  extra,
  selectOptions,
  type,
  submitCount,
  setFieldValue,
  ...props
}: FieldProps<any> & formFields) => {
  const touched = form.touched[field.name]
  const submitted = submitCount > 0
  const hasError = form.errors[field.name]
  const submittedError = hasError && submitted
  const touchedError = hasError && touched

  const onInputChange = ({ target: { value } }: any) =>
        form.setFieldValue(field.name, value)

  const onChange = (value: any) => {
    if (isValueInputType(value) && setFieldValue) {
      setFieldValue('discreteValues', [])

      if (value === 'Boolean')
      {
        setFieldValue('discreteValues', [{ value: 'TRUE' }, { value: 'FALSE' }])
      }
    }
    form.setFieldValue(field.name, value)
  }

  const onBlur = () => form.setFieldTouched(field.name, true) 
  return (
    <div className="field-container">
      <FormItem
        label={label}
        extra={extra}
        hasFeedback={
          (hasFeedback && submitted) || (hasFeedback && touched) ? true : false
        }
        help={submittedError || touchedError ? hasError : false}
        validateStatus={submittedError || touchedError ? 'error' : 'success'}
      >
        <AntComponent
          {...field}
          {...props}
          spellCheck={false}
          onBlur={onBlur}
          onChange={type ? onInputChange : onChange}
        >
          {selectOptions &&
            selectOptions.map((name, index) => (
              <Option key={index} value={name}>
                {name}
              </Option>
            ))}
        </AntComponent>
      </FormItem>
    </div>
  )
}

//components
export const AntSelect = CreateAntField(Select)
export const AntDatePicker = CreateAntField(DatePicker)
export const AntTimePicker = CreateAntField(TimePicker)
export const AntInput = CreateAntField(Input)
export const AntInputPassword = CreateAntField(Input.Password)
export const AntTextarea = CreateAntField(Input.TextArea)
