//This component is used to create a toggled List or Select dropdown.

import React, { useRef, useState } from 'react'
//ant destructuring
import { Form, Select, List } from 'antd'
//utility
import ToggleWrapper from './ToggleWrapper'

//ant design component destructuring
const { Item } = Form
const { Option } = Select

//typescript
interface ListSelectProps {
  name: string
  displayValue: any[]
  selectValue?: any[]
  fieldValue?: (v: any) => any
  options: any[]
  optionValue: (v: any) => string
  optionName: (v: any) => string
  setFieldValue: (value: any) => void
  onElBlur?: () => void
  onElChange?: () => void
  help?: string | boolean
  validateStatus?: '' | 'error' | 'success'
  optionFilterProp?: string
  formField?: any
  disableSelect?: boolean

  //disable list is used in view permutation, once the final number is set to true
  //further editing must not be allowed
  disableList?: boolean
}

//JSX
const ToggleListSelect = ({
  name,
  displayValue,
  selectValue,
  fieldValue,
  options,
  optionValue,
  optionName,
  setFieldValue,
  onElBlur,
  onElChange,
  help,
  validateStatus,
  optionFilterProp,
  formField,
  disableSelect = false,
  disableList = false
}: ListSelectProps): JSX.Element => {
  //ref
  const ref = useRef()
  //local state
  const [isEdit, setIsEdit] = useState(false)

  return (
    <Item
      label={name[0].toUpperCase() + name.slice(1)}
      help={help}
      validateStatus={validateStatus}
    >
      <ToggleWrapper
        editable={isEdit}
        elRef={ref}
        autoFocus
        //static display element
        staticEl={
          <div
            onClick={() => {
              !disableList && setIsEdit(true)
            }}
            className={disableList ? 'd-pointer' : 'c-pointer'}
          >
            <List
              bordered
              dataSource={displayValue}
              renderItem={(item: any) => (
                <List.Item> {item.name || item} </List.Item>
              )}
              style={{ background: 'white' }}
            />
          </div>
        }
        //editable input element
        editableEl={
          <Select
            mode="multiple"
            placeholder={'Select ' + name}
            allowClear
            value={selectValue}
            {...formField}
            onChange={(value: any) => {
              setFieldValue(fieldValue ? fieldValue(value) : value)
              if (onElChange) onElChange()
            }}
            onBlur={() => {
              setIsEdit(false)
              if (onElBlur) onElBlur()
            }}
            ref={(node: any) => (ref.current = node)}
            optionFilterProp={optionFilterProp}
            disabled={disableSelect}
          >
            {options &&
              options.map((o: any) => (
                <Option
                  key={optionValue(o)}
                  value={optionValue(o)}
                  title={optionName(o)}
                >
                  {optionName(o)}
                </Option>
              ))}
          </Select>
        }
      />
    </Item>
  )
}

export default ToggleListSelect
