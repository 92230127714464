//This component will generate the permutations details in a tabular form in view permutation
//This coponent will display the table of the experiment list

import React, { useState, useContext } from 'react'

//styled components
import { Dropdown, Icon, Menu } from 'antd'

//constants
import { columnsNames } from '../../Constants/Permutations/PermutationsTableColumns'

//functional component
import NoData from '../Reusable_UI/NoData/NoData'

//typeScript
import { PermutationTableProps } from '../../TypeScript_Types/Permutations'
import { DownloadModalContext } from '../../Contexts/DownloadModalContext'

//inline style
const moreStyle = {
  color: '#1890ff',
  cursor: 'pointer',
  paddingLeft: '5px'
}

const dropdownOptionStyle = {
  color: '#1890ff',
  cursor: 'pointer',
  marginBottom: 0
}

//default numbers of feature names to be shown in the table
const initialShowCount = 3

const ViewPermutationsTable = ({
  permutationData,
  experimentId,
  listLoading,
  deletePermutationId,
  deleteLoading,
  handleDeletePermutation,
  history,
  handleClonePermutation,
  handleDownloadCSV
}: PermutationTableProps): JSX.Element => {
  const { handleVisible } = useContext(DownloadModalContext)

  //local states
  const [showCount, setShowCount] = useState(initialShowCount)
  const [showMoreRowId, setShowMoreRowID] = useState()

  // pop up action menu
  const menu = (rowItems: { id: string; name: string }): JSX.Element => {
    return (
      <Menu>
        {/*<Menu.Item key="1" onClick={() => { setEditPermutationId(rowItems.id); handleVisible() }}>
          Edit permutation
            </Menu.Item>*/}
        <Menu.Item
          key="2"
          onClick={() => handleDeletePermutation(rowItems.id, rowItems.name)}
        >
          Delete permutation
        </Menu.Item>
        <Menu.Item key="3" onClick={() => handleClonePermutation(rowItems.id)}>
          Clone Permutation
        </Menu.Item>
        <Menu.Item
          key="4"
          onClick={() => {
            handleDownloadCSV(rowItems.id, rowItems.name)
            handleVisible()
          }}
        >
          Download CSV
        </Menu.Item>
      </Menu>
    )
  }

  //show more features to increase the show count of the displayed feature
  //it will be in the increment of 3 or whatever the max difference upto 2.
  const showMoreFeatures = (rowID: string, featuresListCount: number): void => {
    let showCountTemp = initialShowCount

    //if the rowID doesnt match, set to initial.
    //This indicated that the show more is for a new row.
    if (rowID !== showMoreRowId) {
      setShowMoreRowID(rowID)
    } else {
      //else set it to the latest show count
      showCountTemp = showCount
    }

    if (showCountTemp < featuresListCount) {
      if (featuresListCount - showCountTemp > initialShowCount) {
        setShowCount(showCountTemp + initialShowCount)
      } else {
        setShowCount(showCountTemp + (featuresListCount - showCountTemp))
      }
    }
  }

  return (
    <div style={{ marginTop: 10 }} className="overflow-x-scroll">
      <table className="tableContainer view-permutation-table">
        <thead className="tableHead">
          <tr>
            {columnsNames.map((item: any) => (
              <th key={item.key}>{item.title}</th>
            ))}
          </tr>
        </thead>

        {/* table list */}

        <tbody className="tableBody">
          {permutationData &&
            permutationData.map((rowItems, rowIndex: number) => {
              return (
                <tr
                  key={rowIndex}
                  onClick={() =>
                    history.push(
                      `/${experimentId}/${rowItems.id}/view_permutation`
                    )
                  }
                >
                  {/* create ellips for name longer than 60 characters */}
                  <td>
                    {rowItems.name.length > 60
                      ? `${rowItems.name.substring(0, 60)}...`
                      : rowItems.name}
                  </td>

                  <td>
                    {showMoreRowId === rowItems.id
                      ? rowItems.variables
                          .map(variable => variable.discreteValue.value)
                          .flat()
                          .slice(0, showCount)
                          .join(', ')
                      : rowItems.variables
                          .map(variable => variable.discreteValue.value)
                          .flat()
                          .slice(0, initialShowCount)
                          .join(', ')}
                    {rowItems.variables.length <=
                    initialShowCount ? null : rowItems.variables.length <=
                        showCount && showMoreRowId === rowItems.id ? null : (
                      <span
                        onClick={() =>
                          showMoreFeatures(
                            rowItems.id,
                            rowItems.variables.length
                          )
                        }
                        style={moreStyle}
                      >
                        more
                      </span>
                    )}
                  </td>

                  <td>{rowItems.flowcodeCount}</td>

                  <td>{new Date(rowItems.timeStamp).toLocaleString()}</td>
                  {rowItems.id === deletePermutationId && deleteLoading ? (
                    <td style={{ textAlign: 'center' }}>
                      <Icon type="loading" />
                    </td>
                  ) : (
                    // menu
                    <td onClick={e => e.stopPropagation()}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {/* dropdown more menu */}
                        <Dropdown
                          overlay={() => menu(rowItems)}
                          trigger={['click']}
                        >
                          <p style={dropdownOptionStyle}>More</p>
                        </Dropdown>
                      </div>
                    </td>
                  )}
                </tr>
              )
            })}
        </tbody>
      </table>

      {/* show loader or no data */}
      {(!permutationData && listLoading) ||
      (permutationData && permutationData.length < 1) ? (
        <NoData loading={listLoading} />
      ) : null}
    </div>
  )
}

export default ViewPermutationsTable
