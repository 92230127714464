//container to edit feature.
import React, { Fragment, useEffect, useRef, useContext, useState } from 'react'
import { Formik } from 'formik'
import { useMutation, useQuery } from '@apollo/react-hooks'

//graphql
import { UPDATE_FEATURE } from '../../Graqhql/Features/FeatureMutation'
import { GET_FEATURES, GET_FEATURE } from '../../Graqhql/Features/FeatureQuery'

//constants
import { ValueType, TypeValue } from '../../Constants/Features/FeatureValueType'

//context
import { EditModalCTX } from '../../Contexts/EditModalContext'

//typeScript types
import { FeaturesFormValues } from '../../TypeScript_Types/Features'

//utilities
import ErrorBoundary from '../../Utilities/ErrorBoundary'

//functional components
import EditFeatureForm from '../../Components/Feature/Edit_FeatureForm'
import ModalWrap from '../../Components/Reusable_UI/Modal/ModalFormWrap'
import ErrorAlert from '../../Components/Reusable_UI/Alerts/ErrorAlerts'
import { LoadingSpinner } from '../../Components/Reusable_UI/Loaders_Spinners/LoadingSpinner'

//types props
interface Props {
  editFeatureId: string
}

//initial fields
let initialFieldValues: FeaturesFormValues = {
  name: '',
  category: '',
  type: '',
  featureName: '',
  discreteValues: [],
  channel: []
}

//const modal buttons
const buttons = [
  {
    buttonName: 'Edit',
    key: 'editFeature'
  }
]

//JSX - React FC
const EditFeature: React.FC<Props> = ({ editFeatureId }) => {
  //local states
  const [featureError, setFeatureError] = useState()
  const [featureInfo, setfeatureInfo] = useState(initialFieldValues)
  const [channelChoices, setChannelChoices] = useState([])
  const [initialDiscreteValues, setinitialDiscreteValues] = useState()

  //context
  const {
    handleVisible,
    setEditSuccess,
    setEditErrorMsg,
    editError,
    setEditError,
    editErrorMsg,
    viewEditFormModal
  } = useContext(EditModalCTX)

  //get feature details
  const { data: featureData, loading: featureLoading } = useQuery(GET_FEATURE, {
    variables: { id: editFeatureId },
    onError(error) {
      setFeatureError(error)
    }
  })

  //update intial state
  useEffect(() => {
    if (featureData) {
      const { feature } = featureData
      let tempInitialVal = Object.create(initialFieldValues)

      tempInitialVal.name = feature.name
      tempInitialVal.category = feature.category
      tempInitialVal.channel = feature.channel
      // get the id and discreteValue remove items like "__typename"
      tempInitialVal.discreteValues = feature.discreteValues.map(
        (item: { id: string; value: string }) => {
          return { id: item['id'], value: item['value'] }
        }
      )

      tempInitialVal.type = TypeValue[feature.type]

      setfeatureInfo(tempInitialVal)

      let channels = featureData.channels.map((channel: any) => channel.name)
      setChannelChoices(channels)

      //store the intial state of feature names, this will be used to find addValues during handleSubmit
      setinitialDiscreteValues(feature.discreteValues)
    }
  }, [featureData])

  //ref to call reset form
  const resetRef: any = useRef(null)

  //useEffect - reset form if the modal state changes from true to false.
  //Do not move the reset ref call inside handleVisible, it will change the transform origin of the modal.
  useEffect(() => {
    //reset modal form
    if (viewEditFormModal) {
      resetRef.current && resetRef.current.click()
    }
  }, [viewEditFormModal])

  //graphql mutation - call create feature mutation and then refresh the list
  const [editFeature, { loading }] = useMutation(UPDATE_FEATURE, {
    onCompleted() {
      setEditSuccess(true)
      // reset other states and close the modal
      handleVisible()
    },
    onError(error) {
      setEditError(true)
      setEditErrorMsg(error.message)
    },
    refetchQueries: [{ query: GET_FEATURES }]
  })
  //submit form
  const handleSubmit = (values: FeaturesFormValues) => {
    const input = {
      name: values.name,
      category: values.category,
      channel: values.channel,
      type: ValueType[values.type]
    }

    let addValues = values.discreteValues.filter(
      (feature: any) =>
        !initialDiscreteValues.some(
          (initialFeature: { id: string; value: string }) =>
            initialFeature.id === feature.id
        )
    )

    const updateValues = values.discreteValues.filter((feature: any) =>
      initialDiscreteValues.some(
        (initialFeature: { id: string; value: string }) =>
          initialFeature.id === feature.id
      )
    )

    const deleteValues = initialDiscreteValues
      .filter(
        (initialFeature: { id: string; value: string }) =>
          !values.discreteValues.some(
            (feature: any) => initialFeature.id === feature.id
          )
      )
      .map((deleteItem: { id: string; value: string }) => deleteItem.id)

    editFeature({
      variables: {
        id: editFeatureId,
        input,
        addValues,
        deleteValues,
        updateValues
      }
    })
  }

  return (
    <Fragment>
      <Formik
        enableReinitialize
        initialValues={featureInfo}
        onSubmit={handleSubmit}
        validateOnBlur={false}
      >
        {({ submitCount, values, submitForm, handleReset, setFieldValue }) => (
          <ModalWrap
            title="Edit Feature"
            buttons={buttons}
            submitForm={submitForm}
            loading={loading}
            handleVisible={handleVisible}
            viewFormModal={viewEditFormModal}
          >
            {/* error alert - update mutation.  NOTE - success is handled in the parent component  */}
            {editError && (
              <ErrorAlert
                message={editErrorMsg ? editErrorMsg : 'Something went wrong'}
                close={true}
                onErrorClose={() => {
                  setEditError(false)
                  setEditErrorMsg('')
                }}
              />
            )}

            {/* edit alert - get feature details */}
            {featureError ? (
              <ErrorAlert
                message={
                  featureError.message
                    ? featureError.message
                    : 'Something went wrong'
                }
              />
            ) : (
              <Fragment>
                {/* show spinner or data */}
                {featureLoading ? (
                  <LoadingSpinner />
                ) : (
                  <ErrorBoundary>
                    <EditFeatureForm
                      setFieldValue={setFieldValue}
                      submitCount={submitCount}
                      values={values}
                      channelChoices={channelChoices}
                    />
                  </ErrorBoundary>
                )}
              </Fragment>
            )}

            {/* reset form div ref */}
            <div onClick={handleReset} ref={resetRef} />
          </ModalWrap>
        )}
      </Formik>
    </Fragment>
  )
}

export default EditFeature
