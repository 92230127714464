import React, { useState, Fragment, useEffect } from 'react'
import { useMutation } from '@apollo/react-hooks'
import _ from 'lodash'
import { Formik } from 'formik'

//styled components
import { Row, Col, Form as AntForm, Icon, Tooltip } from 'antd'

import {
  submitEditExperiment,
  createExperimentFormData
} from '../../../Utilities/Experiment'
import { UPDATE_EXPERIMENT } from '../../../Graqhql/Experiment/MutationExperiment'
import { GET_EDIT_EXPERIMENT } from '../../../Graqhql/Experiment/QueryExperiment'
//typescript
import {
  ExperimentFormValues,
  EditExperiment,
  DtxcloudUsers
} from '../../../TypeScript_Types/ExperimentTypes'
import ErrorAlert from '../../Reusable_UI/Alerts/ErrorAlerts'
import { AllBrands } from '../../../TypeScript_Types/Brands'
import { AllChannels } from '../../../TypeScript_Types/Channels'
import { AllFeatures } from '../../../TypeScript_Types/Features'

//utilities
import ErrorBoundary from '../../../Utilities/ErrorBoundary'

//functional components
import ExperimentForm from '../Create_Edit_ExperimentForm'

// ant design component destructuring
const FormItem = AntForm.Item

//typescript
interface Props {
  selectOptions: AllBrands & AllChannels & AllFeatures & DtxcloudUsers
  experiment: EditExperiment
  expDecodedId: string
}

//form initial values
const initialFieldValues: ExperimentFormValues = {
  name: '',
  brands: [],
  experiment_identifier: '', // eslint-disable-line
  startDate: null,
  startTime: null,
  endDate: null,
  endTime: null,
  channels: [],
  mediums: [],
  discreteValues: [],
  manager: '',
  isCac: false
}

//inline styles - toggle icon to show and hide experiement details
const toogleIconDiv = {
  display: 'flex',
  alignItems: 'center',
  height: 115
}

const toggleIcon = { fontSize: '22px' }

//p tags
const outputPara = {
  margin: 0,
  border: '1px solid #d9d9d9',
  borderRadius: '4px',
  padding: '4px 11px',
  background: '#FFFFFF',
  lineHeight: '25px',
  minHeight: '35px'
}

//array listing divs
const ViewExperimentDetails = ({
  experiment,
  selectOptions,
  expDecodedId
}: Props): JSX.Element => {
  //local state
  const [experimentData, setExperimentData] = useState(initialFieldValues)
  const [showForm, setShowForm] = useState(true)
  const [updateError, setUpdateError] = useState()
  const [initialBrands, setInitialBrands] = useState()
  const [initialFeatures, setInitialFeatures] = useState()
  const [expName, setExpName] = useState('')
  //this flag will indicate the experiment data is ready
  const [initalDataReady, setInitialDataReady] = useState(false)

  //create the initial form data
  useEffect(() => {
    if (experiment) {
      const {
        experimentData,
        initialBrands,
        initialFeatures
      } = createExperimentFormData({ experiment: { ...experiment } })

      setExperimentData(experimentData)
      setInitialBrands(initialBrands)
      setInitialFeatures(initialFeatures)
      setExpName(experimentData.name)
    }
  }, [experiment, initalDataReady])

  // When the experiment data has updated successfully, change the inital data ready to true
  useEffect(() => {
    setInitialDataReady(true)
  }, [experimentData, showForm])

  //This function will show/hide form details excluding form name
  const showHideFormToggle = (): void => {
    setShowForm(prevState => !prevState)
  }

  //Query to update the experiment
  const [updateExperiment, { loading: updateLoading }] = useMutation(
    UPDATE_EXPERIMENT,
    {
      onCompleted() {
        setUpdateError(null)
      },
      onError(error) {
        setUpdateError(error)
      },
      refetchQueries: [
        {
          query: GET_EDIT_EXPERIMENT,
          variables: { id: expDecodedId }
        }
      ]
    }
  )

  //on update submit
  const handleSubmit = (values: ExperimentFormValues): void => {
    submitEditExperiment(
      values,
      initialBrands,
      initialFeatures,
      updateExperiment,
      expDecodedId
    )
  }

  return (
    <Fragment>
      {/* display error if received while updating experiment */}
      {updateError && (
        <ErrorAlert
          message={
            updateError.message
              ? updateError.message
              : 'Error updating experiment.'
          }
          close={true}
          onErrorClose={() => setUpdateError(undefined)}
        />
      )}

      {/* experiment details */}
      <AntForm>
        {/* experiment name */}
        <Row>
          <Col xs={{ span: 1 }} style={toogleIconDiv}>
            <Tooltip title={showForm ? 'Show less' : 'Show more'}>
              <Icon
                type={showForm ? 'minus-square' : 'plus-square'}
                style={toggleIcon}
                onClick={showHideFormToggle}
              />
            </Tooltip>
          </Col>

          <Col xs={{ span: 23 }}>
            <FormItem label="Name">
              <p style={outputPara}>{expName}</p>
            </FormItem>
          </Col>
        </Row>

        {/* toggle to expand or collapse form details */}
        {showForm && (
          <Formik
            enableReinitialize
            initialValues={experimentData}
            onSubmit={handleSubmit}
            // the form does field level validation
            validateOnBlur={false}
          >
            {({ submitCount, values, submitForm, setFieldValue }) => {
              if (values.name !== expName) setExpName(values.name)
              const isChanged = !_.isEqual(values, experimentData)
              return (
                <ErrorBoundary>
                  <ExperimentForm
                    nested
                    clickToEdit
                    isEdit
                    isChanged={isChanged}
                    totalImpressions={experiment.impressionsToDate}
                    totalCost={experiment.spendToDate}
                    owner={experiment.owner?.username}
                    submitCount={submitCount}
                    values={values}
                    submitForm={submitForm}
                    expData={selectOptions}
                    submitLoading={updateLoading}
                    setFieldValue={setFieldValue}
                    initalDataReady={initalDataReady}
                    setInitialDataReady={setInitialDataReady}
                    buttonName="SAVE CHANGES"
                  />
                </ErrorBoundary>
              )
            }}
          </Formik>
        )}
      </AntForm>
    </Fragment>
  )
}

export default ViewExperimentDetails
