//This component will create formik based input fields using ant d.

import React from 'react'
import { FieldProps } from 'formik'

//styled component
import { Form, Input } from 'antd'

//utilities
import { checkNumeric } from '../../../Utilities/CheckNumeric'

//Ant d component destructuring
const FormItem = Form.Item

//typescript for form fields
interface formFields {
  prefix: React.ReactNode
  label: string
  hasFeedback?: boolean
  type: string
  submitCount: number
}

//type class
type Class = { new (...args: any[]): any }

//JSX
export const InputNumber = (AntComponent: Class) => ({
  field,
  form,
  hasFeedback,
  label,
  type,
  submitCount,
  ...props
}: FieldProps<any> & formFields) => {
  const touched = form.touched[field.name]
  const submitted = submitCount > 0
  const hasError = form.errors[field.name]
  const submittedError = hasError && submitted
  const touchedError = hasError && touched

  const onChange = (value: any) =>
    form.setFieldValue(field.name, checkNumeric(value))
  const onBlur = () => form.setFieldTouched(field.name, true)
  return (
    <div className="field-container">
      <FormItem
        label={label}
        hasFeedback={
          (hasFeedback && submitted) || (hasFeedback && touched) ? true : false
        }
        help={submittedError || touchedError ? hasError : false}
        validateStatus={submittedError || touchedError ? 'error' : 'success'}
      >
        <AntComponent
          {...field}
          {...props}
          allowClear
          onBlur={onBlur}
          onChange={onChange}
        ></AntComponent>
      </FormItem>
    </div>
  )
}

export const AntInputNumber = InputNumber(Input)
