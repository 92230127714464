//container create experiment.
import React, { Fragment, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Formik } from 'formik'
import moment from 'moment'

import { useQuery, useMutation } from '@apollo/react-hooks'

//styled components
import { Alert, Breadcrumb } from 'antd'

//constants
import { Format } from '../../../Constants/dateTime'

//typescript types
import { ExperimentFormValues } from '../../../TypeScript_Types/ExperimentTypes'

//graphql
import { GET_EXPERIMENT_OPTIONS } from '../../../Graqhql/Experiment/QueryExperiment'
import { CREATE_EXPERIMENT } from '../../../Graqhql/Experiment/MutationExperiment'

//utilities
import ErrorBoundary from '../../../Utilities/ErrorBoundary'

//functional components
import LayoutWrap from '../../../HOC/LayoutWrap'
import PageTitle from '../../../Components/Reusable_UI/Headers_Titles/PageTitles'
import CreateForm from '../../../Components/Experiment/Create_Edit_ExperimentForm'
import ErrorAlert from '../../../Components/Reusable_UI/Alerts/ErrorAlerts'
import { LoadingSpinner } from '../../../Components/Reusable_UI/Loaders_Spinners/LoadingSpinner'

//const title of create experiment page
const PAGE_TITLE = 'Create Experiment'

//const form fields initial state
const initialFieldValues: ExperimentFormValues = {
  name: '',
  brands: [],
  experiment_identifier: '', // eslint-disable-line
  startDate: null,
  startTime: null,
  endDate: null,
  endTime: null,
  channels: [],
  mediums: [],
  discreteValues: [],
  manager: '',
  isCac: false
}

//Breadcrumb nav
const CreateExperimentBreadcrumb = (): JSX.Element => {
  return (
    <Breadcrumb style={{ marginBottom: '20px' }}>
      <Breadcrumb.Item>
        <Link to="features_list">Features</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        <Link to="brands_list">Brands</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        <Link to="experiments_list">Experiments</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>Create experiment</Breadcrumb.Item>
    </Breadcrumb>
  )
}

/// Create experiment
const CreateExperiement: React.FC<any> = (props: {
  history: any
  location: any
}) => {
  //local states
  const [expError, setExpError] = useState()
  const [createError, setCreateError] = useState()
  //this flag will indicate initial data load
  const [initalDataReady, setInitialDataReady] = useState(false)

  // get experiments options data from graphql
  const { data: expData, loading: expLoading } = useQuery(
    GET_EXPERIMENT_OPTIONS,
    {
      onError(error) {
        setExpError(error)
      }
    }
  )

  //const update initial data ready on fetching expData
  useEffect(() => {
    if (expData) {
      setInitialDataReady(true)
    }
  }, [expData])

  //mutation to create experiment
  const [createExperiment, { loading: createLoading }] = useMutation(
    CREATE_EXPERIMENT,
    {
      onCompleted({ createExperiment }) {
        props.history.push(`/${createExperiment.experiment.id}/view_experiment`)
      },
      onError(error) {
        setCreateError(error)
      }
    }
  )

  //submit create experiment request
  const handleSubmit = (values: ExperimentFormValues): void => {
    //convert dates to "YYYY-MM-DD" format and time to "HH:MM"
    let startDate
    let startTime
    let endDate
    let endTime
    let startDateTimeUTC = null
    let endDateTimeUTC = null

    //If start date is not null
    if (values.startDate) {
      startDate = moment(values.startDate).format(Format.convertUTCDateFormat)
      startTime = values.startTime
        ? moment(values.startTime).format(Format.timeFormat)
        : '00:00'
      //convert start date and time to UTC
      startDateTimeUTC = new Date(startDate + 'T' + startTime).toISOString()
    }

    //If end date is not null
    if (values.endDate) {
      endDate = moment(values.endDate).format(Format.convertUTCDateFormat)
      endTime = values.endTime
        ? moment(values.endTime).format(Format.timeFormat)
        : '00:00'
      //convert end date and time to UTC
      endDateTimeUTC = new Date(endDate + 'T' + endTime).toISOString()
    }

    {
      const input = {
        name: values.name,
        experiment_identifier: values.experiment_identifier, // eslint-disable-line
        channels: values.channels,
        brands: values.brands,
        mediums: values.mediums,
        startDate: startDateTimeUTC,
        endDate: endDateTimeUTC,
        manager: values.manager,
        isCac: values.isCac
      }
      const discreteValues = values.discreteValues
      //call create mutation
      createExperiment({
        variables: { input: input, discreteValues: discreteValues }
      })
    }
  }

  return (
    <LayoutWrap props={props}>
      {/* bread crumb navigation */}
      <CreateExperimentBreadcrumb />

      {/* title of the page */}
      <PageTitle titleName={PAGE_TITLE} />

      {/* reminder alert to create features & brands*/}
      <div
        style={{
          position: 'absolute',
          top: '6%',
          left: '50%',
          minWidth: '28%',
          transform: 'translate(-50%, -50%)',
          zIndex: 100
        }}
      >
        <Alert
          message="Create features & brands before creating experiment."
          type="info"
          closable
        />
      </div>

      {/* create experiment error */}
      {createError && (
        <ErrorAlert
          message={
            createError.message ? createError.message : 'Something went wrong'
          }
          close={true}
          onErrorClose={() => setCreateError(undefined)}
        />
      )}

      {/* loading experiment form data */}
      {expError ? (
        <ErrorAlert
          message={expError.message ? expError.message : 'Something went wrong'}
        />
      ) : (
        <Fragment>
          {// if the form options are load show spinner else render form.
          expLoading ? (
            <LoadingSpinner />
          ) : (
            <Formik
              initialValues={initialFieldValues}
              onSubmit={handleSubmit}
              // the form does field level validation on blur
              validateOnBlur={false}
            >
              {({ submitCount, values, submitForm, setFieldValue }) => (
                <ErrorBoundary>
                  <CreateForm
                    setInitialDataReady={setInitialDataReady}
                    initalDataReady={initalDataReady}
                    submitCount={submitCount}
                    values={values}
                    submitForm={submitForm}
                    expData={expData}
                    setFieldValue={setFieldValue}
                    submitLoading={createLoading}
                    buttonName="CREATE"
                  />
                </ErrorBoundary>
              )}
            </Formik>
          )}
        </Fragment>
      )}
    </LayoutWrap>
  )
}

export default CreateExperiement
