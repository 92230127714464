//container - View Experiment Form
//Includes experiment details, folders & permutations

import React, { useState, Fragment } from 'react'
import { Link, RouteComponentProps } from 'react-router-dom'

//ant d styled components
import { Switch, Typography, Divider, Tooltip, Breadcrumb } from 'antd'

import { useQuery } from '@apollo/react-hooks'

//graphql
import { GET_EDIT_EXPERIMENT } from '../../../Graqhql/Experiment/QueryExperiment'

//contexts
import CreateFolderModalProvider from '../../../Contexts/CreateFolderModalContext'
import EditFolderModalProvider from '../../../Contexts/EditFolderModalContext'
import CreateModalProvider from '../../../Contexts/CreateModalContext'
import EditModalProvider from '../../../Contexts/EditModalContext'
import DownloadModalProvider from '../../../Contexts/DownloadModalContext'

//utilities
import ErrorBoundary from '../../../Utilities/ErrorBoundary'

//functional components
import LayoutWrap from '../../../HOC/LayoutWrap'
import PageTitle from '../../../Components/Reusable_UI/Headers_Titles/PageTitles'
import ViewAndEditExperiment from '../../../Components/Experiment/ViewExperimentForm/ViewAndEditExperiment'
import FolderList from '../../Folders/FoldersList'
import { LoadingSpinner } from '../../../Components/Reusable_UI/Loaders_Spinners/LoadingSpinner'
import ErrorAlert from '../../../Components/Reusable_UI/Alerts/ErrorAlerts'
import PermutationList from '../../Permutations/PermutationsList'
import ExperimentAnalytics from '../../../Components/Experiment/ExperimentAnalytics'

//ant design componenent destructuring
const { Title } = Typography

//inline styles
const folderDiv = {
  display: 'flex',
  alignItems: 'center',
  marginTop: 20
}

//const title of view experiment page
const PAGE_TITLE = 'View Experiment'

type Params = {
  id: string
}

//JSX
const ViewExperimentComponents = (
  props: RouteComponentProps<Params>
): JSX.Element => {
  //local state
  const [folderView, setFolderView] = useState(false)
  const [expError, setExpError] = useState()

  //experiment id
  const experimentId = props.match.params.id
  const decodedExperimentId = window.atob(experimentId).split(':')[1]

  //get the experiment details
  const { data: expData, loading: expLoading } = useQuery(GET_EDIT_EXPERIMENT, {
    variables: { id: decodedExperimentId },
    onError(error) {
      setExpError(error)
    }
  })

  //breadcrumb navigation menu
  const ViewExperimentBreadcrumb = (
    <Breadcrumb style={{ marginBottom: '20px' }}>
      <Breadcrumb.Item>
        <Link to="/experiments_list">Experiments</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>View experiment</Breadcrumb.Item>
    </Breadcrumb>
  )

  return (
    <LayoutWrap props={props}>
      {/* breadcrumb navigation menu */}
      {ViewExperimentBreadcrumb}

      <PageTitle titleName={PAGE_TITLE} />

      {/* error getting experiment details */}
      {expError ? (
        <ErrorAlert
          message={expError.message ? expError.message : 'Something went wrong'}
        />
      ) : (
        <Fragment>
          {/* if loading or no data show the spinner else render other components */}
          {/* this is necessary because setError will take time for async to complete, meanwhile the
                        details component rendering starts if no expData */}

          {expLoading || !expData ? (
            <LoadingSpinner />
          ) : (
            <Fragment>
              {/* 1. experiment details */}
              <ErrorBoundary>
                <ViewAndEditExperiment
                  experiment={{ ...expData.experiment }}
                  selectOptions={{
                    brands: [...expData.brands],
                    features: [...expData.features],
                    channels: [...expData.channels],
                    dtxcloudUsers: [ ...expData.dtxcloudUsers]
                  }}
                  expDecodedId={decodedExperimentId}
                />
              </ErrorBoundary>

              {/* 2. Charts */}
              <ErrorBoundary>
                <ExperimentAnalytics experimentId={decodedExperimentId} />
              </ErrorBoundary>

              {/* 3. Groups */}
              <div style={folderDiv}>
                <Tooltip title={folderView ? 'Hide folders' : 'Show folders'}>
                  <Switch
                    onClick={checked => setFolderView(checked)}
                    checked={folderView}
                  />
                </Tooltip>
                <Title level={4} style={{ marginBottom: 0, marginLeft: 5 }}>
                  Folders
                </Title>
              </div>

              {/* if folder swtich true display the folder list*/}
              <ErrorBoundary>
                <CreateFolderModalProvider>
                  <EditFolderModalProvider>
                    {folderView && (
                      <FolderList experimentId={decodedExperimentId} />
                    )}
                  </EditFolderModalProvider>
                </CreateFolderModalProvider>
              </ErrorBoundary>

              <Divider />

              {/* 4. Permutation */}
              <ErrorBoundary>
                <CreateModalProvider>
                  <EditModalProvider>
                    <DownloadModalProvider>
                      <PermutationList
                        experimentId={decodedExperimentId}
                        history={props.history}
                      />
                    </DownloadModalProvider>
                  </EditModalProvider>
                </CreateModalProvider>
              </ErrorBoundary>
            </Fragment>
          )}
        </Fragment>
      )}
    </LayoutWrap>
  )
}

export default ViewExperimentComponents
