//Continer -Permutations list

import React, { Fragment, useState, useContext, useEffect } from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { FileExcelFilled } from '@ant-design/icons'
import { BlockPicker, ColorResult } from 'react-color'
import API from '../../Utilities/RESTSetup'

//ant d
import { Modal, Tooltip, Button, Menu, Dropdown, Tag, Row, Col } from 'antd'

//graphql
import { DELETE_PERMUTATION } from '../../Graqhql/Permutations/MutationPermutation'
import { GET_PERMUTATIONS_LIST } from '../../Graqhql/Permutations/QueryPermutation'

//context
import { CreateModalCTX } from '../../Contexts/CreateModalContext'
import { DownloadModalContext } from '../../Contexts/DownloadModalContext'

//typeScript
import { Permutation } from '../../TypeScript_Types/Permutations'
import { Folder } from '../../TypeScript_Types/Folders'

//utilities
import { DEFAULT_FLOWCODE_COLOR } from '../../Constants/Flowcodes/Flowcodes'

//functional components
import ViewPermutationsTable from '../../Components/Permutations/ViewPermutationTable'
import SuccessAlert from '../../Components/Reusable_UI/Alerts/SuccessAlerts'
import { SearchField } from '../../Components/Reusable_UI/Forms/SearchField'
import ModalWrap from '../../Components/Reusable_UI/Modal/ModalFormWrap'
import CreatePermutation from './CreatePermutation'
import PageTitle from '../../Components/Reusable_UI/Headers_Titles/PageTitles'
import ErrorAlert from '../../Components/Reusable_UI/Alerts/ErrorAlerts'
import DownloadCSV from '../Build/DownloadCSV'
import ErrorBoundary from '../../Utilities/ErrorBoundary'

//ant design destructuring
const { confirm } = Modal

//types
interface Props {
  experimentId: string
  history: any
}

//JSX
const PermutationList = ({ experimentId, history }: Props): JSX.Element => {
  //context
  const { createSuccess, viewCreateFormModal, handleVisible } = useContext(
    CreateModalCTX
  )
  const { viewDownloadFormModal } = useContext(DownloadModalContext)

  //const { editSuccess, viewEditFormModal } = useContext(EditModalCTX)

  //local states
  const [permutationData, setPermutationData] = useState()
  const [deletePermutationId, setDeletePermutationId] = useState()
  const [clonePermutationId, setClonePermutationId] = useState<string>('')
  const [deletePermutationError, setDeletePermutationError] = useState()
  const [selectedPermutationId, setSelectedPermutationId] = useState<string>('')
  const [selectedPermutationName, setSelectedPermutationName] = useState<
    string
  >('')
  const [permutationListError, setPermutationListError] = useState()
  const [downloadFlowcodesError, setDownloadFlowcodesError] = useState<string>()
  const [isDownloadFlowcodesLoading, setIsDownloadFlowcodesLoading] = useState(
    false
  )
  const [
    isDownloadPermutationsLoading,
    setIsDownloadPermutationsLoading
  ] = useState(false)
  //filter by folder
  const [filterMenuItems, setFilterMenuItems] = useState()
  const [filterMenuItemCount, setFilterMenuItemCount] = useState()
  const [filterFolderInfo, setFilterFolderInfo] = useState()
  const [searchField, setSearchField] = useState()
  const [
    isDownloadFlowcodesModalOpen,
    setIsDownloadFlowcodesModalOpen
  ] = useState(false)
  const [flowcodeColor, setFlowcodeColor] = useState(DEFAULT_FLOWCODE_COLOR)

  //get permutations data from graphql
  const { data: permutationsList, loading: listLoading } = useQuery(
    GET_PERMUTATIONS_LIST,
    {
      variables: { experimentId: experimentId },
      onError(error) {
        setPermutationListError(error)
      }
    }
  )

  const hasFlowcodes = permutationData?.some(
    (permutation: { flowcodeCount: number }) => permutation.flowcodeCount
  )
  const hasPermutations = !!permutationsList?.permutations?.length

  //update data
  useEffect(() => {
    if (permutationsList) {
      setPermutationData(permutationsList.permutations)

      //folder filter menu
      {
        //unique permutations by folderID
        const uniquePermutations: Array<{ id: string; name: string }> = []

        permutationsList.permutations.forEach((item: Permutation & Folder) => {
          if (item.folder) {
            const index = uniquePermutations.findIndex(
              pushedItem => pushedItem.id === item.folder.id
            )
            if (index <= -1) {
              uniquePermutations.push({
                id: item.folder.id,
                name: item.folder.name
              })
            }
          }
        })
        setFilterMenuItemCount(uniquePermutations.length)
        setFilterMenuItems(uniquePermutations)
      }
    }
  }, [permutationsList])

  //delete permutation mutation
  const [deletePermutation, { loading: deleteLoading }] = useMutation(
    DELETE_PERMUTATION,
    {
      onCompleted() {
        setDeletePermutationId(null)
      },
      onError(error) {
        setDeletePermutationError(error)
      },
      refetchQueries: [
        {
          query: GET_PERMUTATIONS_LIST,
          variables: { experimentId: experimentId }
        }
      ]
    }
  )

  const handleClonePermutation = (permutationId: string): void => {
    setClonePermutationId(permutationId)
    handleVisible()
  }

  const handleDownloadCSV = (
    permutationID: string,
    permutationName: string
  ): void => {
    setSelectedPermutationId(permutationID)
    setSelectedPermutationName(permutationName)
  }

  // delete permutation
  const handleDeletePermutation = (id: string, name: string): void => {
    setDeletePermutationError(null)
    setDeletePermutationId(id)

    confirm({
      title: 'Delete permutation',
      content: `Are you sure you want to delete ${name.substring(0, 60)}...?`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deletePermutation({ variables: { id: id } })
      }
    })
  }

  //on close delete error alert
  const onCloseDeleteError = (): void => {
    setDeletePermutationError(null)
    setDeletePermutationId(null)
  }

  //search permutation
  const handleSearchPermutation = (value: string): void => {
    if (!value) {
      setSearchField(null)
    } else {
      setSearchField(value)
    }
  }

  //filter permutation by folder id
  const handleFolderFilter = (item: { id: string; name: string }): void => {
    setFilterFolderInfo(item)
  }

  const clearFolderFilter = (): void => {
    setFilterFolderInfo(null)
  }

  //Download flowcodes
  const handleDownloadFlowcodes = async (): Promise<void> => {
    try {
      setIsDownloadFlowcodesLoading(true)
      const permutationIds: string[] = permutationData.map(
        ({ id }: { id: string }) => id
      )
      if (!permutationIds.length) {
        setDownloadFlowcodesError('Please select a permutation')
      }
      const url = '/v1/zip/permutations/all-flowcodes'
      const response = await API.get(url, {
        headers: {
          'Content-Type': 'application/json'
        },
        responseType: 'blob',
        params: {
          ids: permutationIds.join(','),
          defaultColor: flowcodeColor?.replace(/#/gi, '')
        }
      })
      const objectURL = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      let filename = response.request.getResponseHeader('Content-Disposition')
      filename =
        (filename && filename.split('filename=')[1]) || 'flowcode-archive.zip'
      link.setAttribute('download', filename)
      link.setAttribute('href', objectURL)
      document.body.appendChild(link)
      link.click()
      window.URL.revokeObjectURL(objectURL)
    } catch (e) {
      setDownloadFlowcodesError(
        'Error downloading flowcodes. Please try again.'
      )
    } finally {
      setIsDownloadFlowcodesLoading(false)
      setFlowcodeColor(DEFAULT_FLOWCODE_COLOR)
    }
  }

  //Download permutation
  const handleDownloadPermutations = async (): Promise<void> => {
    try {
      setIsDownloadPermutationsLoading(true)
      const permutationIds: string[] = permutationData.map(
        ({ id }: { id: string }) => id
      )
      if (!permutationIds.length) {
        return setDownloadFlowcodesError('Please select a permutation')
      }
      const path = '/v1/csv/permutations'
      const query = `ids=${permutationIds.join(',')}`
      const url = `${path}?${query}`
      const response = await API.get(url, {
        headers: {
          'Content-Type': 'application/json'
        },
        responseType: 'blob'
      })
      const objectURL = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      let filename = response.request.getResponseHeader('Content-Disposition')
      filename = (filename && filename.split('filename=')[1]).replace(/"/gi, '')
      link.setAttribute('download', filename)
      link.setAttribute('href', objectURL)
      document.body.appendChild(link)
      link.click()
      window.URL.revokeObjectURL(objectURL)
    } catch (e) {
      setDownloadFlowcodesError(
        'Error downloading permutations. Please try again.'
      )
    } finally {
      setIsDownloadPermutationsLoading(false)
      setFlowcodeColor(DEFAULT_FLOWCODE_COLOR)
    }
  }

  //filter list after updating search params
  useEffect(() => {
    if (permutationsList) {
      if (!searchField && !filterFolderInfo) {
        setPermutationData(permutationsList.permutations)
      } else {
        if (searchField && filterFolderInfo) {
          const filteredUniq = permutationsList.permutations.filter(
            (permutation: any) => {
              const name = permutation.name.toLowerCase()
              const searchFieldResult = name.indexOf(searchField) > -1
              const folderFilterResult = permutation.folder
                ? permutation.folder.id === filterFolderInfo.id
                : false
              return searchFieldResult && folderFilterResult
            }
          )

          setPermutationData(filteredUniq)
        } else {
          if (searchField) {
            const filteredSearch = permutationsList.permutations.filter(
              (permutation: any) => {
                const name = permutation.name.toLowerCase()
                return name.indexOf(searchField) > -1
              }
            )
            setPermutationData(filteredSearch)
          }

          if (filterFolderInfo) {
            const filteredFolder = permutationsList.permutations.filter(
              (permutation: any) => {
                // permutation.folder can be null for certain permutations
                if (permutation.folder) {
                  return permutation.folder.id === filterFolderInfo.id
                }
                return false
              }
            )
            if (filteredFolder) {
              setPermutationData(filteredFolder)
            }
          }
        }
      }
    }
  }, [searchField, filterFolderInfo, permutationsList])

  //render filter by folders in menu
  const filterMenu = (
    <Menu>
      {/* clear filter option only when filter is active */}
      {filterFolderInfo && (
        <Menu.Item onClick={clearFolderFilter}>Clear filter</Menu.Item>
      )}

      {filterMenuItems &&
        filterMenuItems.map(
          (item: { id: string; name: string }, index: number) => (
            <Menu.Item key={index} onClick={() => handleFolderFilter(item)}>
              {' '}
              {item.name}{' '}
            </Menu.Item>
          )
        )}
    </Menu>
  )

  return (
    <div style={{ position: 'relative' }}>
      {/* create or edit action outcomes */}
      {createSuccess ? (
        <SuccessAlert
          message={
            createSuccess
              ? 'Permutation created successfully.'
              : 'Permutation updated successfully.'
          }
          closable={true}
          title={createSuccess ? 'Create permutation' : 'Edit permutation'}
        />
      ) : null}

      {/* permutations */}
      {/* <div style={permutations}> */}
      <Row gutter={10}>
        <Col span={15}>
          <PageTitle titleName="Permutations" />
        </Col>

        {/* <div style={searchAddDiv}> */}
        <Col span={9}>
          {/* filter by folder only if the permutation has filters options */}
          {filterMenuItemCount > 0 && (
            <Col span={3}>
              <Tooltip title="Filter by folder" placement="topRight">
                <Dropdown overlay={filterMenu}>
                  <Button shape="circle" icon="filter" type="default" />
                </Dropdown>
              </Tooltip>
            </Col>
          )}

          {/* search field */}
          <Col span={12}>
            <SearchField
              handleSearch={handleSearchPermutation}
              placeholderVal="search permutation"
              disabled={!permutationData}
            />
          </Col>
          {/* download permuations excel */}
          <Tooltip
            title="Download permutations excel file"
            placement="topRight"
          >
            <Col span={3}>
              <Button
                shape="circle"
                type="primary"
                className="download-permutations-btn"
                onClick={handleDownloadPermutations}
                loading={isDownloadPermutationsLoading}
                disabled={!hasPermutations}
              >
                {!isDownloadPermutationsLoading && <FileExcelFilled />}
              </Button>
            </Col>
          </Tooltip>

          {/* download flowcodes */}
          <Tooltip title="Download flowcodes" placement="topRight">
            <Col span={3}>
              <Button
                shape="circle"
                icon="download"
                type="primary"
                className="download-flowcode-btn"
                onClick={() => setIsDownloadFlowcodesModalOpen(true)}
                loading={isDownloadFlowcodesLoading}
                disabled={!hasFlowcodes}
              />
            </Col>
          </Tooltip>
          {/* create permutation button */}
          <Tooltip title="Create permutations" placement="topRight">
            <Col span={3}>
              <Button
                shape="circle"
                icon="plus"
                type="primary"
                onClick={handleVisible}
              />
            </Col>
          </Tooltip>
        </Col>
      </Row>
      {/* </div> */}

      {/* filtered tags */}
      <div>
        {filterFolderInfo && (
          <Tag onClose={clearFolderFilter} closable color="blue">
            {`Filter: ${filterFolderInfo.name}`}
          </Tag>
        )}
      </div>

      {/* create permutation form */}
      {viewCreateFormModal && (
        <CreatePermutation
          experimentId={experimentId}
          clonePermutationId={clonePermutationId}
          setClonePermutationId={setClonePermutationId}
        />
      )}

      {/* TODO: Extract to FlowcodeOptionsModal options modal (flowcode studio v0.0.1) */}
      {isDownloadFlowcodesModalOpen && (
        <ModalWrap
          title="Download Flowcodes"
          buttons={[
            {
              buttonName: 'Download',
              key: 'download'
            }
          ]}
          loading={isDownloadFlowcodesLoading}
          viewFormModal
          handleVisible={() => {
            setIsDownloadFlowcodesModalOpen(false)
            setFlowcodeColor(DEFAULT_FLOWCODE_COLOR)
          }}
          submitForm={async () => {
            await handleDownloadFlowcodes()
            setIsDownloadFlowcodesModalOpen(false)
          }}
        >
          <strong>Color:</strong>
          <div className="color-picker-container">
            <BlockPicker
              color={flowcodeColor}
              triangle="hide"
              onChangeComplete={(color: ColorResult) =>
                setFlowcodeColor(color.hex)
              }
            />
          </div>
        </ModalWrap>
      )}

      {/* if there is an error downloading flowcodes */}
      {!!downloadFlowcodesError && (
        <ErrorAlert
          message={downloadFlowcodesError}
          onErrorClose={() => setDownloadFlowcodesError('')}
          close={true}
        />
      )}

      {/* display error received while fetching the permutation table data */}
      {permutationListError ? (
        <ErrorAlert
          message={
            permutationListError.message
              ? permutationListError.message
              : 'Error fetching permutations.'
          }
        />
      ) : (
        <Fragment>
          {/* delete permutation error  alert*/}
          {deletePermutationError && (
            <ErrorAlert
              message={
                deletePermutationError.message
                  ? deletePermutationError.message
                  : 'Error deleting permutation.'
              }
              onErrorClose={onCloseDeleteError}
              close={true}
            />
          )}

          {/* Download csv */}
          {viewDownloadFormModal && (
            <ErrorBoundary>
              <DownloadCSV
                permutationId={selectedPermutationId}
                permutationName={selectedPermutationName}
              />
            </ErrorBoundary>
          )}

          {/* created permutations table listing */}
          <ViewPermutationsTable
            permutationData={permutationData}
            experimentId={experimentId}
            listLoading={listLoading}
            deleteLoading={deleteLoading}
            deletePermutationId={deletePermutationId}
            history={history}
            handleClonePermutation={handleClonePermutation}
            handleDeletePermutation={handleDeletePermutation}
            handleDownloadCSV={handleDownloadCSV}
          />
        </Fragment>
      )}
    </div>
  )
}

export default PermutationList
