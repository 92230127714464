//Container - register form page

import React, { Fragment, useState } from 'react'
import { Formik } from 'formik'
import { useMutation, useApolloClient } from '@apollo/react-hooks'

//styled components
import { Row, Col } from 'antd'

//graphql
import { REGISTER_USER } from '../../Graqhql/Register'

//utilities
import ErrorBoundary from '../../Utilities/ErrorBoundary'

//functional components
import LayoutWrap from '../../HOC/LayoutWrap'
import RegisterForm from '../../Components/Register/RegisteForm'
import PageTitle from '../../Components/Reusable_UI/Headers_Titles/PageTitles'
import ErrorAlert from '../../Components/Reusable_UI/Alerts/ErrorAlerts'

//const title of login page
const PAGE_TITLE = 'Sign Up'

//const form fields initial state
const initialFieldValues = {
  username: '',
  email: '',
  password: ''
}

//types for form fields state
interface FormValues {
  username: string
  password: string
  email: string
}

const Register: React.FC<any> = props => {
  const client = useApolloClient()

  //local state
  const [registerError, setRegisterError] = useState()

  //use mutation hook - extract mutate function, token and save it to the local storage when the request is completed.
  const [createUser, { loading }] = useMutation(REGISTER_USER, {
    onCompleted({ createUser }) {
      localStorage.setItem('token', createUser.token)
      client.writeData({
        data: { isAuthenticated: !!createUser.token, __typename: 'Auth' }
      })
    },
    onError(error) {
      setRegisterError(error)
    }
  })

  //handle submit function
  const handleSubmit = (values: FormValues) => {
    createUser({
      variables: {
        username: values.username,
        email: values.email,
        password: values.password
      }
    })
  }

  return (
    <LayoutWrap props={props}>
      <Row type="flex" justify="center">
        <Col span={6}>
          <Formik
            initialValues={initialFieldValues}
            onSubmit={handleSubmit}
            validateOnBlur={false}
          >
            {({ submitCount, values }) => (
              <Fragment>
                {/* title of the page */}
                <PageTitle titleName={PAGE_TITLE} />

                {/* on error during mutation display error message */}
                {registerError && (
                  <ErrorAlert
                    message={
                      registerError.message
                        ? registerError.message
                        : 'Something went wrong'
                    }
                  />
                )}

                {/* register form */}
                <ErrorBoundary>
                  <RegisterForm
                    loading={loading}
                    submitCount={submitCount}
                    values={values}
                  />
                </ErrorBoundary>
              </Fragment>
            )}
          </Formik>
        </Col>
      </Row>
    </LayoutWrap>
  )
}

export default Register
