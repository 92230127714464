//This is the parent container for showing the list of experiements in /experiments_list.
//Async loading implementation

import React, { useState, Fragment, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/react-hooks'

//styled component
import { Button, Tooltip, Modal } from 'antd'

//graphql query
import { GET_EXPERIMENTS } from '../../Graqhql/Experiment/QueryExperiment'
import { DELETE_EXPERIMENT } from '../../Graqhql/Experiment/MutationExperiment'

//functional components
import PageTitle from '../../Components/Reusable_UI/Headers_Titles/PageTitles'
import LayoutWrap from '../../HOC/LayoutWrap'
import ExperimenTable from '../../Components/ExperimentsList/ExperimentListTable'
import { SearchField } from '../../Components/Reusable_UI/Forms/SearchField'
import ErrorAlert from '../../Components/Reusable_UI/Alerts/ErrorAlerts'

//ant design destructuring
const { confirm } = Modal

//inline styles
const titleDiv = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
}

const searchAddDiv = {
  display: 'flex',
  justifyContent: 'space-between',
  flexBasis: '21%'
}

//const title of login page
const PAGE_TITLE = 'Experiment List'

//JSX
const ExperiementList: React.FC<any> = props => {
  //localStates
  const [fetchError, setFetchError] = useState(false)
  const [fetchErrorMsg, setFetchErrorMsg] = useState('')
  const [deleteId, setDeleteId] = useState()
  const [deleteError, setDeleteError] = useState()
  const [searchVal, setSearchVal] = useState('')

  const queryConfig = {
    onError(error: any) {
      setFetchError(true)
      setFetchErrorMsg(error.message)
    },
    variables: {}
  }

  if (searchVal) queryConfig.variables = { searchParam: searchVal }

  //get experiments data from graphql
  const { data, loading, fetchMore } = useQuery(GET_EXPERIMENTS, queryConfig)

  //local state
  const [experimentsData, setExperimentsData] = useState([])

  //update the local state, this will be used in search function.

  useEffect(() => {
    if (data) {
      setExperimentsData(data.experiments?.edges)
    }
  }, [data])

  const handleSearch = (value: string) => setSearchVal(value)

  //this will be used in updated and fetch more query functions, infinite scroll
  const experiment = data ? data.experiments : null

  //delete experiment mutation
  const [deleteExperiment, { loading: deleteLoading }] = useMutation(
    DELETE_EXPERIMENT,
    {
      onCompleted() {
        setDeleteId(null)
      },
      onError(error) {
        setDeleteError(error)
      },
      refetchQueries: [{ query: GET_EXPERIMENTS }]
    }
  )

  const handleDeleteExperiment = (id: string, name: string) => {
    setDeleteError(null)
    setDeleteId(id)

    {
      let decodedId = window.atob(id).split(':')[1]
      confirm({
        title: 'Delete experiment',
        content: `Are you sure you want to delete ${name.substring(
          0,
          60
        )}... ?`,
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk() {
          deleteExperiment({ variables: { id: decodedId } })
        }
      })
    }
  }

  // close delete error popup
  const onCloseDeleteError = () => {
    setDeleteError(null)
    setDeleteId(null)
  }

  return (
    <LayoutWrap props={props}>
      {/* title and search bar div */}
      <div style={titleDiv}>
        <PageTitle titleName={PAGE_TITLE} />

        <div style={searchAddDiv}>
          <SearchField
            handleSearch={handleSearch}
            placeholderVal="search experiment"
            disabled={!experimentsData}
          />

          {/* create experiment button */}
          <Tooltip title="Create new experiment" placement="topRight">
            <Link to="/create_experiment">
              <Button shape="circle" icon="plus" type="primary" />
            </Link>
          </Tooltip>
        </div>
      </div>

      {/* if error display error message alert else render table */}
      {fetchError ? (
        <ErrorAlert
          message={fetchErrorMsg ? fetchErrorMsg : 'Something went wrong.'}
        />
      ) : (
        <Fragment>
          {/* display error if delete action failed */}
          {deleteError && (
            <ErrorAlert
              message={
                deleteError.message
                  ? deleteError.message
                  : 'Something went wrong.'
              }
              close={true}
              onErrorClose={onCloseDeleteError}
            />
          )}

          {/* experiment table */}
          <ExperimenTable
            history={props.history}
            experimentsData={experimentsData}
            loading={loading}
            deleteLoading={deleteLoading}
            deleteId={deleteId}
            handleDeleteExperiment={handleDeleteExperiment}
            hasNextPage={experiment && experiment.pageInfo.hasNextPage}
            onLoadMore={() =>
              fetchMore({
                variables: {
                  after: experiment && experiment.pageInfo.endCursor
                },

                updateQuery: (previousResult, { fetchMoreResult }: any) => {
                  const newEdges = fetchMoreResult.experiments.edges
                  const pageInfo = fetchMoreResult.experiments.pageInfo
                  return newEdges.length
                    ? {
                        // Put the new experiments data at the end of the list and update `pageInfo`
                        // so that we have a new `endCursor` and `hasNextPage` values
                        experiments: {
                          __typename: previousResult.experiments.__typename,
                          edges: [
                            ...previousResult.experiments.edges,
                            ...newEdges
                          ],
                          pageInfo
                        }
                      }
                    : previousResult
                }
              }).catch(error => {
                // fetch more error handling
                setFetchErrorMsg(error.message)
                setFetchError(true)
              })
            }
          />
        </Fragment>
      )}
    </LayoutWrap>
  )
}

export default ExperiementList
