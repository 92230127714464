//Query - Experiments

import gql from 'graphql-tag'

//get the list of all experiments
export const GET_EXPERIMENTS = gql`
 query getExperiments($after: String, $searchParam: String) {
	experiments(first: ${Math.ceil(
    (window.innerHeight - 200) / 54
  )}, after: $after, name_Icontains: $searchParam){
		pageInfo  {
			startCursor
			endCursor
			hasNextPage
			hasPreviousPage
		}
		edges {
			cursor
			node {
				id
				name
        timeStamp
        numberFolders
        numberPermutations
			}
		}
	}
}
`

//get the choices and options for create/edit experiment forms
export const GET_EXPERIMENT_OPTIONS = gql`
  query {
    #  channels
    channels {
      name
      mediums
    }

    # features
    features {
      id
      name
      archived
      discreteValues {
        id
        value
      }
    }

    # brands
    brands {
      id
      name
    }

    # users - manager
    dtxcloudUsers {
      id
      username
    }
  }
`

//edit experiment
//get the choices and options for create/edit experiment forms
export const GET_EDIT_EXPERIMENT = gql`
  query($id: UUID) {
    #  experiment
    experiment(id: $id) {
      id
      name
      manager {
        id
      }
      isCac
      owner {
        username
      }
      experimentBrandSet {
        id
        brand {
          id
        }
      }
      experimentIdentifier
      channels
      mediums
      startDate
      endDate
      spendToDate
      impressionsToDate
      experimentDiscreteSet {
        id
        discreteValue {
          id
          value
          feature {
            id
            name
          }
        }
      }
    }
    # channels
    channels {
      name
      mediums
    }

    # features
    features {
      id
      name
      archived
      discreteValues {
        id
        value
      }
    }
    # brands
    brands {
      id
      name
    }

    # users - manager
    dtxcloudUsers {
      id
      username
    }
  }
`

//view experiment
export const VIEW_EXPERIMENT = gql`
  query($id: UUID) {
    experiment(id: $id) {
      id
      name
      manager {
        id
      }
      isCac
      brands {
        id
        name
      }
      experimentIdentifier
      channels
      mediums
      startDate
      endDate
      spendToDate
      impressionsToDate
      discreteValues {
        id
        value
        feature {
          id
          name
        }
      }
    }
  }
`

export const GET_EXPERIMENT_ANALYTICS = gql`
  query experiment($id: UUID) {
    analytics(id: $id) {
      id
      scanCount
      costPerScan
      permutationName
    }
  }
`

// export const SEARCH_EXPERIMENTS = gql`
//   query searchExperiments($searchParam: String) {
//     experiments(name_Icontains: $searchParam){
//   		edges {
//   			node {
//   				id
//   				name
//           timeStamp
//           numberFolders
//           numberPermutations
//   			}
//   		}
//   	}
//   }
// `;
