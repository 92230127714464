import React from 'react'

//App routes
import AppRoutes from './Routes/AppRoutes'
import ErrorBoundary from './Utilities/ErrorBoundary'

const App: React.FC = () => {
  return (
    <ErrorBoundary>
    <AppRoutes />
  </ErrorBoundary>
  )
}

export default App
