//non cost numeric check - no decimal point
export const checkNumeric = (e: any) => {
  const { value } = e.target

  if (!value) {
    return null
  } else {
    const validChars = /\$?\d[\d,]*(\.\d+)?$/
    let inputVal
    if (!validChars.test(value)) {
      inputVal = value.replace(/[^0-9]/g, '')
      return inputVal
    } else {
      inputVal = value.replace(/[,a-zA-Z]/g, '')
      return Number(inputVal)
    }
  }
}

export const checkNumericVal = (value: string) => {
  if (!value) {
    return null
  } else {
    const validChars = /\$?\d[\d,]*(\.\d+)?$/
    let inputVal
    if (!validChars.test(value)) {
      inputVal = value.replace(/[^0-9]/g, '')
      return inputVal
    } else {
      inputVal = value.replace(/[,a-zA-Z]/g, '')
      return Number(inputVal)
    }
  }
}

//cost numeric check - decimal point
export const checkDecimalNumeric = (value: string) => {
  //regex to test numbers with 2 decimals places
  const validCost = /^[0-9]+(\.[0-9]{0,2})?$/

  if (!value) {
    return value
  } else {
    if (validCost.test(value)) {
      //format input to add commas
      let inputVal = value.replace(/\d{1,3}(?=(\d{3})+(?!\d))/g, '$&,')
      return inputVal
    }
    return null
  }
}
