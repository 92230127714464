//This component will catch the errors from the children component and
//prevent the app from crashing by displaying a
import React, { Component } from 'react'

//ant design components
import { Alert } from 'antd'

type ErrorState = {
  hasError: boolean
}

//hooks does not support getDerivedStateFromError yet.
class ErrorBoundary extends Component<{}, ErrorState> {
  state = { hasError: false }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Alert
          message="Error - Something went wrong"
          type="error"
          showIcon
        />
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
