//container to create feature.
import React, { Fragment, useEffect, useContext, useRef, useState } from 'react'
import { Formik } from 'formik'
import { useMutation, useQuery } from '@apollo/react-hooks'

//graphql
import { CREATE_FEATURE } from '../../Graqhql/Features/FeatureMutation'
import {
  GET_FEATURES,
  GET_CHANNEL_CHOICES
} from '../../Graqhql/Features/FeatureQuery'

//constants
import { ValueType } from '../../Constants/Features/FeatureValueType'

//contexts
import { CreateModalCTX } from '../../Contexts/CreateModalContext'

//typescript types
import { FeaturesFormValues } from '../../TypeScript_Types/Features'

//utilities
import ErrorBoundary from '../../Utilities/ErrorBoundary'

//functional components
import CreateFeatureForm from '../../Components/Feature/Create_FeatureForm'
import ModalWrap from '../../Components/Reusable_UI/Modal/ModalFormWrap'
import ErrorAlert from '../../Components/Reusable_UI/Alerts/ErrorAlerts'
import { LoadingSpinner } from '../../Components/Reusable_UI/Loaders_Spinners/LoadingSpinner'

//const form fields initial state
const initialFieldValues: FeaturesFormValues = {
  name: '',
  category: '',
  type: '',
  featureName: '',
  discreteValues: [],
  channel: []
}

//const modal buttons
const buttons = [
  {
    buttonName: 'Create',
    key: 'create'
  }
]

//JSX
const CreateFeature: React.FC<any> = () => {
  //local state
  const [fetchChannelsError, setFetchChannelsError] = useState()
  const [channelChoices, setChannelChoices] = useState([])

  //context
  const {
    viewCreateFormModal,
    handleVisible,
    setCreateSuccess,
    createError,
    setCreateError,
    createErrorMsg,
    setCreateErrorMsg
  } = useContext(CreateModalCTX)

  //ref to call reset form
  const resetRef: any = useRef(null)

  //useEffect - reset form if the modal state changes from true to false.
  //Do not move the reset ref call inside handleVisible, it will change the transform origin of the modal.
  useEffect(() => {
    //reset modal
    if (viewCreateFormModal) {
      resetRef.current && resetRef.current.click()
    }
  }, [viewCreateFormModal])

  //graphql mutation - call create feature mutation and then refresh the list
  const [createFeature, { loading }] = useMutation(CREATE_FEATURE, {
    onCompleted() {
      setCreateSuccess(true)
      // reset other states and close the modal
      handleVisible()
    },
    onError(error) {
      setCreateError(true)
      setCreateErrorMsg(error.message)
    },
    refetchQueries: [{ query: GET_FEATURES }]
  })

  //get channels
  const { data: channelChoicesData, loading: channelChoicesLoading } = useQuery(
    GET_CHANNEL_CHOICES,
    {
      onError(error) {
        setFetchChannelsError(error)
      }
    }
  )

  //get channels options
  useEffect(() => {
    if (channelChoicesData) {
      let channels = channelChoicesData.channels.map(
        (channel: any) => channel.name
      )
      setChannelChoices(channels)
    }
  }, [channelChoicesData])

  //submit form to create feature
  const handleSubmit = (values: FeaturesFormValues) => {
    const input = {
      name: values.name,
      category: values.category,
      channel: values.channel,
      type: ValueType[values.type]
    }

    const discreteValues = values.discreteValues
    createFeature({ variables: { input, discreteValues } })
  }

  return (
    <Fragment>
      <Formik
        initialValues={initialFieldValues}
        onSubmit={handleSubmit}
        validateOnBlur={false}
      >
        {({ submitCount, values, submitForm, handleReset, setFieldValue }) => (
          <ModalWrap
            title="Create Feature"
            buttons={buttons}
            submitForm={submitForm}
            loading={loading || channelChoicesLoading}
            handleVisible={handleVisible}
            viewFormModal={viewCreateFormModal}
          >
            {/* error alert, success alert is handled in parent container */}
            {createError && (
              <ErrorAlert
                message={
                  createErrorMsg ? createErrorMsg : 'Something went wrong'
                }
                close={true}
                onErrorClose={() => {
                  setCreateErrorMsg('')
                  setCreateError(false)
                }}
              />
            )}

            {/* show spinner or data if dropdown options cannot be fetched */}
            {fetchChannelsError ? (
              <ErrorAlert
                message={
                  fetchChannelsError.message
                    ? fetchChannelsError.message
                    : 'Something went wrong'
                }
              />
            ) : (
              <>
                {channelChoicesLoading ? (
                  <LoadingSpinner />
                ) : (
                  <ErrorBoundary>
                    <CreateFeatureForm
                      setFieldValue={setFieldValue}
                      submitCount={submitCount}
                      values={values}
                      channelChoices={channelChoices}
                    />
                  </ErrorBoundary>
                )}
              </>
            )}

            {/* reset form div ref */}
            <div onClick={handleReset} ref={resetRef} />
          </ModalWrap>
        )}
      </Formik>
    </Fragment>
  )
}

export default CreateFeature
