// queries - permutation

//Note - do not remove "id" from queries even if not used by any program modules. It is used by
//apollo client to cache results

import gql from 'graphql-tag'

export const GET_PERMUTATION_EXPERIMENT = gql`
  query($permutationId: UUID) {
    permutation(id: $permutationId) {
      id
      experiment {
        id
        name
      }
    }
  }
`

//get the choices and options for create/edit permutation forms
export const GET_PERMUTATIONS_OPTIONS = gql`
  query($id: UUID) {
    # experiment
    experiment(id: $id) {
      id
      name
      experimentIdentifier
      channels
      mediums
      brands {
        id
        name
      }
      experimentBrandSet {
        id
        brand {
          id
          name
        }
      }
      experimentDiscreteSet {
        id
        discreteValue {
          id
          value
          feature {
            id
            name
          }
        }
      }
    }
    # folders by expt id
    folders(experimentId: $id) {
      id
      name
    }

    # all features
    features {
      id
      name
      archived
      discreteValues {
        id
        value
      }
    }
  }
`

//get permutation list

export const GET_PERMUTATIONS_LIST = gql`
  query($experimentId: UUID) {
    permutations(experimentId: $experimentId) {
      id
      name
      variables {
        id
        discreteValue {
          id
          value
        }
      }
      folder {
        id
        name
      }
      flowcodeCount
      timeStamp
    }
  }
`

//get permutation details for edit
export const GET_EDIT_PERMUTATION = gql`
  query($experimentId: UUID, $permutationID: UUID) {
    # choices from experiment
    experiment(id: $experimentId) {
      id
      name
      channels
      mediums
      experimentIdentifier
      brands {
        id
        name
      }
      experimentBrandSet {
        id
        brand {
          id
          name
        }
      }
      # variables - features selection dropdown
      experimentDiscreteSet {
        id
        discreteValue {
          id
          value
          feature {
            id
            name
          }
        }
      }
    }

    # folders
    folders(experimentId: $experimentId) {
      id
      name
    }

    # features for attributes
    features {
      id
      name
      archived
      discreteValues {
        id
        value
      }
    }

    # permutation
    permutation(id: $permutationID) {
      id
      name
      finalNumber
      brand {
        id
        brand {
          id
          name
        }
      }
      iscs
      
      # existing variables in the permutation
      variables {
        id
        discreteValue {
          id
          value
          feature {
            id
            name
          }
        }
      }

      # existing variables in the permutation for delete
      permutationVariableSet {
        id
        experimentDiscrete {
          id
        }
      }

      # existing attributes in the permutation
      attributes {
        id
        value
        feature {
          id
          name
        }
      }

      # existing in the permutation for delete
      permutationAttributeSet {
        id
        discreteValue {
          id
        }
      }
      channel
      medium
      estCost
      actualCost
      estImpressions
      actualImpressions
      folder {
        id
      }
      notes
    }
  }
`

//get permutation details for view
//do not remove the id's, its required for caching
export const VIEW_PERMUTATION = gql`
  query($id: UUID) {
    # permutation
    permutation(id: $id) {
      id
      name
      finalNumber
      brand {
        id
        brand {
          id
          name
        }
      }
      iscs
      channel
      medium
      estCost
      actualCost
      estImpressions
      actualImpressions
      folder {
        id
        name
      }
      notes
      variables {
        id
        # archived
        discreteValue {
          id
          value
          feature {
            id
            name
          }
        }
      }
      attributes {
        id
        value
        # archived
        feature {
          id
          name
        }
      }
      experiment {
        id
        name
      }
    }
  }
`
