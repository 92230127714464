//This is a common form for create feature
//The form "Field" component is from the resuable UI components - refer /Reusable_UI

import React, { Fragment } from 'react'

//form handler
import { Form, Field } from 'formik'

//styled components
import { Row, Col, Form as AntForm, Input, Button, Tooltip } from 'antd'

//constant
import { typeChoices } from '../../Constants/Features/FeatureValueType'

//functional components
import { AntInput, AntSelect } from '../Reusable_UI/Forms/FormFields'

// utilities
import {
  validateRequired,
  validateRequiredArray
} from '../../Utilities/Validate'
import { checkNumericVal } from '../../Utilities/CheckNumeric'

import { featureCategories } from '../../Constants/Features/FeaturesList'

//typeScript
import { FeatureFormsProps } from '../../TypeScript_Types/Features'

//array destructuring
const FormItem = AntForm.Item

//JSX - React FC
const EditFeatureForm = ({
  submitCount,
  values,
  setFieldValue,
  channelChoices
}: FeatureFormsProps) => {
  //save the inputs on hitting enter
  const handleValuesEnter = () => {
    //featureNames - object with discreteValue
    if (values.featureName) {
      const value = [{ value: values.featureName }, ...values.discreteValues]
      setFieldValue('discreteValues', value)
      //clear input
      setFieldValue('featureName', '')
    }
  }

  //remove the feature name
  const handleDeleteValue = (removeFeature: { id: string; value: string }) => {
    const deleteValue = values.discreteValues.filter(
      (item: any) => item.id !== removeFeature.id
    )
    setFieldValue('discreteValues', deleteValue)
  }

  //handle value update - update feature name without deleting it
  const handleValueUpdate = (
    updateValue: string,
    item: { id: string; value: string }
  ) => {
    let tempFeatureNames: any = [...values.discreteValues]

    for (var index in tempFeatureNames) {
      if (tempFeatureNames[index].id === item.id) {
        if (values.type === 'Numerical') {
          tempFeatureNames[index].value = checkNumericVal(updateValue)
        } else {
          tempFeatureNames[index].value = updateValue
        }
        break
      }
    }
    setFieldValue('discreteValues', tempFeatureNames)
  }

  return (
    <Form>
      <Row gutter={[10, { xs: 8, sm: 10, md: 24, lg: 32 }]}>
        <Col xs={{ span: 12 }}>
          {/* Name */}
          <Field
            label="Name"
            name="name"
            type="text"
            allowClear
            placeholder="Feature name"
            validate={validateRequired}
            submitCount={submitCount}
            component={AntInput}
          />
        </Col>

        <Col xs={{ span: 12 }}>
          {/* category */}
          <Field
            label="Category"
            name="category"
            allowClear
            placeholder="Select category"
            submitCount={submitCount}
            selectOptions={featureCategories}
            component={AntSelect}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          {/* Channel */}
          <Field
            label="Channel"
            name="channel"
            placeholder="Select channel"
            mode="multiple"
            allowClear
            submitCount={submitCount}
            selectOptions={channelChoices}
            component={AntSelect}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          {/* values */}
          <Field
            label="Values type"
            name="type"
            allowClear
            placeholder="Select type"
            // disabled if there are values - to keep the values consistent.
            disabled={values.discreteValues.length > 0}
            submitCount={submitCount}
            validate={validateRequired}
            selectOptions={typeChoices}
            component={AntSelect}
          />
        </Col>
      </Row>

      {/* values for the selected type - note creating features without values will create impact across feature selection
        Tree Select forms */}
      {values.type && (
        <Fragment>
          <Row
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Col span={20}>
              <Fragment>
                <Field
                  name="featureName"
                  validate={() => validateRequiredArray(values.discreteValues)}
                >
                  {({ field, form }: any) => (
                    <FormItem
                      label="Values"
                      // field validations help & feedbacks
                      help={
                        (values.discreteValues.length === 0 &&
                          submitCount > 0) ||
                        (form.touched.featureName &&
                          values.discreteValues.length === 0)
                          ? 'Values not entered for the value type'
                          : false
                      }
                      validateStatus={
                        (values.discreteValues.length === 0 &&
                          submitCount > 0) ||
                        (form.touched.featureName &&
                          values.discreteValues.length === 0)
                          ? 'error'
                          : 'success'
                      }
                    >
                      <Input
                        {...field}
                        allowClear
                        value={values.featureName}
                        placeholder="Enter value and hit return"
                        onChange={({ target: { value } }: any) => {
                          values.type === 'Numerical'
                            ? form.setFieldValue(
                                'featureName',
                                checkNumericVal(value)
                              )
                            : form.setFieldValue('featureName', value)
                        }}
                        onPressEnter={handleValuesEnter}
                        onBlur={() => form.setFieldTouched(field.name, true)}
                      />
                    </FormItem>
                  )}
                </Field>
              </Fragment>
            </Col>
            <Col>
              {/* Add button to add the input to the featuresNames */}
              <Button
                style={{ marginTop: '10px' }}
                disabled={!values.featureName}
                onClick={handleValuesEnter}
              >
                Add
              </Button>
            </Col>
          </Row>

          <div style={{ padding: '0px 6px' }}>
            {/* feature names listing  */}
            {values.discreteValues.length > 0
              ? values.discreteValues.map((item: any, index: number) => (
                  <Fragment key={index}>
                    <Row
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '10px'
                      }}
                    >
                      <Col span={21} style={{ marginRight: '5px' }}>
                        <Fragment>
                          <Input
                            value={item.value}
                            placeholder="Enter value and hit return"
                            onChange={({ target: { value } }: any) =>
                              handleValueUpdate(value, item)
                            }
                          />
                        </Fragment>
                      </Col>
                      <Col>
                        {/* Remove button to add the remove to the value  */}
                        <Tooltip title="Remove">
                          <Button
                            onClick={() => handleDeleteValue(item)}
                            icon="minus"
                          />
                        </Tooltip>
                      </Col>
                    </Row>
                  </Fragment>
                ))
              : null}
          </div>
        </Fragment>
      )}
    </Form>
  )
}

export default EditFeatureForm
