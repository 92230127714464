//Mutation - Features

import gql from 'graphql-tag'

//create feature
export const CREATE_FEATURE = gql`
  mutation($input: FeatureInputType!, $discreteValues: [DiscreteInputType]) {
    createFeature(input: $input, discreteValues: $discreteValues) {
      ok
    }
  }
`

//update feature
export const UPDATE_FEATURE = gql`
  mutation(
    $id: UUID!
    $deleteValues: [UUID]
    $addValues: [DiscreteInputType]
    $input: FeatureInputType
    $updateValues: [DiscreteInputType]
  ) {
    updateFeature(
      id: $id
      deleteValues: $deleteValues
      addValues: $addValues
      updateValues: $updateValues
      input: $input
    ) {
      ok
    }
  }
`

//delete feature - archive or unarchive
//NOTE - this is not deleteFeature mutation
export const DELETE_FEATURE = gql`
  mutation($id: UUID!, $input: FeatureInputType) {
    updateFeature(id: $id, input: $input) {
      ok
    }
  }
`
