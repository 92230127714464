//This component is to take the input for the csv file name
import React from 'react'

//form handler
import { Form, Field } from 'formik'

//styled components
import { Row, Col } from 'antd'

//utitlities
import { validateRequired } from '../../Utilities/Validate'

//functional components
import { AntInput } from '../Reusable_UI/Forms/FormFields'

//typeScript
interface Props {
  submitCount: number
}

const CsvFileForm = ({ submitCount }: Props) => {
  return (
    <Form>
      <Row>
        <Col span={20}>
          <Field
            label="File Name"
            name="fileName"
            type="text"
            allowClear
            validate={validateRequired}
            submitCount={submitCount}
            component={AntInput}
          />
        </Col>
      </Row>
    </Form>
  )
}

export default CsvFileForm
