//Mutations - Brands

import gql from 'graphql-tag'

//create brand
export const CREATE_BRAND = gql`
  mutation($input: BrandInputType) {
    createBrand(input: $input) {
      brand {
        name
        description
        customerSuccessRep
        primaryBrandContact
      }
    }
  }
`

//delete brand
export const DELETE_BRAND = gql`
  mutation($id: UUID!) {
    deleteBrand(id: $id) {
      ok
    }
  }
`

//edit brand
export const EDIT_BRAND = gql`
  mutation($input: BrandInputType, $id: UUID!) {
    updateBrand(input: $input, id: $id) {
      brand {
        name
      }
    }
  }
`
