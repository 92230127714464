//Query - flowcodes

import gql from 'graphql-tag'

//get all flowcodes form options
export const GET_FLOWCODES_OPTIONS = gql`
  query($id: UUID, $category: String) {
    # permutations
    permutation(id: $id) {
      id
      # brands drop down
      permutationBrandSet {
        id
        brands {
          id
          name
        }
      }
    }

    #flowcode features
    features(category: $category) {
      id
      name
      archived
      discreteValues {
        id
        value
      }
    }
  }
`

//get all flowcodes list
export const GET_FLOWCODES_LIST = gql`
  query($id: UUID) {
    permutationBatches(id: $id) {
      id
      domain
      type
      isBuilt
      isScan
      batchFlowcodes {
        id
        redirectValue
        shorturl
        active
        batch {
          id
          attributes {
            id
            value
            feature {
              id
              name
            }
          }
        }
        flowcodeBrandSet {
          id
          permutationBrand {
            id
            brands {
              id
              name
            }
          }
        }
      }
    }
    permutation(id: $id) {
      id
      name
    }
  }
`

//get batch / flowcode by ID

export const GET_FLOWCODES_EDIT = gql`
  query($id: ID, $permutationId: UUID, $category: String) {
    # selection choices from permutation
    # permutations
    permutation(id: $permutationId) {
      id
      # brands dropdown
      permutationBrandSet {
        id
        brands {
          id
          name
        }
      }
    }
    # created batch details
    batch(id: $id) {
      id
      domain
      type
      attributes {
        id
        value
      }
      # delete attributes id
      batchDiscreteSet {
        id
        discreteValue {
          id
        }
      }
      # brand
      batchFlowcodes {
        id
        flowcodeBrandSet {
          id
          permutationBrand {
            id
          }
        }
        redirectValue
        shorturl
      }
      isScan
    }
    # features dropdown
    features(category: $category) {
      id
      name
      archived
      discreteValues {
        id
        value
      }
    }
  }
`
