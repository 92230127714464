//Container - create folder

import React, { useRef, useEffect, Fragment, useContext } from 'react'
import { Formik } from 'formik'
import { useMutation } from '@apollo/react-hooks'

//context
import { CreateFolderModalCTX } from '../../Contexts/CreateFolderModalContext'

//graphql
import { CREATE_FOLDER } from '../../Graqhql/Folders/FolderMutations'
import { GET_FOLDERS } from '../../Graqhql/Folders/FoldersQuery'

//typescript
import { FoldersFormValues } from '../../TypeScript_Types/Folders'

//utilities
import ErrorBoundary from '../../Utilities/ErrorBoundary'

//functional component
import ModalWrap from '../../Components/Reusable_UI/Modal/ModalFormWrap'
import CreateFolderForm from '../../Components/Folders/Create_Edit_FoldersForm'
import ErrorAlert from '../../Components/Reusable_UI/Alerts/ErrorAlerts'

//type props
interface Props {
  experimentId: string
}

//intial form values
const initialFieldValues: FoldersFormValues = {
  name: '',
  description: ''
}

//const modal buttons
const buttons = [
  {
    buttonName: 'Create',
    key: 'create'
  }
]

const CreateFolder: React.FC<Props> = ({ experimentId }) => {
  //context
  const {
    createFolderFormModal,
    handleVisible,
    setCreateFolderSuccess,
    createFolderError,
    setCreateFolderError,
    createFolderErrorMsg,
    setCreateFolderErrorMsg
  } = useContext(CreateFolderModalCTX)

  //ref to call reset form
  const resetRef: any = useRef(null)

  //resetForm when there is changed in the view state of the modal,
  //do not move the reference click to handleVisible function, it will change the animation direction of the modal
  useEffect(() => {
    resetRef.current && resetRef.current.click()
  }, [createFolderFormModal])

  //create brand graphql mutation
  const [createBrand, { loading }] = useMutation(CREATE_FOLDER, {
    onCompleted() {
      //on success set the sucess state to true and close the modal
      setCreateFolderSuccess(true)
      handleVisible()
    },
    onError(error: any) {
      setCreateFolderError(true)
      setCreateFolderErrorMsg(error.message)
    },
    refetchQueries: [
      { query: GET_FOLDERS, variables: { experimentId: experimentId } }
    ]
  })

  //handle submit
  const handleSubmit = (values: FoldersFormValues) => {
    createBrand({ variables: { input: values, experimentId: experimentId } })
  }

  return (
    <Fragment>
      {/* form */}

      <Formik
        initialValues={initialFieldValues}
        onSubmit={handleSubmit}
        validateOnBlur={false}
      >
        {({ submitCount, submitForm, handleReset }) => (
          <ModalWrap
            title="Create Folders"
            buttons={buttons}
            submitForm={submitForm}
            loading={loading}
            handleVisible={handleVisible}
            viewFormModal={createFolderFormModal}
          >
            {/* error alert */}
            {createFolderError && (
              <ErrorAlert
                message={
                  createFolderErrorMsg
                    ? createFolderErrorMsg
                    : 'Something went wrong'
                }
                close={true}
                onErrorClose={() => {
                  setCreateFolderError(false)
                  setCreateFolderErrorMsg('')
                }}
              />
            )}
            <ErrorBoundary>
              <CreateFolderForm submitCount={submitCount} />
            </ErrorBoundary>

            {/* reset form div ref */}
            <div onClick={handleReset} ref={resetRef} />
          </ModalWrap>
        )}
      </Formik>
    </Fragment>
  )
}

export default CreateFolder
