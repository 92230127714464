//View Permutaiton Form container
//Includes permutation details & flowcodes components.

import React, { Fragment, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

//ant d
import { Divider, Breadcrumb } from 'antd'

//graphql
import { useQuery, useMutation } from '@apollo/react-hooks'
import { GET_EDIT_PERMUTATION } from '../../Graqhql/Permutations/QueryPermutation'
import { EDIT_PERMUTATION } from '../../Graqhql/Permutations/MutationPermutation'

//contexts
import CreateModalProvider from '../../Contexts/CreateModalContext'
import EditModalProvider from '../../Contexts/EditModalContext'
import BuildModalProvider from '../../Contexts/BuildModalContext'

//utilities
import { reduceDiscreteSet } from '../../Utilities/Permutation'
import { removeCommas } from '../../Utilities/Format'
import ErrorBoundary from '../../Utilities/ErrorBoundary'

//functional components
import LayoutWrap from '../../HOC/LayoutWrap'
import PageTitle from '../../Components/Reusable_UI/Headers_Titles/PageTitles'
import ViewPermutationDetails from '../../Components/Permutations/ViewPermutationDetails'
import Flocodes from '../Flowcodes/FlowcodesList'
import ErrorAlert from '../../Components/Reusable_UI/Alerts/ErrorAlerts'
import { LoadingSpinner } from '../../Components/Reusable_UI/Loaders_Spinners/LoadingSpinner'

//const title view permutation
const PAGE_TITLE = 'View Permutation'

interface Props {
  history: any
  location: any
  match: any
  staticContext: any
}

//JSX
const ViewPermutation: React.FC<any> = ({
  history,
  location,
  match,
  staticContext
}: Props) => {
  //local state
  const [error, setFetchError] = useState()
  const [editError, setEditError] = useState()
  // const [experimentId, setExperimentId] = useState('')
  const [expFeatures, setExpFeatures] = useState()

  //permutation id retrieved from url
  const permutationId = match.params.id
  const experimentId = match.params.exp

  //get the permutation/experiment details
  const { data: permutationData, loading: loading2 } = useQuery(
    GET_EDIT_PERMUTATION,
    {
      variables: {
        experimentId: experimentId,
        permutationID: permutationId
      },
      onError(error) {
        setFetchError(error)
      }
    }
  )

  //edit permutation mutation
  const [editPermutation, { loading: editLoading }] = useMutation(
    EDIT_PERMUTATION,
    {
      onCompleted() {
        // setEditSuccess(true)
      },
      onError(error) {
        setEditError(error)
      },
      refetchQueries: [
        {
          query: GET_EDIT_PERMUTATION,
          variables: {
            experimentId: experimentId,
            permutationID: permutationId
          }
        }
      ]
    }
  )

  useEffect(() => {
    if (permutationData?.experiment.experimentDiscreteSet)
      setExpFeatures(
        reduceDiscreteSet(permutationData.experiment.experimentDiscreteSet)
      )
  }, [permutationData])

  //format the data for update mutation
  const updatePermutation = ({
    name,
    estCost,
    actualCost,
    estImpressions,
    actualImpressions,
    iscs,
    notes,
    channel,
    medium,
    folder,
    variables,
    brands,
    attributes,
    finalNumber
  }: any): void => {
    const initialVariables = permutationData.permutation.permutationVariableSet
    const initialBrands = permutationData.permutation.brand
    const initialAttributes =
      permutationData.permutation.permutationAttributeSet

    const addVariable = variables
      .filter(
        (v: any) =>
          !initialVariables
            .map((va: any) => va.experimentDiscrete.id)
            .includes(v.id)
      )
      .map((variable: any) => variable.id)

    const deleteVariable = initialVariables
      .filter(
        (v: any) =>
          !variables.map((va: any) => va.id).includes(v.experimentDiscrete.id)
      )
      .map((variable: any) => variable.id)

    const addAttribute = attributes
      .filter(
        (a: any) =>
          !initialAttributes
            .map((att: any) => att.discreteValue.id)
            .includes(a.id)
      )
      .map((attribute: any) => attribute.id)
    const deleteAttribute = initialAttributes
      .filter(
        (a: any) =>
          !attributes.map((att: any) => att.id).includes(a.discreteValue.id)
      )
      .map((attribute: any) => attribute.id)

    const addBrands = brands
      .filter((b: any) => !initialBrands.map((br: any) => br.id).includes(b.id))
      .map((brand: any) => brand.id)
    const deleteBrands = initialBrands
      .filter((b: any) => !brands.map((br: any) => br.id).includes(b.id))
      .map((brand: any) => brand.id)

    const body = {
      variables: {
        id: permutationId,
        input: {
          name,
          estCost: estCost === '' ? null : estCost,
          actualCost: actualCost === '' ? null : actualCost,
          estImpressions:
            estImpressions === '' ? null : +removeCommas(estImpressions), //remove commas and convert to Int
          actualImpressions:
            actualImpressions === '' ? null : +removeCommas(actualImpressions), //remove commas and convert to Int
          iscs,
          notes,
          channel,
          medium,
          finalNumber
        },
        folder: folder?.id || null,
        addVariable,
        deleteVariable,
        addBrands,
        deleteBrands,
        addAttribute,
        deleteAttribute
      }
    }
    editPermutation(body)
  }

  const isLoading = loading2
  const dataComplete = permutationData && expFeatures

  return (
      <LayoutWrap props={{ history, location, match, staticContext }}>
        {/* breadcrumb navigation menu */}
        {dataComplete && (
            <Breadcrumb style={{ marginBottom: '20px' }}>
              <Breadcrumb.Item>
                <Link to="/experiments_list">Experiments</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={`/${permutationData?.experiment?.id}/view_experiment`}>
                  View experiment
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>View permutation</Breadcrumb.Item>
            </Breadcrumb>
        )}

        {/* page title */}
        <PageTitle titleName={PAGE_TITLE} />

        {/* fetch permutation details error */}
        {error ? (
          <ErrorAlert
            message={error.message ? error.message : 'Something went wrong'}
          />
        ) : (
          <Fragment>
            {/* fetching permutation details loading state */}
            {isLoading || !dataComplete ? (
              <LoadingSpinner />
            ) : (
              <Fragment>
                {/* display error if occurred while updating the permutation */}
                {editError && (
                  <ErrorAlert
                    message={
                      editError.message
                        ? editError.message
                        : 'Something went wrong'
                    }
                    close={true}
                    onErrorClose={() => setEditError(undefined)}
                  />
                )}

                {/* 1. permutation details */}
                {editLoading ? (
                  <div style={{ position: 'relative' }}>
                    <LoadingSpinner />
                  </div>
                ) : (
                  <ErrorBoundary>
                    <ViewPermutationDetails
                      permutationDetails={permutationData}
                      updatePermutation={updatePermutation}
                      expFeatures={expFeatures}
                    />
                  </ErrorBoundary>
                )}

                <Divider />

                {/* flowcodes */}
                <ErrorBoundary>
                  <CreateModalProvider>
                    <EditModalProvider>
                      <BuildModalProvider>
                        <Flocodes permutationId={permutationId} />
                      </BuildModalProvider>
                    </EditModalProvider>
                  </CreateModalProvider>
                </ErrorBoundary>
              </Fragment>
            )}
          </Fragment>
        )}
      </LayoutWrap>
  )
}

export default ViewPermutation
