//This component wraps toggle elements and returns disabled or editable input element.

import { useEffect } from 'react'

//typescipt
interface ToggleProps {
  editable: boolean
  editableEl: any
  staticEl: any
  elRef?: any
  autoFocus?: boolean
}

//React FC
const ToggleWrapper = ({
  editable,
  editableEl,
  staticEl,
  elRef,
  autoFocus = false
}: ToggleProps): JSX.Element => {
  //give focus to editable element
  useEffect(() => {
    if (editable && autoFocus && elRef) {
      elRef.current.focus()
    }
  }, [editable, autoFocus, elRef])

  //return editable or disabled element
  if (editable) return editableEl
  return staticEl
}

export default ToggleWrapper
