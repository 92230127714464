//Query - Feature

import gql from 'graphql-tag'

//get feature by ID
export const GET_FEATURE = gql`
  query($id: UUID) {
    feature(id: $id) {
      id
      name
      category
      type
      channel
      discreteValues {
        id
        value
      }
    }
    channels {
      name
    }
  }
`

//get all features
export const GET_FEATURES = gql`
  query($id: String) {
    features(category: $id) {
      id
      name
      category
      channel
      type
      archived
      discreteValues {
        id
        value
      }
    }
  }
`

//get channels choices for features form

export const GET_CHANNEL_CHOICES = gql`
  query {
    channels {
      name
    }
  }
`
