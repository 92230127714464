// This is the context to handle interaction & states between the container/component buttons and modal of Folder module.
// Create modal is used by the table component and the 'Create' component to manage the modal state.

import React, { createContext, useState, useEffect } from 'react'

//create context
export const CreateFolderModalCTX: any = createContext({})

const CreateFolderModalProvider: React.FC<any> = props => {
  const [createFolderSuccess, setCreateFolderSuccess] = useState(false)
  const [createFolderError, setCreateFolderError] = useState(false)
  const [createFolderErrorMsg, setCreateFolderErrorMsg] = useState('')
  //show or hide state of modal
  const [createFolderFormModal, setViewFolderFormModal] = useState(false)

  // mount timer and unmount cleanup - set timer for 2secs and remove timer when the component is unmounted
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (createFolderSuccess) {
        setCreateFolderSuccess(false)
      }
    }, 2000)

    return () => {
      // clear timeout
      return clearTimeout(timeout)
    }
  }, [createFolderSuccess])

  const handleVisible = () => {
    if (createFolderFormModal) {
      setCreateFolderError(false)
      setCreateFolderErrorMsg('')
    }
    setViewFolderFormModal(prevState => !prevState)
  }
  return (
    <CreateFolderModalCTX.Provider
      value={{
        createFolderFormModal,
        handleVisible,
        createFolderSuccess,
        setCreateFolderSuccess,
        createFolderError,
        setCreateFolderError,
        createFolderErrorMsg,
        setCreateFolderErrorMsg
      }}
    >
      {props.children}
    </CreateFolderModalCTX.Provider>
  )
}

export default CreateFolderModalProvider
