import { TableColumns } from "../../TypeScript_Types/TableColumns"

// flowcode table

export const columnsNames: Readonly<TableColumns[]> = [
  {
    title: '#',
    key: 'number'
  },
  {
    title: 'Unique',
    key: 'unique'
  },
  {
    title: 'Batch',
    key: 'batch'
  },
  {
    title: 'Short URL',
    key: 'shorturl'
  },
  {
    title: 'Landing page',
    key: 'redirectId'
  },
  {
    title: 'Features',
    key: 'features'
  },
  {
    title: 'Brand',
    key: 'brand'
  },
  {
    title: 'Scan',
    key: 'scan'
  },
  {
    title: 'Preview',
    key: 'preview'
  },
  {
    title: 'Download',
    key: 'download'
  },
  {
    title: 'Actions',
    key: 'actions'
  }
]

export const DEFAULT_FLOWCODE_COLOR = '#000'
