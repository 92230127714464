// mutations - permutation

import gql from 'graphql-tag'

//create permutation
export const CREATE_PERMUTATION = gql`
  mutation(
    $attributes: [UUID]
    $experimentId: UUID!
    $folder: UUID
    $input: PermutationInputType
    $variables: [UUID!]
    $brands: [UUID]
  ) {
    createPermutation(
      attributes: $attributes
      experimentId: $experimentId
      folder: $folder
      input: $input
      brands: $brands
      variables: $variables
    ) {
      ok
    }
  }
`

//edit permutation
export const EDIT_PERMUTATION = gql`
  mutation(
    $addAttribute: [UUID]
    $addBrands: [UUID]
    $addVariable: [UUID]
    $deleteAttribute: [UUID]
    $deleteBrands: [UUID]
    $deleteVariable: [UUID]
    $folder: UUID
    $id: UUID!
    $input: PermutationInputType
  ) {
    updatePermutation(
      id: $id
      folder: $folder
      input: $input
      addVariable: $addVariable
      deleteVariable: $deleteVariable
      addBrands: $addBrands
      deleteBrands: $deleteBrands
      addAttribute: $addAttribute
      deleteAttribute: $deleteAttribute
    ) {
      ok
    }
  }
`

//delete permutation
export const DELETE_PERMUTATION = gql`
  mutation($id: UUID!) {
    deletePermutation(id: $id) {
      ok
    }
  }
`
