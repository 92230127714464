//This coponent will display the table content of the experiment list

import React from 'react'
import { Link } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component'

//styled components
import { Menu, Dropdown, Icon, Spin } from 'antd'

//stylesheet
import '../Reusable_UI/Styles.less'

//constants
import { columnsNames } from '../../Constants/Experiment/ExperimentListColumns'

//typeScript
import { ExperimentTableProps } from '../../TypeScript_Types/ExperimentTypes'

//functional component
import NoData from '../Reusable_UI/NoData/NoData'

//ant Icon
const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />

//React FC
const ExperimenTable = ({
  experimentsData,
  loading,
  onLoadMore,
  hasNextPage,
  deleteId,
  handleDeleteExperiment,
  deleteLoading,
  history
}: ExperimentTableProps) => {
  // pop up action menu
  const menu = (experimentId: string, name: string) => {
    return (
      <Menu>
        <Menu.Item key="0">
          <Link to={`${experimentId}/edit_experiment`}>Edit experiment</Link>
        </Menu.Item>
        <Menu.Item
          key="1"
          onClick={() => handleDeleteExperiment(experimentId, name)}
        >
          Delete experiment
        </Menu.Item>
      </Menu>
    )
  }

  return (
    <div style={{ marginTop: 10 }}>
      {/* this infinite scroll component will monitor scroll action and call loadMore function */}
      <InfiniteScroll
        dataLength={experimentsData.length}
        next={onLoadMore}
        hasMore={hasNextPage}
        loader={
          <div style={{ textAlign: 'center', marginTop: '5px' }}>
            <Spin indicator={antIcon} />{' '}
          </div>
        }
        style={{ overflow: 'visible', overflowX: 'scroll' }}
      >
        <table className="tableContainer experiment-list">
          <thead className="tableHead">
            <tr>
              {columnsNames.map((item: any) => (
                <th key={item.key}>{item.title}</th>
              ))}
            </tr>
          </thead>

          {/* table list */}

          <tbody className="tableBody">
            {experimentsData &&
              experimentsData.map((rowItems: any, rowIndex) => {
                return (
                  <tr
                    key={rowIndex}
                    onClick={() =>
                      history.push(`${rowItems.node.id}/view_experiment`)
                    }
                  >
                    <td>{`${rowItems.node.name.substring(0, 60)}${
                      rowItems.node.name.length > 60 ? '...' : ''
                    }`}</td>
                    <td>
                      {new Date(rowItems.node.timeStamp).toLocaleString()}
                    </td>
                    <td>{rowItems.node.numberFolders}</td>
                    <td>{rowItems.node.numberPermutations}</td>

                    {/* actions - show loading spiiner if delete in progress else the menu */}
                    {rowItems.node.id === deleteId && deleteLoading ? (
                      <td style={{ textAlign: 'center' }}>
                        <Icon type="loading" />
                      </td>
                    ) : (
                      // action menu
                      <td onClick={e => e.stopPropagation()}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          {/* pop up more */}
                          <Dropdown
                            overlay={() =>
                              menu(rowItems.node.id, rowItems.node.name)
                            }
                            trigger={['click']}
                          >
                            <p
                              style={{
                                color: '#1890ff',
                                cursor: 'pointer',
                                marginBottom: 0
                              }}
                            >
                              More
                            </p>
                          </Dropdown>
                        </div>
                      </td>
                    )}
                  </tr>
                )
              })}
          </tbody>
        </table>

        {/* display no data icon */}
        {((experimentsData && experimentsData.length === 0) ||
          (!experimentsData && loading)) && <NoData loading={loading} />}
      </InfiniteScroll>
    </div>
  )
}

export default ExperimenTable
