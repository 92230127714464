//This coponent will display the table content of the Folders

import React, { useContext } from 'react'

//styled components
import { Menu, Dropdown, Icon } from 'antd'

//stylesheet
import '../Reusable_UI/Styles.less'

//constants
import { columnsNames } from '../../Constants/Folders/FoldersList'

//context
import { EditFolderModalCTX } from '../../Contexts/EditFolderModalContext'

//functional component
import NoData from '../Reusable_UI/NoData/NoData'

//typescript
interface Props {
  folderListData: {
    folders: Array<object>
  }
  listLoading: boolean
  setEditFolderId: React.Dispatch<React.SetStateAction<string>>
  deleteFolderId: string
  handleDeleteFolder: (id: string, name: string) => void
  deleteLoading: boolean
}

//JSX
const FoldersListTable = ({
  folderListData,
  setEditFolderId,
  listLoading,
  deleteFolderId,
  deleteLoading,
  handleDeleteFolder
}: Props) => {
  //context
  const { handleVisible } = useContext(EditFolderModalCTX)

  // pop up action menu
  const menu = (rowItems: { id: string; name: string }) => {
    return (
      <Menu>
        <Menu.Item
          key="1"
          onClick={() => {
            setEditFolderId(rowItems.id)
            handleVisible()
          }}
        >
          Edit folder
        </Menu.Item>
        <Menu.Item
          key="3"
          onClick={() => {
            handleDeleteFolder(rowItems.id, rowItems.name)
          }}
        >
          Delete folder
        </Menu.Item>
      </Menu>
    )
  }

  return (
    <div style={{ marginTop: 10 }}>
      <table className="tableContainer">
        <thead className="tableHead">
          <tr>
            {columnsNames.map((item: any) => (
              <th key={item.key}>{item.title}</th>
            ))}
          </tr>
        </thead>

        {/* table list */}

        <tbody className="tableBody">
          {folderListData &&
            folderListData.folders.map((rowItems: any, rowIndex) => {
              return (
                <tr key={rowIndex}>
                  <td>{rowItems.name}</td>
                  <td>{rowItems.description}</td>
                  <td>{rowItems.permutationCount}</td>
                  <td>
                    {rowItems.id === deleteFolderId && deleteLoading ? (
                      <Icon type="loading" />
                    ) : (
                      <Dropdown
                        overlay={() => menu(rowItems)}
                        trigger={['click']}
                      >
                        <Icon type="more" />
                      </Dropdown>
                    )}
                  </td>
                </tr>
              )
            })}
        </tbody>
      </table>

      {/* show loader or no data */}
      {(!folderListData && listLoading) ||
      (folderListData && folderListData.folders.length === 0) ? (
        <NoData loading={listLoading} />
      ) : null}
    </div>
  )
}

export default FoldersListTable
