//This is a common form for create feature
//The form "Field" component is from the resuable UI components - refer /Reusable_UI

import React, { Fragment, useEffect, useState } from 'react'

//form handler
import { Form, Field } from 'formik'

//styled components
import { Row, Col, Form as AntForm, Input, Tag, Button } from 'antd'

//constant
import { typeChoices } from '../../Constants/Features/FeatureValueType'
import { featureCategories } from '../../Constants/Features/FeaturesList'

// utilities
import {
  validateRequired,
  validateRequiredArray
} from '../../Utilities/Validate'
import { checkNumericVal } from '../../Utilities/CheckNumeric'

//typeScript
import { FeatureFormsProps } from '../../TypeScript_Types/Features'

//functional components
import { AntInput, AntSelect } from '../Reusable_UI/Forms/FormFields'

//array destructuring
const FormItem = AntForm.Item

//JSX - react FC
const CreateFeatureForm = ({
  submitCount,
  values,
  setFieldValue,
  channelChoices
}: FeatureFormsProps) => {

  //local state
  const [isBooleanType, setIsBooleanType] = useState(false)


  //update boolean type
  useEffect(() => {
    if(values.type === 'Boolean'){
      setIsBooleanType(true);
    } else{
      setIsBooleanType(false);
    }
  }, [values])

  //save the inputs on hitting enter
  const handleValuesEnter = () => {
    //featureNames - object with discreteValue
    if (values.featureName) {  
      const value = [{ value: values.featureName }, ...values.discreteValues]
      setFieldValue('discreteValues', value)
      //clear input
      setFieldValue('featureName', '')
    }
  }

  //remove the feature name
  const handleClose = (removeFeature: any) => {
    const value = values.discreteValues.filter(
      (featureName: any) => featureName.value !== removeFeature.value
    )
    setFieldValue('discreteValues', value)
  }

  return (
    <Form>
      <Row gutter={[10, { xs: 8, sm: 10, md: 24, lg: 32 }]}>
        <Col xs={{ span: 12 }}>
          {/* Name */}
          <Field
            label="Name"
            name="name"
            type="text"
            allowClear
            placeholder="Feature name"
            validate={validateRequired}
            submitCount={submitCount}
            component={AntInput}
          />
        </Col>

        <Col xs={{ span: 12 }}>
          {/* category */}
          <Field
            label="Category"
            name="category"
            allowClear
            placeholder="Select category"
            submitCount={submitCount}
            selectOptions={featureCategories}
            component={AntSelect}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          {/* Channel */}
          <Field
            label="Channel"
            name="channel"
            placeholder="Select channel"
            mode="multiple"
            allowClear
            submitCount={submitCount}
            selectOptions={channelChoices}
            component={AntSelect}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          {/* values */}
          <Field
            label="Values type"
            name="type"
            allowClear
            placeholder="Select type"
            submitCount={submitCount}
            validate={validateRequired}
            selectOptions={typeChoices}
            setFieldValue={setFieldValue}
            component={AntSelect}
          />
        </Col>
      </Row>

      {/* values for the selected type */}
      {values.type && (
        <Fragment>
          {!isBooleanType && (
            <Row
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <Col span={20}>
                <Fragment>
                  <Field
                    name="featureName"
                    validate={() =>
                      validateRequiredArray(values.discreteValues)
                    }
                  >
                    {({ field, form }: any) => (
                      <FormItem
                        label="Values"
                        // field validations help & feedback
                        help={
                          (values.discreteValues.length === 0 &&
                            submitCount > 0) ||
                          (form.touched.featureName &&
                            values.discreteValues.length === 0)
                            ? 'Values not entered for the value type'
                            : false
                        }
                        validateStatus={
                          (values.discreteValues.length === 0 &&
                            submitCount > 0) ||
                          (form.touched.featureName &&
                            values.discreteValues.length === 0)
                            ? 'error'
                            : 'success'
                        }
                      >
                        <Input
                          {...field}
                          allowClear
                          value={values.featureName}
                          placeholder="Enter value and hit return"
                          onChange={({ target: { value } }: any) => {
                            values.type === 'Numerical'
                              ? form.setFieldValue(
                                  'featureName',
                                  checkNumericVal(value)
                                )
                              : form.setFieldValue('featureName', value)
                          }}
                          onPressEnter={handleValuesEnter}
                          onBlur={() => form.setFieldTouched(field.name, true)}
                        />
                      </FormItem>
                    )}
                  </Field>
                </Fragment>
              </Col>
              <Col>
                {/* Add button to add the input to the featuresNames */}
                <Button
                  style={{ marginTop: '10px' }}
                  disabled={!values.featureName}
                  onClick={handleValuesEnter}
                >
                  Add
                </Button>
              </Col>
            </Row>
          )}

          <div style={{ padding: '0px 6px' }}>
            {/* feature names / values listing */}
            {values.discreteValues.length > 0
              ? values.discreteValues.map((item: any, index: number) => (
                  <Tag
                    key={index}
                    onClose={() => handleClose(item)}
                    style={{ marginBottom: '10px', display: "inline-block" }}
                    closable={!isBooleanType}
                  >
                    {item.value}
                  </Tag>
                ))
              : null}
          </div>
        </Fragment>
      )}
    </Form>
  )
}

export default CreateFeatureForm
