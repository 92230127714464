//Create or edit folder form component

import React from 'react'

//form handler
import { Form, Field } from 'formik'

//functional components
import { AntInput, AntTextarea } from '../Reusable_UI/Forms/FormFields'
//utilities
import { validateRequired } from '../../Utilities/Validate'

//typeScript
interface Props {
  submitCount: number
}

const CreateFolderForm = ({ submitCount }: Props) => {
  return (
    <Form>
      {/* folder name */}
      <Field
        label="Name"
        name="name"
        type="text"
        validate={validateRequired}
        submitCount={submitCount}
        component={AntInput}
      />

      {/* folder description */}
      <Field
        label="Description"
        name="description"
        type="text"
        submitCount={submitCount}
        component={AntTextarea}
      />
    </Form>
  )
}

export default CreateFolderForm
