import { TableColumns } from "../../TypeScript_Types/TableColumns";

//this is the names of the columns in folders list table

export const columnsNames: Readonly<TableColumns[]> = [
  {
    title: 'Folder Name',
    key: 'name'
  },
  {
    title: 'Description',
    key: 'description'
  },
  {
    title: '# Permutations',
    key: 'permutationCount'
  },
  {
    title: 'Actions',
    key: 'actions'
  }
]
