//This is the parent container for showing the list of features in /features_list.

import React, { useState, useEffect, useContext, Fragment } from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { Link } from 'react-router-dom'

//styled components
import { Collapse, Tooltip, Button, Modal, Breadcrumb } from 'antd'

//graphql query
import { GET_FEATURES } from '../../Graqhql/Features/FeatureQuery'
import { DELETE_FEATURE } from '../../Graqhql/Features/FeatureMutation'

//context
import { CreateModalCTX } from '../../Contexts/CreateModalContext'
import { EditModalCTX } from '../../Contexts/EditModalContext'

//utilities
import ErrorBoundary from '../../Utilities/ErrorBoundary'

//functional components
import CreateFeature from '../Feature/CreateFeature'
import PageTitle from '../../Components/Reusable_UI/Headers_Titles/PageTitles'
import LayoutWrap from '../../HOC/LayoutWrap'
import { SearchField } from '../../Components/Reusable_UI/Forms/SearchField'
import FeaturesListTable from '../../Components/FeaturesList/FeaturesListTable'
import EditFeature from '../Feature/EditFeature'
import SuccessAlert from '../../Components/Reusable_UI/Alerts/SuccessAlerts'
import ErrorAlert from '../../Components/Reusable_UI/Alerts/ErrorAlerts'

//ant component destructuring
const { Panel } = Collapse
const { confirm } = Modal

//inline styles
const titleDiv = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
}

const searchAddDiv = {
  display: 'flex',
  justifyContent: 'space-between',
  flexBasis: '21%'
}

//const title of login page
const PAGE_TITLE = 'Features'

//Breadcrumb nav
const FeatureBreadcrumb = () => {
  return (
    <Breadcrumb style={{ marginBottom: '20px' }}>
      <Breadcrumb.Item>
        <Link to="create_experiment">Create experiment</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>Features</Breadcrumb.Item>
    </Breadcrumb>
  )
}

//JSX
const FeaturesList: React.FC<any> = props => {
  //local state
  const [featuresData, setFeatures] = useState([])
  const [featuresDataError, setFeaturesDataError] = useState()
  const [activeData, setActiveData] = useState([])
  const [inactiveData, setInactiveData] = useState([])
  const [activeKey, setActiveKey] = useState(['1'])
  const [editFeatureId, setEditFeatureId] = useState('')
  const [archivedId, setArchivedId] = useState()
  const [archiveError, setArchiveError] = useState()

  //contexts
  const { createSuccess, handleVisible, viewCreateFormModal } = useContext(
    CreateModalCTX
  )
  const { editSuccess, viewEditFormModal } = useContext(EditModalCTX)

  //get features data from graphql
  const { data, loading } = useQuery(GET_FEATURES, {
    onError(error) {
      setFeaturesDataError(error)
    }
  })

  //update data
  useEffect(() => {
    if (data) {
      setFeatures(data.features)
    }
  }, [data])

  //sort features based on active or inactive state whenever features Data changes
  useEffect(() => {
    if (featuresData) {
      let active: any = []
      let inactive: any = []

      featuresData.forEach((item: any) => {
        if (item.archived) {
          inactive.push(item)
        } else {
          active.push(item)
        }
      })

      setActiveData(active)
      setInactiveData(inactive)
    }
  }, [featuresData])

  //delete brand mutation
  const [updateArchive, { loading: archiveLoading }] = useMutation(
    DELETE_FEATURE,
    {
      onCompleted() {
        setArchivedId(null)
      },
      onError() {
        setArchiveError(true)
      },
      refetchQueries: [{ query: GET_FEATURES }]
    }
  )

  //call's delete mutation to deactivate
  const handleArchive = (id: string, name: string, archived: boolean) => {
    //set the selected feature id for archive
    setArchivedId(id)

    //confirm modal
    confirm({
      title: archived ? 'Activate feature' : 'Deactivate feature',
      content: `Are you sure you want to ${
        archived ? 'activate' : 'de-activate'
      } ${name.substring(0, 40)}...?`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        updateArchive({
          variables: {
            id: id,
            input: { archived: archived ? false : true }
          }
        })
      }
    })
  }

  // close archive error popup
  const onCloseArchiverror = () => {
    setArchiveError(null)
    setArchivedId(null)
  }

  //search feature filter
  const handleSearch = (value: string) => {
    if (!value) {
      setFeatures(featuresData)
    } else {
      const filteredItem = data.features.filter((feature: any) => {
        let name = feature.name.toLowerCase()
        return name.indexOf(value) > -1
      })
      setFeatures(filteredItem)
    }
  }

  return (
    <LayoutWrap props={props}>
      {/* breadcrumb navigations */}
      <FeatureBreadcrumb />

      {/* title and search bar div */}
      <div style={titleDiv}>
        <PageTitle titleName={PAGE_TITLE} />
        <div style={searchAddDiv}>
          <SearchField
            handleSearch={handleSearch}
            placeholderVal="search feature"
            disabled={!featuresData}
          />
          {/* create feature button */}
          <Tooltip title="Create new feature" placement="topRight">
            <Button
              shape="circle"
              icon="plus"
              type="primary"
              onClick={handleVisible}
            />
          </Tooltip>
          {/* create feature form */}
          {viewCreateFormModal && <CreateFeature />}
        </div>
      </div>

      {/* if error while fetching features, display the error alert else render table */}

      {featuresDataError ? (
        <ErrorAlert message={featuresDataError.message} />
      ) : (
        <Fragment>
          {/* create or edit feature success alert */}
          {createSuccess || editSuccess ? (
            <SuccessAlert
              message={
                createSuccess
                  ? 'Feature created successfully.'
                  : 'Feature updated successfully.'
              }
              closable={true}
              title={createSuccess ? 'Create feature' : 'Edit feature'}
            />
          ) : null}

          {/* archive feature error alert */}
          {archiveError && (
            <ErrorAlert
              message={
                archiveError ? archiveError.message : 'Something went wrong.'
              }
              close={true}
              onErrorClose={onCloseArchiverror}
            />
          )}

          {/* table content  in collapsible panel*/}
          <Collapse
            activeKey={activeKey}
            onChange={(key: any) => setActiveKey(key)}
          >
            {/* active panel */}
            <Panel header="Active" key="1">
              <ErrorBoundary>
                <FeaturesListTable
                  featuresData={activeData}
                  tableLoading={loading}
                  setEditFeatureId={setEditFeatureId}
                  archivedId={archivedId}
                  archiveLoading={archiveLoading}
                  handleArchive={handleArchive}
                />
              </ErrorBoundary>
            </Panel>

            {/* inactive panel */}
            <Panel header="Inactive" key="2">
              <ErrorBoundary>
                <FeaturesListTable
                  featuresData={inactiveData}
                  tableLoading={loading}
                  setEditFeatureId={setEditFeatureId}
                  archivedId={archivedId}
                  archiveLoading={archiveLoading}
                  handleArchive={handleArchive}
                />
              </ErrorBoundary>
            </Panel>
          </Collapse>

          {viewEditFormModal && <EditFeature editFeatureId={editFeatureId} />}
        </Fragment>
      )}
    </LayoutWrap>
  )
}

export default FeaturesList
