//This component will toggle between tree select and description preview
import React, { useRef, useState } from 'react'
//styled component
import { Form, TreeSelect, Collapse, Descriptions } from 'antd'
//utilities
import ToggleWrapper from './ToggleWrapper'

//ant design component destructuring
const { Item } = Form
const { Panel } = Collapse
const DescItem = Descriptions.Item

//typescript
interface ListSelectProps {
  name: string
  selectValue?: any[]
  setFieldValue: (value: any) => void
  onToggle?: () => void
  onElChange?: () => void
  help?: string | boolean
  validateStatus?: '' | 'error' | 'success'
  optionFilterProp?: string
  formField?: any
  treeData: any
  filterProp: string
  showCheckedStrategy?: any
  label?: string
  disableEdit?: boolean
  selectedItems: any[]
}

//JSX
const ToggleTreeSelect = ({
  name,
  selectValue,
  setFieldValue,
  onToggle,
  onElChange,
  help,
  validateStatus,
  filterProp,
  formField,
  treeData,
  showCheckedStrategy,
  label,
  disableEdit = false,
  selectedItems
}: ListSelectProps): JSX.Element => {
  //ref
  const ref = useRef()
  //local state
  const [isEdit, setIsEdit] = useState(false)

  return (
    <Item
      label={label || name[0].toUpperCase() + name.slice(1)}
      help={help}
      validateStatus={validateStatus}
      className="ant-row-left-align"
    >
      <ToggleWrapper
        editable={isEdit}
        elRef={ref}
        staticEl={
          <div
            onClick={() => {
              !disableEdit && setIsEdit(true)
            }}
            className={disableEdit ? 'd-pointer' : 'c-pointer'}
          >
            <Collapse bordered={false} defaultActiveKey={['1']}>
              <Panel
                showArrow={false}
                header={'Preview selected ' + label?.toLowerCase()}
                key="1"
              >
                {!selectedItems || selectedItems.length === 0 ? (
                  <div style={{ textAlign: 'center' }}>
                    <p>{label} not selected.</p>
                  </div>
                ) : (
                  <Descriptions
                    layout="vertical"
                    bordered
                    size="small"
                    style={{ background: 'white' }}
                  >
                    {selectedItems.map((previewItem: any) => (
                      <DescItem
                        label={previewItem.featureName}
                        key={previewItem.featureId}
                      >
                        {previewItem.discreteValues &&
                          previewItem.discreteValues.map((item: any) => (
                            <p key={item.id} style={{ marginBottom: '5px' }}>
                              {item.value}
                            </p>
                          ))}
                      </DescItem>
                    ))}
                  </Descriptions>
                )}
              </Panel>
            </Collapse>
          </div>
        }
        editableEl={
          <TreeSelect
            // {...formField}
            value={selectValue || selectedItems}
            treeData={treeData}
            searchPlaceholder="Please select features value"
            treeCheckable={true}
            allowClear
            showCheckedStrategy={showCheckedStrategy}
            onChange={(value: any) => {
              setFieldValue(value)
              if (onElChange) onElChange()
            }}
            onDropdownVisibleChange={(open: boolean) => {
              if (!open) {
                setIsEdit(false)
                if (onToggle) onToggle()
              }
            }}
            ref={(node: any) => (ref.current = node)}
            treeNodeFilterProp={filterProp}
          />
        }
      />
    </Item>
  )
}

export default ToggleTreeSelect
