//based on the environment development or production set the URL
//used in ApolloSetup.tsx & RESTSetup.tsx

export const EnvironmentURL = (): string => {
  let baseUrl = 'http://localhost:8000'
  if (process.env.REACT_APP_ENV === 'production') {
    baseUrl = 'https://mc-api.dtxcloud.com'
  } else if (process.env.REACT_APP_ENV === 'staging') {
    baseUrl = 'https://stg-api.dtxcloud.com'
  }
  return baseUrl
}

//note - for graphql '/graphql' will be appeneded in the ApolloSetup.
//     - for REST '/v1' will be appeneded in the individual axios call.
