//This is the utility function for creating a features -> Discrete value tree structure for TreeSelect styled component

//typeScripts
import { AllFeaturesItem, Feature } from '../TypeScript_Types/Features'

//tree data for TreeSelect
export const GetFeaturesTree = (
  inputData: Array<AllFeaturesItem | Feature>
): any => {
  const treeData = inputData.map((feature, i: number) => ({
    // Parent
    value: feature.id,
    title: feature.name,
    key: `0-${i}`,
    children: feature.discreteValues.map((discrete, index: number) => {
      const key = `0-${i}-${index}`
      return {
        // child
        title: discrete.value,
        value: discrete.id,
        key: key
      }
    })
  }))
  return treeData
}
