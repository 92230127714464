import React from 'react'

//form handler
import { Form, Field } from 'formik'

//utitlities
import { validateRequired } from '../../Utilities/Validate'

//functional components
import { AntInput, AntTextarea } from '../Reusable_UI/Forms/FormFields'

//typeScript
interface Props {
  isSubmitting: boolean
  submitCount: number
}

const createFolderForm = ({ submitCount }: Props) => {
  return (
    <Form>
      {/* brand name */}
      <Field
        label="Brand name"
        name="name"
        type="text"
        allowClear
        validate={validateRequired}
        submitCount={submitCount}
        component={AntInput}
      />

      {/* brand description */}
      <Field
        label="Description"
        name="description"
        type="text"
        allowClear
        submitCount={submitCount}
        component={AntTextarea}
      />

      {/* contact */}
      <Field
        label="Primary Brand Contact"
        name="primaryBrandContact"
        type="text"
        allowClear
        submitCount={submitCount}
        component={AntInput}
      />

      {/* customer success rep */}
      <Field
        label="Customer Success Rep"
        name="customerSuccessRep"
        type="text"
        allowClear
        submitCount={submitCount}
        component={AntInput}
      />
    </Form>
  )
}

export default createFolderForm
