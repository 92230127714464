//Create flowcodes Form container

import React, { useContext, useState, Fragment, useRef, useEffect } from 'react'
import { Formik } from 'formik'
import { useQuery, useMutation } from '@apollo/react-hooks'

//context
import { CreateModalCTX } from '../../Contexts/CreateModalContext'

//graphql
import {
  GET_FLOWCODES_OPTIONS,
  GET_FLOWCODES_LIST
} from '../../Graqhql/Flowcodes/FlowcodesQuery'
import { CREATE_BATCH } from '../../Graqhql/Flowcodes/FlowcodesMutation'

//utilities
import { constructRedirectValue } from '../../Utilities/Flowcode'
import ErrorBoundary from '../../Utilities/ErrorBoundary'

//typescript
import { FlowcodesFormValues } from '../../TypeScript_Types/Flowcodes'

//functional component
import CreateFlowcode from '../../Components/Flowcodes/Create_Edit_Flowcode'
import ModalWrap from '../../Components/Reusable_UI/Modal/ModalFormWrap'
import { LoadingSpinner } from '../../Components/Reusable_UI/Loaders_Spinners/LoadingSpinner'
import ErrorAlert from '../../Components/Reusable_UI/Alerts/ErrorAlerts'
import BuildBatch from './SaveBuildBatch'

//initial form fields
const initialFieldValues: FlowcodesFormValues = {
  type: '',
  brand: [],
  redirectValue: '', // remove
  attributes: [],
  isScan: true,
  redirectValue1: '',
  redirectValue2: '',
  redirectValue3: '',
  redirectValue4: '',
  redirectValue5: '',
  redirectValue6: ''
}

interface Props {
  permutationId: string
  flowcodeType: string
}

//React FC - JSX
const CreateFlowcodes = ({
  permutationId,
  flowcodeType
}: Props): JSX.Element => {
  //local state
  const [saveAndBuild, setSaveAndBuild] = useState(false)
  const [createComplete, setCreateComplete] = useState(false)
  const [batchId, setBatchId] = useState()
  const [flowcodesFetchError, setFlowcodesFetchError] = useState()
  const [initialDataReady, setInitialDataReady] = useState(false)

  //context
  const {
    viewCreateFormModal,
    handleVisible,
    setCreateSuccess,
    setCreateError,
    setCreateErrorMsg,
    createError,
    createErrorMsg
  } = useContext(CreateModalCTX)

  //ref to call reset form
  const resetRef: any = useRef(null)

  //get flowcodes options
  const { data: flowcodesData, loading: flowcodesLoading } = useQuery(
    GET_FLOWCODES_OPTIONS,
    {
      variables: { id: permutationId, category: 'flowcode' },
      onError(error) {
        setFlowcodesFetchError(error)
      }
    }
  )

  //set true if initial data is ready, not using onCompleted doesn't work in few refresh scenarios
  useEffect(() => {
    if (flowcodesData) {
      setInitialDataReady(true)
    }
  }, [flowcodesData])

  //reset form on modal/form close action
  useEffect(() => {
    if (viewCreateFormModal) {
      resetRef.current && resetRef.current.click()
    }
  }, [viewCreateFormModal])

  //create flowcodes mutation
  const [createBatch, { loading: createLoading }] = useMutation(CREATE_BATCH, {
    onCompleted(data) {
      //if create and clone is false, close the modal and set success to true.
      if (!saveAndBuild) {
        setCreateSuccess(true)
        handleVisible()
      } else {
        setCreateComplete(true)
        setBatchId(data.createBatch.batch.id)
      }
    },
    onError(error) {
      setCreateError(true)
      setCreateErrorMsg(error.message)
    },
    refetchQueries: [
      { query: GET_FLOWCODES_LIST, variables: { id: permutationId } }
    ]
  })

  //this function will be called when save and build button is clicked
  const handleSaveBuild = (): void => {
    setSaveAndBuild(prevState => !prevState)
  }

  //handle submit
  const handleSubmit = (values: FlowcodesFormValues): void => {
    // let input = { ...values}
    const input = {
      type: values.type.toUpperCase(),
      redirectValue: constructRedirectValue(values),
      isScan: values.isScan
      // domain: " "
    }
    const permutationBrand = values.brand
    const attributes = values.attributes
    createBatch({
      variables: {
        permutationId: permutationId,
        input,
        attributes,
        permutationBrand
      }
    })
  }

  //For flow codes that are ‘one’ there should only be the option to save.

  const nonOneButtons = [
    {
      buttonName: 'Save',
      key: 'save'
    },
    {
      buttonName: 'Save & build',
      key: 'saveBuild',
      action: handleSaveBuild
    }
  ]

  const oneTypeButtons = [
    {
      buttonName: 'Save',
      key: 'save'
    }
  ]

  return (
    <Fragment>
      {saveAndBuild && createComplete ? (
        <BuildBatch
          batchId={batchId}
          saveAndBuild={saveAndBuild && createComplete}
          handleSaveBuild={handleSaveBuild}
          setCreateComplete={setCreateComplete}
        />
      ) : (
        <Formik
          initialValues={initialFieldValues}
          onSubmit={handleSubmit}
          validateOnBlur={false}
        >
          {({
            submitCount,
            submitForm,
            values,
            setFieldValue,
            handleReset
          }) => (
            <ModalWrap
              title="Create Flowcodes"
              buttons={
                flowcodeType === 'ONE' || values.type === 'One'
                  ? oneTypeButtons
                  : nonOneButtons
              }
              submitForm={submitForm}
              loading={createLoading}
              handleVisible={handleVisible}
              viewFormModal={viewCreateFormModal}
            >
              {/* Display error if occurred during fetching form options */}
              {flowcodesFetchError ? (
                <ErrorAlert
                  message={
                    flowcodesFetchError.message
                      ? flowcodesFetchError.message
                      : 'Something went wrong'
                  }
                />
              ) : (
                <>
                  {flowcodesLoading ? (
                    <LoadingSpinner />
                  ) : (
                    <Fragment>
                      {/* error creating flowcode  */}
                      {createError && (
                        <ErrorAlert
                          message={
                            createErrorMsg
                              ? createErrorMsg
                              : 'Something went wrong'
                          }
                          close={true}
                          onErrorClose={() => {
                            setCreateError(false)
                            setCreateErrorMsg('')
                          }}
                        />
                      )}
                      <ErrorBoundary>
                        <CreateFlowcode
                          submitCount={submitCount}
                          flowcodesData={flowcodesData}
                          values={values}
                          flowcodeType={flowcodeType}
                          featuresData={
                            flowcodesData &&
                            flowcodesData.features?.filter(
                              (feature: { archived: boolean }) =>
                                !feature.archived
                            )
                          }
                          setFieldValue={setFieldValue}
                          initialDataReady={initialDataReady}
                          setInitialDataReady={setInitialDataReady}
                        />
                      </ErrorBoundary>
                    </Fragment>
                  )}
                </>
              )}

              {/* reset form div ref */}
              <div onClick={handleReset} ref={resetRef} />
            </ModalWrap>
          )}
        </Formik>
      )}
    </Fragment>
  )
}

export default CreateFlowcodes
