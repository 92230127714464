//Flowcodes list container
//Includes create flowcodes button, flowcode table components

import React, { useState, Fragment, useContext } from 'react'
import { BlockPicker, ColorResult } from 'react-color'

//REST
import API from '../../Utilities/RESTSetup'

//ant d
import { Tooltip, Button, Modal } from 'antd'

//graphql
import { useQuery, useMutation } from '@apollo/react-hooks'
import { GET_FLOWCODES_LIST } from '../../Graqhql/Flowcodes/FlowcodesQuery'
import { DELETE_BATCH } from '../../Graqhql/Flowcodes/FlowcodesMutation'

//context
import { CreateModalCTX } from '../../Contexts/CreateModalContext'
import { EditModalCTX } from '../../Contexts/EditModalContext'
import { BuildModalCTX } from '../../Contexts/BuildModalContext'

//functional component
import { SearchField } from '../../Components/Reusable_UI/Forms/SearchField'
import FlowcodeTable from '../../Components/Flowcodes/FlowcodesTable'
import CreateFlowcodes from './CreateFlowcodes'
import SuccessAlert from '../../Components/Reusable_UI/Alerts/SuccessAlerts'
import PageTitle from '../../Components/Reusable_UI/Headers_Titles/PageTitles'
import ModalWrap from '../../Components/Reusable_UI/Modal/ModalFormWrap'
import EditFlowcodes from './EditFlowcodes'
import BuildBatch from '../Build/BuildBatch'
import PreviewQR from './PreviewQR'
import { useEffect } from 'react'
import { DEFAULT_FLOWCODE_COLOR } from '../../Constants/Flowcodes/Flowcodes'
import ErrorAlert from '../../Components/Reusable_UI/Alerts/ErrorAlerts'
import ErrorBoundary from '../../Utilities/ErrorBoundary'

//ant design component destructuring
const { confirm } = Modal

//inline styles
const flowcodes = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: 20
}

const searchAddDiv = {
  display: 'flex',
  justifyContent: 'space-between',
  flexBasis: '25%'
}

interface Props {
  permutationId: string
}

const FlowcodesList = ({ permutationId }: Props): JSX.Element => {
  //local states
  const [flowcodesList, setFlowcodesList] = useState()
  const [batchesPresent, setBatchesPresent] = useState()
  const [flowcodeType, setFlowcodeType] = useState()

  const [deleteBatchId, setDeleteBatchId] = useState()
  const [deleteBatchError, setDeleteBatchError] = useState()
  const [editBatchId, setEditBatchId] = useState()
  const [buildBatchId, setBuildBatchId] = useState()
  const [buildAll, setBuildAll] = useState(false)
  const [downloadQRErrorMsg, setDownloadQRErrorMsg] = useState()
  const [downloadQRLoading, setDownloadQRLoading] = useState(false)
  const [downloadQRId, setDownloadQRId] = useState()
  const [previewQRId, setPreviewQRId] = useState()
  const [flowcodesListError, setFlowcodesListError] = useState()
  const [
    isDownloadFlowcodeModalOpen,
    setIsDownloadFlowcodeModalOpen
  ] = useState(false)
  const [flowcodeColor, setFlowcodeColor] = useState(DEFAULT_FLOWCODE_COLOR)

  //context
  const { createSuccess, viewCreateFormModal, handleVisible } = useContext(
    CreateModalCTX
  )
  const { editSuccess, viewEditFormModal } = useContext(EditModalCTX)
  const {
    buildSuccess,
    buildFormModal,
    handleVisible: buildHandleVisible
  } = useContext(BuildModalCTX)

  //get flowcodes data from graphql
  const { data: flowcodesListData, loading: flowcodeslistLoading } = useQuery(
    GET_FLOWCODES_LIST,
    {
      variables: { id: permutationId },
      onError(error) {
        setFlowcodesListError(error)
      }
    }
  )

  //update initial data
  useEffect(() => {
    setFlowcodesList(flowcodesListData)

    //To determine the 'ONE' or "MANY" type of a batch, this will be used to set the type in create/edit
    //flowcodes.
    //In any one permutation there should only be flowcodes with either ‘one’ or ‘many’.
    //Once the user creates a flow code with either, they should not be allowed to create another with the other type.
    const batchesPresentTemp =
      flowcodesListData && flowcodesListData.permutationBatches.length > 0
    const flowcodeTypeTemp = batchesPresentTemp
      ? flowcodesListData.permutationBatches[0].type
      : ''
    setBatchesPresent(batchesPresentTemp)
    setFlowcodeType(flowcodeTypeTemp)
  }, [flowcodesListData])

  //delete batch mutation
  const [deleteBatch, { loading: deleteLoading }] = useMutation(DELETE_BATCH, {
    onCompleted() {
      setDeleteBatchId(null)
    },
    onError(error) {
      setDeleteBatchError(error)
    },
    refetchQueries: [
      { query: GET_FLOWCODES_LIST, variables: { id: permutationId } }
    ]
  })

  // delete batch
  const handleDeleteBatch = (id: string): void => {
    setDeleteBatchError(null)
    setDeleteBatchId(id)

    confirm({
      title: 'Delete batch',
      content: `Are you sure you want to delete batch: ${id}?`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deleteBatch({ variables: { id: id } })
      }
    })
  }

  //download QR images
  const handleDownloadQR = (downloadBatchId: string, color?: string): void => {
    // initializations
    setDownloadQRErrorMsg(null)
    setDownloadQRId(downloadBatchId)
    setDownloadQRLoading(true)

    //API config
    const config: any = {
      headers: {
        'Content-Type': 'application/json'
      },
      responseType: 'blob',
      params: {
        defaultColor: color?.replace(/#/gi, '')
      }
    }

    API.get(`/v1/flowcode/${downloadBatchId}`, config)
      .then((response: any) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        let filename = response.request.getResponseHeader('Content-Disposition')
        filename = filename.split('"')[1]
        link.setAttribute('download', `${filename}`)
        document.body.appendChild(link)
        link.click()
        //initialize
        setDownloadQRId(null)
        setDownloadQRLoading(false)
      })
      .catch((error: any) => {
        if (error.response && error.response.status === 401) {
          setDownloadQRErrorMsg('Your session has expired. Please log in again')
          // add auto logout step
        } else if (error.response && error.response.data.detail) {
          setDownloadQRErrorMsg(error.response.data.detail)
        } else {
          setDownloadQRErrorMsg('Something went wrong')
        }

        //initialize
        setDownloadQRId(null)
        setDownloadQRLoading(false)
      })
  }

  //on close delete error alert
  const onCloseDeleteError = (): void => {
    setDeleteBatchError(null)
    setDeleteBatchId(null)
    setDownloadQRErrorMsg(null)
  }

  //search filter - by redirectValue
  const handleSearch = (value: string): void => {
    if (!value) {
      setFlowcodesList(flowcodesListData)
    } else {
      const filteredItem = flowcodesListData.permutationBatches.filter(
        (batch: any) =>
          batch.batchFlowcodes.some(
            (flowcode: any) => flowcode.redirectValue.indexOf(value) > -1
          )
      )
      const filteredBatch = {
        permutationBatches: [...filteredItem]
      }
      setFlowcodesList(filteredBatch)
    }
  }

  return (
    <div style={{ position: 'relative' }}>
      <div style={flowcodes}>
        <PageTitle titleName="Flowcodes" />

        {/* create or edit action outcomes */}
        {createSuccess || editSuccess ? (
          <SuccessAlert
            message={
              createSuccess
                ? 'Flowcode created successfully.'
                : 'Flowcode updated successfully.'
            }
            closable={true}
            title={createSuccess ? 'Create flowcode' : 'Edit flowcode'}
          />
        ) : null}

        {/* this is only for build performed from table build menu option & not'save & build' */}
        {buildSuccess && (
          <SuccessAlert
            message="Build successful."
            closable={true}
            title="Build batch"
          />
        )}

        {/* search bar and create button */}
        <div style={searchAddDiv}>
          <SearchField
            handleSearch={handleSearch}
            placeholderVal="search flowcode"
            disabled={false}
          />

          {/* create flowcode button */}
          <Tooltip title="Create flowcodes" placement="topRight">
            <Button
              shape="circle"
              icon="plus"
              type="primary"
              onClick={handleVisible}
            />
          </Tooltip>

          {/* build all batches by permutation ID button, disabled*/}
          <Tooltip title="Build all" placement="topRight">
            <Button
              shape="circle"
              icon="build"
              type="primary"
              onClick={() => {
                setBuildAll(true)
                buildHandleVisible()
              }}
              disabled={!batchesPresent}
            />
          </Tooltip>
        </div>
      </div>

      {/* create flowcode modal */}
      {viewCreateFormModal && (
        <CreateFlowcodes
          permutationId={permutationId}
          flowcodeType={flowcodeType}
        />
      )}

      {/* TODO: Extract to FlowcodeOptionsModal options modal (flowcode studio v0.0.1) */}
      {isDownloadFlowcodeModalOpen && (
        <ModalWrap
          title="Download Flowcode"
          buttons={[
            {
              buttonName: 'Download',
              key: 'download'
            }
          ]}
          loading={false}
          viewFormModal
          handleVisible={() => {
            setIsDownloadFlowcodeModalOpen(false)
            setDownloadQRId(null)
            setFlowcodeColor(DEFAULT_FLOWCODE_COLOR)
          }}
          submitForm={async () => {
            setIsDownloadFlowcodeModalOpen(false)
            handleDownloadQR(downloadQRId, flowcodeColor)
            setFlowcodeColor(DEFAULT_FLOWCODE_COLOR)
          }}
        >
          <strong>Color:</strong>
          <div className="color-picker-container">
            <BlockPicker
              color={flowcodeColor}
              triangle="hide"
              onChangeComplete={(color: ColorResult) =>
                setFlowcodeColor(color.hex)
              }
            />
          </div>
        </ModalWrap>
      )}

      {/* flowcodes by permutation ID list */}
      {flowcodesListError ? (
        <ErrorAlert message={flowcodesListError.message} />
      ) : (
        <Fragment>
          {/* delete batch error alert */}
          {deleteBatchError && (
            <ErrorAlert
              message={deleteBatchError.message}
              close={true}
              onErrorClose={onCloseDeleteError}
            />
          )}

          {/* download QR error */}
          {downloadQRErrorMsg && (
            <ErrorAlert
              message={downloadQRErrorMsg}
              close={true}
              onErrorClose={onCloseDeleteError}
            />
          )}

          {/* edit flowcode modal */}
          {viewEditFormModal && (
              <EditFlowcodes
                permutationId={permutationId}
                updateId={editBatchId}
                flowcodeType={flowcodeType}
              />
          )}

          {/* build batch */}
          {buildFormModal && (
              <BuildBatch
                batchId={buildBatchId}
                buildAll={buildAll}
                permutationId={permutationId}
                permutationName={
                  flowcodesListData ? flowcodesListData.permutation.name : ''
                }
              />
          )}

          {/* preview batch QR */}
          {!!previewQRId && (
              <PreviewQR
                previewBatchId={previewQRId}
                setPreviewQRId={setPreviewQRId}
              />
          )}

          {/* flowcodes table */}
          <ErrorBoundary>
            <FlowcodeTable
              flowcodeData={flowcodesList}
              listLoading={flowcodeslistLoading}
              setEditBatchId={setEditBatchId}
              deleteBatchId={deleteBatchId}
              handleDeleteBatch={handleDeleteBatch}
              handleDownloadQR={downloadbatchId => {
                setIsDownloadFlowcodeModalOpen(true)
                setDownloadQRId(downloadbatchId)
              }}
              setBuildBatchId={setBuildBatchId}
              downloadQRId={downloadQRId}
              downloadQRLoading={downloadQRLoading}
              deleteLoading={deleteLoading}
              setPreviewQRId={setPreviewQRId}
            />
          </ErrorBoundary>
        </Fragment>
      )}
    </div>
  )
}

export default FlowcodesList
