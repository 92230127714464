//These functions will accept dates in UTC format and render the local date in YYYY-MM-DD and time in HH:MM format.

//this is to add a '0' padding to single digits months
function pad(value: number) {
  if (value < 10) {
    return '0' + value
  } else {
    return value
  }
}

//send formatted date
export const getDateValue = (timestamp: string) => {
  let newDate = new Date(timestamp)
  return (
    pad(newDate.getMonth() + 1) +
    '-' +
    pad(newDate.getDate()) +
    '-' +
    newDate.getFullYear()
  )
}

//send formatted time
export const getTimeValue = (timestamp: string) => {
  let newDate = new Date(timestamp)
  return pad(newDate.getHours()) + ':' + pad(newDate.getMinutes())
}
