//typescript table columns
import { TableColumns } from "../../TypeScript_Types/TableColumns"

//this is the names of the columns in features list table

export const columnsNames: Readonly<TableColumns[]> = [
  {
    title: 'Name',
    key: 'name'
  },
  {
    title: 'Category',
    key: 'category'
  },
  {
    title: 'Channels',
    key: 'channels'
  },
  {
    title: 'Values',
    key: 'discreteValue'
  },
  {
    title: 'Data type',
    key: 'dataType'
  },
  {
    title: 'Actions',
    key: 'actions'
  }
]

export const featureCategories: Readonly<string[]> = [
  'Flowcode',
  'targeting',
  'education_type',
  'time',
  'user_flow',
  'creative_attention',
  'creative_interest',
  'creative_desire',
  'creative_scan_action',
  'creative_conversion_action',
  'other'
]
