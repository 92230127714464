//base instance for axios

//apollo client can make REST calls bt client cannot be configured to separate URLs, hence axios.

import axios from 'axios'
//utilities
import { EnvironmentURL } from './EnvironmentURL'

//Note - Also check ApolloSetup.tsx if any changes
let endpoint = EnvironmentURL()

const instance: any = axios.create({
  //comment the below code for dev proxy
  baseURL: endpoint

})

instance.CancelToken = axios.CancelToken
instance.isCancel = axios.isCancel

export default instance
