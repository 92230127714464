//Query - folders

import gql from 'graphql-tag'

//get folders by experiment ID
export const GET_FOLDERS = gql`
  query($experimentId: UUID) {
    folders(experimentId: $experimentId) {
      id
      name
      description
      permutationCount
    }
  }
`

//get folder by folder id

export const GET_FOLDER = gql`
  query($id: UUID) {
    folder(id: $id) {
      id
      name
      description
    }
  }
`
