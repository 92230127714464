//This container will handle the download csv action for permutations
import React, { useContext, Fragment, useState, useEffect } from 'react'
import { Formik } from 'formik'

//REST
import API from '../../Utilities/RESTSetup'

import { DownloadModalContext } from '../../Contexts/DownloadModalContext'

//functional component
import ErrorAlert from '../../Components/Reusable_UI/Alerts/ErrorAlerts'
import ModalWrap from '../../Components/Reusable_UI/Modal/ModalFormWrap'
import CsvFileForm from '../../Components/Build/CsvFile'

//types for form fields state
interface FormValues {
  fileName: string
}

//initial form fields
const initialFieldValues: FormValues = {
  fileName: ''
}

interface Props {
  permutationId: string
  permutationName: string
}

const DownloadCSV: React.FC<Props> = ({
  permutationId,
  permutationName
}: Props): JSX.Element => {
  //context
  const {
    viewDownloadFormModal,
    handleVisible,
    setDownloadSuccess
  } = useContext(DownloadModalContext)

  //local states
  const [formFields, setFormFields] = useState()
  const [downloadingCsv, setDownloadingCsv] = useState(false)
  const [downloadCsvErrorMsg, setDownloadCsvErrorMsg] = useState()

  //update filename
  useEffect(() => {
    const fileNameUpdate = Object.create(initialFieldValues)
    fileNameUpdate.fileName = permutationName.substring(0, 40)
    setFormFields(fileNameUpdate)
  }, [permutationName])

  //download csv
  const handleSubmitCsv = (values: FormValues): void => {
    // initializations
    setDownloadCsvErrorMsg(null)
    setDownloadingCsv(true)

    //API config
    const config: any = {
      headers: {
        'Content-Type': 'application/json'
      },
      responseType: 'blob'
    }

    //API call
    API.get(`/v1/csv/permutation/${permutationId}/`, config)
      .then((response: any) => {
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(new Blob([response.data]))
        link.setAttribute('download', `${values.fileName}.csv`)
        document.body.appendChild(link)
        link.click()

        //modal handling
        setDownloadSuccess(true)
        handleVisible()
        
      })
      .catch((error: any) => {
        if (error?.response?.status === 401) {
          setDownloadCsvErrorMsg(
            'Your session has expired. Please log in again'
          )
          // TODO: add auto logout step
        } else if (error.response && error.response.data.detail) {
          setDownloadCsvErrorMsg(error.response.data.detail)
        } else {
          setDownloadCsvErrorMsg('Something went wrong')
        }
        setDownloadingCsv(false);   
      })
      
  }

  const buttonsDownload = [
    {
      buttonName: 'Download CSV',
      key: 'download'
    }
  ]

  return (
    <Formik
      enableReinitialize
      initialValues={formFields}
      onSubmit={handleSubmitCsv}
      validateOnBlur={false}
    >
      {({ submitCount, submitForm }) => (
        <ModalWrap
          title="Download CSV"
          buttons={buttonsDownload}
          submitForm={submitForm}
          handleVisible={handleVisible}
          viewFormModal={viewDownloadFormModal}
          loading={downloadingCsv}
        >
          <Fragment>
            {/* download csv error  */}
            {downloadCsvErrorMsg && (
              <ErrorAlert message={downloadCsvErrorMsg}
                close={true}
                onErrorClose={() => setDownloadCsvErrorMsg(undefined)} />
            )}

            <CsvFileForm submitCount={submitCount} />
          </Fragment>
        </ModalWrap>
      )}
    </Formik>
  )
}

export default DownloadCSV
