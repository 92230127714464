// TODO: move to flowcode utils?

const urlPatternStart = /(http|sms:|mailto:)/
const urlPatternSplit = /,(http|sms:|mailto:)/

export const splitURLs = (value: string): string[] => {
  const urls = []
  let ind = value.search(urlPatternSplit)
  let url = ''
  while (ind !== -1) {
    url = value.substr(0, ind).trim()
    if (url.search(urlPatternStart) === 0) {
      urls.push(url)
    }
    value = value.substr(ind + 1)
    ind = value.search(urlPatternSplit)
  }
  url = value.trim()
  if (url.search(urlPatternStart) === 0) {
    urls.push(value)
  }
  return urls
}

export const constructRedirectValue = ({
  redirectValue1,
  redirectValue2,
  redirectValue3,
  redirectValue4,
  redirectValue5,
  redirectValue6
}: {
  redirectValue1?: string
  redirectValue2?: string
  redirectValue3?: string
  redirectValue4?: string
  redirectValue5?: string
  redirectValue6?: string
}): string =>
  [
    redirectValue1,
    redirectValue2,
    redirectValue3,
    redirectValue4,
    redirectValue5,
    redirectValue6
  ]
    .filter(Boolean)
    .join(',')
