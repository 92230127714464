//const value type - check the corresponding value of the 'Values type' selection from UI dropdown

//typescript

//constants
export const ValueType: any = {
  Freetext: 'string',
  Boolean: 'boolean',
  Numerical: 'number'
}

export const TypeValue: any = {
  string: 'Freetext',
  boolean: 'Boolean',
  number: 'Numerical'
}

//options for dropdown
export const typeChoices: Readonly<string[]> = ['Freetext', 'Numerical', 'Boolean']
