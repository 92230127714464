//This container will handle the build batch action for the individual batches from flowcodes list
//SaveBuildBatch - invoked after successful create
//OR on BUILD ALL
import React, { useContext, Fragment, useState, useEffect } from 'react'
import { Formik } from 'formik'
import { useMutation } from '@apollo/react-hooks'

//REST
import API from '../../Utilities/RESTSetup'

//context
import { BuildModalCTX } from '../../Contexts/BuildModalContext'

//graphql
import {
  BUILD_BATCH,
  BUILD_ALL
} from '../../Graqhql/Flowcodes/FlowcodesMutation'

//functional component
import ErrorAlert from '../../Components/Reusable_UI/Alerts/ErrorAlerts'
import ModalWrap from '../../Components/Reusable_UI/Modal/ModalFormWrap'
import CsvFileForm from '../../Components/Build/CsvFile'
import BuildQuantityForm from '../../Components/Build/BuildQuantity'
import { GET_FLOWCODES_LIST } from '../../Graqhql/Flowcodes/FlowcodesQuery'

//initial form fields
const initialFieldValues: FormValues = {
  quantity: '',
  fileName: ''
}

//types for form fields state
interface FormValues {
  quantity: any //this is to set quantity to empty in the intial state
  fileName: string
}

interface Props {
  batchId: string
  buildAll: boolean
  permutationId: string
  permutationName: string
}

const BuildBatch: React.FC<Props> = ({
  batchId,
  buildAll,
  permutationId,
  permutationName
}: Props): JSX.Element => {
  //context
  const {
    buildFormModal,
    handleVisible,
    setBuildSuccess,
    buildError,
    setBuildError,
    buildErrorMsg,
    setBuildErrorMsg
  } = useContext(BuildModalCTX)

  //local states
  const [formFields, setFormFields] = useState()
  const [downloadCsv, setDownloadCsv] = useState(false)
  const [downloadingCsv, setDownloadingCsv] = useState(false)
  const [downloadCsvErrorMsg, setDownloadCsvErrorMsg] = useState()

  //update filename
  useEffect(() => {
    const fileNameUpdate = Object.create(initialFieldValues)
    fileNameUpdate.fileName = permutationName.substring(0, 40)
    setFormFields(fileNameUpdate)
  }, [permutationName])

  //build individual mutation
  const [buildBatch, { loading: buildBatchLoading }] = useMutation(
    BUILD_BATCH,
    {
      onCompleted() {
        setDownloadCsv(true)
      },
      onError(error) {
        setBuildError(true)
        setBuildErrorMsg(error.message)
      }
    }
  )

  // buill all by permutation ID
  const [buildAllBatch, { loading: buildAllBatchLoading }] = useMutation(
    BUILD_ALL,
    {
      onCompleted() {
        setDownloadCsv(true)
      },
      onError(error) {
        setBuildError(true)
        setBuildErrorMsg(error.message)
      },
      refetchQueries: [
        { query: GET_FLOWCODES_LIST, variables: { id: permutationId } }
      ]
    }
  )

  //handle submit - if the build was initiated from build all button then call build all mutation
  const handleSubmitBuild = (values: FormValues): void => {
    if (buildAll) {
      buildAllBatch({
        variables: { permutationId: permutationId, quantity: values.quantity }
      })
    } else {
      buildBatch({ variables: { id: batchId, quantity: values.quantity } })
    }
  }

  //download csv
  const handleSubmitCsv = (values: FormValues): void => {
    // initializations
    setDownloadCsvErrorMsg(null)
    setDownloadingCsv(true)

    //API config
    const config: any = {
      headers: {
        'Content-Type': 'application/json'
      },
      responseType: 'blob'
    }

    //API call
    API.get(`/api/csv/permutation/${permutationId}/`, config)
      .then((response: any) => {
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(new Blob([response.data]))
        link.setAttribute('download', `${values.fileName}.csv`)
        document.body.appendChild(link)
        link.click()

        //modal handling
        setBuildSuccess(true)
        handleVisible()
      })
      .catch((error: any) => {
        if (error.response && error.response.status === 401) {
          setDownloadCsvErrorMsg(
            'Your session has expired. Please log in again'
          )
          // add auto logout step
        } else if (error.response && error.response.data.detail) {
          setDownloadCsvErrorMsg(error.response.data.detail)
        } else {
          setDownloadCsvErrorMsg('Something went wrong')
        }
      })
  }

  //const modal buttons
  const buttonsBuild = [
    {
      buttonName: 'Build',
      key: 'build'
    }
  ]

  const buttonsDownload = [
    {
      buttonName: 'Download CSV',
      key: 'download'
    }
  ]


  return (
    <Formik
      enableReinitialize
      initialValues={formFields}
      onSubmit={downloadCsv ? handleSubmitCsv : handleSubmitBuild}
      validateOnBlur={false}
    >
      {({ submitCount, submitForm }) => (
        <ModalWrap
          title="Build batch"
          buttons={downloadCsv ? buttonsDownload : buttonsBuild}
          submitForm={submitForm}
          handleVisible={handleVisible}
          viewFormModal={buildFormModal}
          loading={buildBatchLoading || buildAllBatchLoading || downloadingCsv}
        >
          <Fragment>
            {/* error building batch, close -> closable  */}
            { buildError && 
              <ErrorAlert
                message={ buildErrorMsg ? buildErrorMsg : 'Error bulding batch.'}
                close={true}
                onErrorClose={() => {setBuildError(false);  setBuildErrorMsg('')}}

              />
            }

            {/* download csv error  */}
            {downloadCsvErrorMsg && (
              <ErrorAlert message={downloadCsvErrorMsg} 
              close={true}
              onErrorClose={() => setDownloadCsvErrorMsg(undefined)}/>
            )}

            {downloadCsv ? <CsvFileForm submitCount={submitCount} />
           : <BuildQuantityForm submitCount={submitCount} />}
          </Fragment>
        </ModalWrap>
      )}
    </Formik>
  )
 }
export default BuildBatch
