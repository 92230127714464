//This component will render analytical chart in view experiment

import React from 'react'
import { Bar } from 'react-chartjs-2'
import { GET_EXPERIMENT_ANALYTICS } from '../../Graqhql/Experiment/QueryExperiment'
import { useQuery } from '@apollo/react-hooks'
import { ExperimentAnalytic } from '../../TypeScript_Types/ExperimentTypes'
import NoData from '../Reusable_UI/NoData/NoData'
import { Divider } from 'antd'
import ErrorAlert from '../Reusable_UI/Alerts/ErrorAlerts'

interface Props {
  experimentId: any
}

const SCANS_COLOR = '#356AC4'
const CPS_COLOR = '#EA4435'

const ExperimentAnalytics: React.FC<Props> = ({
  experimentId
}: Props): JSX.Element | null => {
  const [error, setError] = React.useState()

  const { data, loading } = useQuery(GET_EXPERIMENT_ANALYTICS, {
    variables: { id: experimentId },
    onError(error) {
      setError(error.message)
    }
  })

  if (loading) {
    return <div style={{marginTop: '20px'}}>
              <NoData loading={loading} />
           </div>
  }

  if (error)
    return (
        <ErrorAlert message={error || 'Error loading analytics data'} />
    )

  if (!data?.analytics.length) return null

  const names: string[] = []
  const costPerScan: number[] = []
  const scans: number[] = []

  data.analytics.forEach(
    ({ costPerScan: cps, scanCount, permutationName }: ExperimentAnalytic) => {
      scans.push(scanCount)
      names.push(permutationName)
      costPerScan.push(cps)
    }
  )

  const chartData = {
    datasets: [
      {
        label: 'Cost Per Scan',
        type: 'line',
        data: costPerScan,
        fill: false,
        borderColor: CPS_COLOR,
        backgroundColor: CPS_COLOR,
        pointBorderColor: CPS_COLOR,
        pointBackgroundColor: CPS_COLOR,
        pointHoverBackgroundColor: CPS_COLOR,
        pointHoverBorderColor: CPS_COLOR,
        yAxisID: 'y-axis-2'
      },
      {
        label: 'Scans',
        type: 'bar',
        data: scans,
        fill: false,
        backgroundColor: SCANS_COLOR,
        borderColor: SCANS_COLOR,
        hoverBackgroundColor: SCANS_COLOR,
        hoverBorderColor: SCANS_COLOR,
        yAxisID: 'y-axis-1'
      }
    ]
  }

  const options = {
    responsive: true,
    tooltips: {
      mode: 'label'
    },
    elements: {
      line: {
        fill: false
      }
    },
    scales: {
      datasets: [{
        maxBarThickness: 50,
      }],
      xAxes: [
        {
          // maxBarThickness: 50, this attribute is no longer part of xAxes
          labels: names,
          display: true,
          gridLines: {
            display: true
          },
          ticks: {
            callback: (value: string) =>
              `${value.substr(0, 10)}${value?.length > 10 ? '...' : ''}`
            //   beginAtZero: true
          }
        }
      ],
      yAxes: [
        {
          type: 'linear',
          display: true,
          position: 'left',
          id: 'y-axis-1',
          gridLines: {
            display: true
          },
          ticks: {
            // min: 0,
            stepSize: 5,
            beginAtZero: true
          }
        },
        {
          type: 'linear',
          display: true,
          position: 'right',
          id: 'y-axis-2',
          gridLines: {
            display: false
          },
          ticks: {
            beginAtZero: true,
            callback: (value: number) => `$${value.toFixed(2)}`
          }
        }
      ]
    }
  }

  return (
    <>
      <Divider />
      <section className="experiment-analytics-container">
        <Bar data={chartData} options={options} />
      </section>
      <Divider />
    </>
  )
}

export default ExperimentAnalytics
