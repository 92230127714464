//This component is used for field where "ID" to be set as the value

//This component will create formik based input fields using ant d.

import React from 'react'
import { FieldProps } from 'formik'

//styled component
import { Form, Select } from 'antd'

//Ant d component destructuring
const FormItem = Form.Item
const { Option } = Select

//typescript for form fields
interface formFields {
  prefix: React.ReactNode
  label: string
  hasFeedback?: boolean
  selectOptions: Array<string>
  submitCount: number
  optionValue: string
  optionName: string
  filterPropName?: string
}

//type class
type Class = { new (...args: any[]): any }

//JSX
const CreateAntField = (AntComponent: Class) => ({
  field,
  form,
  hasFeedback,
  label,
  selectOptions,
  submitCount,
  optionName,
  optionValue,
  filterPropName,
  ...props
}: FieldProps<any> & formFields) => {
  const touched = form.touched[field.name]
  const submitted = submitCount > 0
  const hasError = form.errors[field.name]
  const submittedError = hasError && submitted
  const touchedError = hasError && touched

  const onChange = (value: any) => form.setFieldValue(field.name, value)
  const onBlur = () => form.setFieldTouched(field.name, true)

  return (
    <div className="field-container">
      <FormItem
        label={label}
        hasFeedback={
          (hasFeedback && submitted) || (hasFeedback && touched) ? true : false
        }
        help={submittedError || touchedError ? hasError : false}
        validateStatus={submittedError || touchedError ? 'error' : 'success'}
      >
        <AntComponent
          {...field}
          {...props}
          allowClear
          filterOption={true}
          spellCheck={false}
          onBlur={onBlur}
          onChange={onChange}
          {...(filterPropName && { optionFilterProp: filterPropName })}
        >
          {selectOptions &&
            selectOptions.map((item: any, index) => (
              <Option
                key={index}
                value={item[optionValue]}
                data-searchkey={item['name']}
              >
                {item[optionName]}
              </Option>
            ))}
        </AntComponent>
      </FormItem>
    </div>
  )
}

//components
export const AntSelectWithVal = CreateAntField(Select)
