//Query - Brand

import gql from 'graphql-tag'

//get brand by brand id
export const GET_BRAND = gql`
  query($id: UUID) {
    brand(id: $id) {
      id
      name
      description
      primaryBrandContact
      customerSuccessRep
    }
  }
`

//get brands
export const GET_BRANDS = gql`
  query {
    brands {
      id
      name
      description
      primaryBrandContact
      customerSuccessRep
    }
  }
`

export const GET_BRANDS_LIST = gql`
  query {
    brands {
      id
      name
    }
  }
`
