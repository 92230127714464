//This component is used to display error altert, during occurence of an error
//Note - error alert rendered on fetching form details will not be closeable
//close alerts are for create/edit operations

import React from 'react'

//styled component
import { Alert } from 'antd'

interface Props {
  message: string;
  onErrorClose?: () => void;
  close?: boolean;

}

const ErrorAlert = ({ message, onErrorClose,  close = false}: Props) => {
  return (
    <div
    style={{
      position: 'fixed',
      top: '13%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      zIndex: 3,
      maxWidth: '450px'
    }}
  >
      <Alert
        message="Error"
        description={`Error: ${message}`}
        type="error"
        showIcon
        onClose={onErrorClose}
        closable = {close}
      />
    </div>
  )
}

export default ErrorAlert
