//This coponent will display the table content of the feature list

import React, { useContext } from 'react'

//styled components
import { Menu, Dropdown, Icon } from 'antd'

//stylesheet
import '../Reusable_UI/Styles.less'

//constants
import { columnsNames } from '../../Constants/Features/FeaturesList'
import { TypeValue } from '../../Constants/Features/FeatureValueType'

//context
import { EditModalCTX } from '../../Contexts/EditModalContext'

//typeScript
import { FeaturesTableProps, FeatureListTable } from '../../TypeScript_Types/Features'

//functional component
import NoData from '../Reusable_UI/NoData/NoData'

//React FC JSX
const FeaturesListTable = ({
  featuresData,
  tableLoading,
  setEditFeatureId,
  handleArchive,
  archiveLoading,
  archivedId
}: FeaturesTableProps) => {
  //context
  const { handleVisible } = useContext(EditModalCTX)

  // pop up action menu
  const menu = (feature: any) => {
    return (
      <Menu>
        <Menu.Item
          key="1"
          onClick={() => {
            setEditFeatureId(feature.id)
            handleVisible()
          }}
        >
          Edit
        </Menu.Item>
        <Menu.Item
          key="3"
          onClick={() =>
            handleArchive(feature.id, feature.name, feature.archived)
          }
        >
          {feature.archived ? 'Activate' : 'De-activate'}
        </Menu.Item>
      </Menu>
    )
  }

  return (
    <div className="overflow-x-scroll">
      <table className="tableContainer">
        <thead className="tableHead">
          <tr>
            {columnsNames.map((item: any) => (
              <th key={item.key}>{item.title}</th>
            ))}
          </tr>
        </thead>

        {/* table list */}

        <tbody className="tableBody">
          {featuresData &&
            featuresData.map((rowItems: FeatureListTable, rowIndex) => {
              return (
                <tr key={rowIndex}>
                  <td>{rowItems.name}</td>
                  <td>{rowItems.category}</td>
                  <td>{[rowItems.channel].join(', ')}</td>
                  <td>
                    {rowItems.discreteValues.map((item, index) =>
                      index ? ', ' + item.value : item.value
                    )}
                  </td>
                  <td>{TypeValue[rowItems.type]}</td>
                  <td>
                    {rowItems.id === archivedId && archiveLoading ? (
                      <Icon type="loading" />
                    ) : (
                      <Dropdown
                        overlay={() => menu(rowItems)}
                        trigger={['click']}
                      >
                        <Icon type="more" />
                      </Dropdown>
                    )}
                  </td>
                </tr>
              )
            })}
        </tbody>
      </table>

      {/* no data  */}
      {((featuresData && featuresData.length === 0) ||
        (!featuresData && tableLoading)) && <NoData loading={tableLoading} />}
    </div>
  )
}

export default FeaturesListTable
