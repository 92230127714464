//This is the form for create experiment
//The form "Field" component is from the resuable UI components - refer /Reusable_UI

import React, { useState, useEffect } from 'react'
import moment from 'moment'

//form handler
import { Field } from 'formik'

//styled components
import {
  Row,
  Col,
  Button,
  Form as AntForm,
  Popconfirm,
  Input,
  Tooltip,
  Switch
} from 'antd'

//functional components
import {
  AntDatePicker,
  AntTimePicker,
  AntTextarea
} from '../Reusable_UI/Forms/FormFields'
import {
  ToggleListSelect,
  ToggleTreeSelect,
  ToggleSelect
} from '../Reusable_UI/Forms/Toggle'

//utilities
import { validateRequiredArray } from '../../Utilities/Validate'
import { GetFeaturesTree } from '../../Utilities/FeaturesTree'
import { numberWithCommas } from '../../Utilities/Format'
import { accumulateFeatures2 } from '../../Utilities/Experiment'

//constants
import { Format } from '../../Constants/dateTime'

//typescript
import { ExperimentProps } from '../../TypeScript_Types/ExperimentTypes'
import { FeatureTree, FeaturePreview } from '../../TypeScript_Types/Features'

//ant d destructuring
const FormItem = AntForm.Item

const outputPara = {
  margin: '4px 0',
  border: '1px solid #d9d9d9',
  borderRadius: '4px',
  padding: '4px 11px',
  background: '#FFFFFF',
  lineHeight: '22px',
  height: '32px'
}

//JSX - React FC
const CreateEditExperimentForm = ({
  submitCount,
  values,
  buttonName,
  submitLoading,
  submitForm,
  expData,
  setFieldValue,
  nested = false,
  clickToEdit = false,
  totalImpressions,
  totalCost,
  owner = '',
  isChanged = false,
  isEdit = false,
  // initial data is set to true when it has been successfully fetched from API
  // and is ready for render
  initalDataReady,
  setInitialDataReady
}: ExperimentProps): JSX.Element => {
  //local states
  const [featureTreeData, setFeatureTreeData]: [
    Array<FeatureTree>,
    any
  ] = useState([])
  const [selectedFeaturesPreview, setSelectedFeaturePreview]: [
    Array<FeaturePreview>,
    any
  ] = useState([])
  const [channels, setChannels]: [Array<string>, any] = useState([])
  const [mediums, setMediums]: [Array<string>, any] = useState([])
  const [channelsUpdated, setChannelsUpdated] = useState(false)
  const [featureUpdated, setfeatureUpdate] = useState(false)
  const [brandUpdated, setBrandUpdate] = useState(false)
  const [brandNames, setBrandNames]: [Array<string>, any] = useState([])
  const [editSection, setEditSection] = useState('')
  const [showSubmit, setShowSubmit] = useState(false)

  // create feature data
  useEffect(() => {
    if (expData && initalDataReady) {
      //features dropdown options
      const filteredFeatures = expData.features.filter(
        feature => !feature.archived
      )
      const featuresTree = GetFeaturesTree(filteredFeatures)
      setFeatureTreeData(featuresTree)

      //get channels & medium choice
      const channels = expData.channels.map(item => item.name)
      setChannels(channels)

      //set initialData to false
      if (initalDataReady) {
        setInitialDataReady(false)
      }
    }
  }, [expData, initalDataReady, setInitialDataReady])

  //BRANDS - filter selected brand details,
  useEffect(() => {
    if (
      expData &&
      ((values.brands.length > 0 && initalDataReady) || brandUpdated)
    ) {
      // data will in sorted order by id.
      const brandName = expData.brands
        .filter(item => values.brands.includes(item.id))
        .map(item => item.name)

      //reset brand operation flags
      setBrandNames(brandName)
      setBrandUpdate(false)

      if (initalDataReady) {
        setInitialDataReady(false)
      }
    }
  }, [values, expData, brandUpdated, initalDataReady, setInitialDataReady])

  //filter features
  useEffect(() => {
    //FEATURES - create an oject of selected discreteValues with features.
    //only if the values is not undefined and feature has been updated this block will run.
    if (
      expData &&
      ((values.discreteValues.length > 0 && initalDataReady) || featureUpdated)
    ) {
      const customFeatureData = accumulateFeatures2(expData, values)
      setSelectedFeaturePreview(customFeatureData)
      setfeatureUpdate(false)

      if (initalDataReady) {
        setInitialDataReady(false)
      }
    }
  }, [values, expData, featureUpdated, setInitialDataReady, initalDataReady])

  //update experiment name
  useEffect(() => {
    if (expData) {
      const experimentName = [
        values.startDate && moment(values.startDate).format('YYYY-MM'),
        values.brands.length > 3
          ? 'Multiple brands'
          : brandNames.toString().replace(/,/g, '-'),
        values.mediums
          .toString()
          .toLowerCase()
          .replace(/,/g, '-'),
        values.experiment_identifier
      ]
        .filter(Boolean)
        .join('_')
      setFieldValue('name', experimentName)
    }
  }, [values, expData, brandNames, setFieldValue])

  //update mediums - filters unique mediums from the channels
  //note - mediums and channels are being stored in all caps due to backend enums constraint
  useEffect(() => {
    if (
      expData &&
      // ((values.channels.length > 0 && !channelsUpdated) || channelsUpdated)
      ((values.channels.length > 0 && initalDataReady) || channelsUpdated)
    ) {
      const mediumObj = expData.channels.filter(channel =>
        values.channels.includes(channel.name.toUpperCase())
      )
      //grab mediums array and flatten
      const filteredMedium = mediumObj.map(objItem => objItem.mediums).flat()

      // array.from is a workaround due to typescript missing feature only supports iterables on Array.
      const uniqMedium = Array.from(new Set(filteredMedium))
      setMediums(uniqMedium)

      //check for medium changes and update only if channel is updated
      if (channelsUpdated) {
        const filteredValuesMedium = values.mediums.filter(item =>
          uniqMedium.includes(item)
        )
        //update "mediums" to remove the corresponding values of
        setFieldValue('mediums', filteredValuesMedium)
      }

      if (initalDataReady) {
        setInitialDataReady(false)
      }

      setChannelsUpdated(false)
    }
  }, [
    values,
    expData,
    setFieldValue,
    channelsUpdated,
    setInitialDataReady,
    initalDataReady
  ])

  //show submit button check
  useEffect(() => {
    setShowSubmit(() => (nested ? isChanged : true))
  }, [nested, isChanged])

  return (
    <div className="form">
      {/* Experiment name */}
      {!nested && (
        <Tooltip title="Auto-generated name" placement="topLeft">
          <Field
            label="Name"
            name="name"
            readOnly
            allowClear={false}
            submitCount={submitCount}
            component={AntTextarea}
          />
        </Tooltip>
      )}

      {/* brands */}
      <Field name="brands" validate={validateRequiredArray}>
        {({ field, form }: any) => (
          <ToggleListSelect
            name="brands"
            displayValue={brandNames}
            options={expData?.brands}
            optionValue={(o: any) => o.id}
            optionName={(o: any) => o.name}
            setFieldValue={(value: string) => {
              setFieldValue('brands', value)
            }}
            onElBlur={() => form.setFieldTouched(field.name, true)}
            onElChange={() => setBrandUpdate(true)}
            help={
              (values.brands.length === 0 && submitCount > 0) ||
              (form.touched.brands && values.brands.length === 0)
                ? 'Brands not selected.'
                : false
            }
            validateStatus={
              (values.brands.length === 0 && submitCount > 0) ||
              (form.touched.brands && values.brands.length === 0)
                ? 'error'
                : 'success'
            }
            optionFilterProp="title"
            formField={field}
          />
        )}
      </Field>

      {/* Experiement identifier */}
      <Field name="experiment_identifier">
        {({ field }: any) => (
          <FormItem label="Experiment Identifier">
            <Input
              {...field}
              spellCheck={false}
              readOnly={clickToEdit ? editSection !== 'expIdentifier' : false}
              placeholder="Experiment identifier"
              type="text"
              onClick={() =>
                clickToEdit &&
                editSection !== 'expIdentifier' &&
                setEditSection('expIdentifier')
              }
              allowClear={clickToEdit ? editSection === 'expIdentifier' : true}
              onBlur={() =>
                clickToEdit &&
                editSection === 'expIdentifier' &&
                setEditSection('')
              }
              // suffix https://ant.design/components/input/#FAQ
              suffix={<span />}
            />
          </FormItem>
        )}
      </Field>

      <Row gutter={14}>
        {/* Experiment manager */}
        <Col xs={{ span: 6 }}>
          <Field name="Manager" className="field">
            {() => (
              <ToggleSelect
                name="manager"
                displayValue={
                  expData?.dtxcloudUsers?.find(
                    (user: any) => user.id === values.manager
                  )?.username || ''
                }
                selectValue={values.manager}
                fieldValue={(value: string) => value}
                options={expData?.dtxcloudUsers}
                optionValue={(o: { id: string }) => o.id}
                optionName={(o: { username: string }) => o.username}
                setValue={(value: any) => setFieldValue('manager', value)}
              />
            )}
          </Field>
        </Col>

        {/* owner */}
        {nested && (
          <Col xs={{ span: 6 }}>
            <FormItem label="Owner">
              <p style={outputPara}>{owner}</p>
            </FormItem>
          </Col>
        )}

        <Col xs={{ span: 6 }}>
          {/* isCac */}
          <FormItem label="CAC">
            <Switch
              checked={values.isCac}
              onChange={() => setFieldValue('isCac', !values.isCac)}
            />
          </FormItem>
        </Col>
      </Row>

      <Row gutter={14} style={{ minHeight: '147px' }}>
        <Col span={6}>
          {/* start date */}
          <Field
            label="Start date"
            name="startDate"
            format={Format.dateFormat}
            component={AntDatePicker}
            validate={(value: string) =>
              !value ? 'Start date not selected' : undefined
            }
          />
        </Col>

        <Col span={6}>
          {/* start time */}
          <Field
            label="Start time"
            name="startTime"
            id="startTime"
            extra={
              values && !values.startDate ? 'Select start date to enable' : ''
            }
            format={Format.timeFormat}
            disabled={!values.startDate}
            component={AntTimePicker}
          />
        </Col>

        <Col span={6}>
          {/* end date */}
          <Field
            label="End date"
            name="endDate"
            id="endTime"
            format={Format.dateFormat}
            component={AntDatePicker}
          />
        </Col>

        <Col span={6}>
          {/* end time */}
          <Field
            label="End time"
            name="endTime"
            extra={values && !values.endDate ? 'Select end date to enable' : ''}
            disabled={!values.endDate}
            format={Format.timeFormat}
            component={AntTimePicker}
          />
        </Col>
      </Row>
      {/* total impression and view only for View/Edit experiment */}
      {nested && (
        <Row gutter={[14, 14]}>
          {/* total impression */}
          <Col span={12}>
            <FormItem label="Total Impressions">
              <p style={outputPara}>
                {numberWithCommas(totalImpressions || 0)}
              </p>
            </FormItem>
          </Col>

          {/* total cost */}
          <Col span={12}>
            <FormItem label="Total cost">
              <p style={outputPara}>$ {totalCost}</p>
            </FormItem>
          </Col>
        </Row>
      )}

      <Row gutter={[14, 14]}>
        <Col span={12}>
          {/* channel */}
          <Field
            name="channels"
            validate={validateRequiredArray}
            className="field"
          >
            {({ field, form }: any) => (
              <ToggleListSelect
                name="channels"
                displayValue={values.channels}
                options={channels}
                optionValue={(o: string) => o.toUpperCase()}
                optionName={(o: string) => o}
                setFieldValue={(value: string) =>
                  setFieldValue('channels', value)
                }
                onElBlur={() => form.setFieldTouched(field.name, true)}
                onElChange={() => setChannelsUpdated(true)}
                formField={field}
              />
            )}
          </Field>
        </Col>

        <Col span={12}>
          {/* medium */}
          <Field
            name="mediums"
            validate={validateRequiredArray}
            className="field"
          >
            {({ field, form }: any) => (
              <ToggleListSelect
                name="mediums"
                displayValue={values.mediums}
                options={mediums}
                optionValue={(o: string) => o.toUpperCase()}
                optionName={(o: string) => o}
                setFieldValue={(value: string) =>
                  setFieldValue('mediums', value)
                }
                onElBlur={() => form.setFieldTouched(field.name, true)}
                formField={field}
                help={
                  (values.mediums.length === 0 && submitCount > 0) ||
                  (form.touched.mediums && values.mediums.length === 0)
                    ? 'Mediums not selected'
                    : false
                }
                validateStatus={
                  (values.mediums.length === 0 && submitCount > 0) ||
                  (form.touched.mediums && values.mediums.length === 0)
                    ? 'error'
                    : 'success'
                }
                disableSelect={values && values.channels.length === 0}
              />
            )}
          </Field>
        </Col>
      </Row>

      {/* selected features panel */}
      <Row gutter={[14, 14]}>
        <Col>
          <Field
            name="discreteValues"
            validate={(value: string) => validateRequiredArray(value, !!isEdit)}
          >
            {({ field, form }: any) => {
              return (
                <ToggleTreeSelect
                  name="discreteValues"
                  label="Features"
                  selectValue={values.discreteValues}
                  treeData={featureTreeData}
                  formField={field}
                  filterProp="title"
                  selectedItems={selectedFeaturesPreview}
                  setFieldValue={(value: any) =>
                    setFieldValue('discreteValues', value)
                  }
                  onElChange={() => setfeatureUpdate(true)}
                  help={
                    (values.discreteValues.length === 0 && submitCount > 0) ||
                    (form.touched.discreteValues &&
                      values.discreteValues.length === 0)
                      ? 'Feature names not selected'
                      : false
                  }
                  validateStatus={
                    (values.discreteValues.length === 0 && submitCount > 0) ||
                    (form.touched.discreteValues &&
                      values.discreteValues.length === 0)
                      ? 'error'
                      : 'success'
                  }
                />
              )
            }}
          </Field>
        </Col>
      </Row>

      {/* submit button */}
      {showSubmit && (
        <Row>
          <Col span={24} style={{ textAlign: 'right' }}>
            <Popconfirm
              title="Are you sure？"
              okText="Yes"
              cancelText="No"
              onConfirm={submitForm}
            >
              <Button type="primary" loading={submitLoading}>
                {buttonName}
              </Button>
            </Popconfirm>
          </Col>
        </Row>
      )}
    </div>
  )
}

export default CreateEditExperimentForm
