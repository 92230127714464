//Container - edit folder
import React, { Fragment, useContext, useRef, useEffect, useState } from 'react'
import { Formik } from 'formik'
import { useMutation, useQuery } from '@apollo/react-hooks'

//graphql
import { EDIT_FOLDER } from '../../Graqhql/Folders/FolderMutations'
import { GET_FOLDER, GET_FOLDERS } from '../../Graqhql/Folders/FoldersQuery'

//context
import { EditFolderModalCTX } from '../../Contexts/EditFolderModalContext'

//typescript
import { FoldersFormValues } from '../../TypeScript_Types/Folders'

//utilities
import ErrorBoundary from '../../Utilities/ErrorBoundary'

//functional components
import EditFolderForm from '../../Components/Folders/Create_Edit_FoldersForm'
import ModalWrap from '../../Components/Reusable_UI/Modal/ModalFormWrap'
import ErrorAlert from '../../Components/Reusable_UI/Alerts/ErrorAlerts'
import { LoadingSpinner } from '../../Components/Reusable_UI/Loaders_Spinners/LoadingSpinner'
import SuccessAlert from '../../Components/Reusable_UI/Alerts/SuccessAlerts'

//typescript
interface Props {
  editFolderId: string
  experimentId: string
}

//const modal buttons
const buttons = [
  {
    buttonName: 'Edit',
    key: 'edit'
  }
]

//JSX
const EditFolder: React.FC<Props> = ({ editFolderId, experimentId }) => {
  //localStates
  const [getFolderError, setGetFolderError] = useState()

  const { data: getFolder, loading: getFolderLoading } = useQuery(GET_FOLDER, {
    variables: { id: editFolderId },
    onError(error) {
      setGetFolderError(error)
    }
  })

  //context
  const {
    editFolderFormModal,
    handleVisible,
    editFolderSuccess,
    setEditFolderSuccess,
    editFolderError,
    setEditFolderError,
    editFolderErrorMsg,
    setEditFolderErrorMsg
  } = useContext(EditFolderModalCTX)

  //ref
  const resetRef: any = useRef(null)

  //useEffect - resetForm do not move calling ref to handleVisible function it will change the transform origin of the modal
  useEffect(() => {
    resetRef.current && resetRef.current.click()
    // reset get folder error
    setGetFolderError(null)
  }, [editFolderFormModal])

  //graphql mutation
  const [editFolder, { loading: editLoading }] = useMutation(EDIT_FOLDER, {
    onCompleted() {
      setEditFolderSuccess(true)
      handleVisible()
    },
    onError(error) {
      setEditFolderErrorMsg(error.message)
      setEditFolderError(true)
    },
    refetchQueries: [
      { query: GET_FOLDERS, variables: { experimentId: experimentId } }
    ]
  })

  //call mutation and submit form
  const handleSubmit = (values: FoldersFormValues) => {
    //mutation input arg
    let input = {
      name: values.name,
      description: values.description
    }
    //mutation id arg
    let id = editFolderId
    editFolder({ variables: { id, input } })
  }

  return (
    <Fragment>
      {/* success alert */}
      {editFolderSuccess ? (
        <SuccessAlert
          message="Folder updated successfully."
          closable={true}
          title="Edit folder"
        />
      ) : null}

      {/* create experiment button */}
      <Formik
        enableReinitialize
        initialValues={getFolder ? getFolder.folder : {}}
        onSubmit={handleSubmit}
        validateOnBlur={false}
      >
        {({ submitCount, submitForm, handleReset }) => (
          <ModalWrap
            title="Edit Folder"
            buttons={buttons}
            submitForm={submitForm}
            loading={editLoading}
            handleVisible={handleVisible}
            viewFormModal={editFolderFormModal}
          >
            {/* error alert mutation request, NOTE - success is handled in the parent component */}
            {editFolderError && (
              <ErrorAlert
                message={
                  editFolderErrorMsg
                    ? editFolderErrorMsg
                    : 'Something went wrong'
                }
                close={true}
                onErrorClose={() => {
                  setEditFolderError(false)
                  setEditFolderErrorMsg('')
                }}
              />
            )}

            {/* get folder details error */}
            {getFolderError ? (
              <ErrorAlert
                message={
                  getFolderError.message
                    ? getFolderError.message
                    : 'Something went wrong'
                }
              />
            ) : (
              <Fragment>
                {/* display css loader while the folder details are getting downloaded, add edit data data */}
                {getFolderLoading ? (
                  <LoadingSpinner />
                ) : (
                  <ErrorBoundary>
                    <EditFolderForm submitCount={submitCount} />
                  </ErrorBoundary>
                )}
              </Fragment>
            )}
            {/* reset form div red */}
            <div onClick={handleReset} ref={resetRef} />
          </ModalWrap>
        )}
      </Formik>
    </Fragment>
  )
}

export default EditFolder
