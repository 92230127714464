//This container will fetch the folders

//NOTE- temporarily get all folders query is used, when fetch by experiment id is available, replace.

import React, { useState, useContext, Fragment } from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'

//ant d
import { Tooltip, Button, Modal } from 'antd'

//graphql query
import { GET_FOLDERS } from '../../Graqhql/Folders/FoldersQuery'
import { DELETE_FOLDER } from '../../Graqhql/Folders/FolderMutations'

//context
import { CreateFolderModalCTX } from '../../Contexts/CreateFolderModalContext'
import { EditFolderModalCTX } from '../../Contexts/EditFolderModalContext'

//utilities
import ErrorBoundary from '../../Utilities/ErrorBoundary'

//functional components
import SuccessAlert from '../../Components/Reusable_UI/Alerts/SuccessAlerts'
import CreateFolder from '../Folders/CreateFolder'
import FoldersListTable from '../../Components/FoldersList/FoldersListTable'
import EditFolder from './EditFolder'
import ErrorAlert from '../../Components/Reusable_UI/Alerts/ErrorAlerts'

//ant design destructuring
const { confirm } = Modal

//types
interface Props {
  experimentId: string
}

//JSX
const FolderList: React.FC<Props> = ({ experimentId }) => {
  //context
  const {
    createFolderSuccess,
    handleVisible,
    createFolderFormModal
  } = useContext(CreateFolderModalCTX)
  const { editFolderFormModal, editFolderSuccess } = useContext(
    EditFolderModalCTX
  )

  //local states
  const [foldersListError, setFoldersListError] = useState()
  const [editFolderId, setEditFolderId] = useState()
  const [deleteFolderId, setDeleteFolderId] = useState()
  const [deleteFolderError, setDeleteFolderError] = useState()

  //get experiments data from graphql
  const { data: folderListData, loading: listLoading } = useQuery(GET_FOLDERS, {
    variables: { experimentId: experimentId },
    onError(error) {
      setFoldersListError(error)
    }
  })

  //delete folder mutation
  const [deleteFolder, { loading: deleteLoading }] = useMutation(
    DELETE_FOLDER,
    {
      onCompleted() {
        setDeleteFolderId(null)
      },
      onError(error) {
        setDeleteFolderError(error)
      },
      refetchQueries: [
        { query: GET_FOLDERS, variables: { experimentId: experimentId } }
      ]
    }
  )

  // delete dolder
  const handleDeleteFolder = (id: string, name: string) => {
    setDeleteFolderError(null)
    setDeleteFolderId(id)

    confirm({
      title: 'Delete folder',
      content: `Are you sure you want to delete ${name}?`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deleteFolder({ variables: { id: id } })
      }
    })
  }

  //on close delete error alert
  const onCloseDeleteError = () => {
    setDeleteFolderError(null)
    setDeleteFolderId(null)
  }

  return (
    <div>
      {/* display folder action outcomes */}
      {createFolderSuccess || editFolderSuccess ? (
        <SuccessAlert
          message={
            createFolderSuccess
              ? 'Folder created successfully.'
              : 'Folder updated successfully.'
          }
          closable={true}
          title={createFolderSuccess ? 'Create folder' : 'Edit folder'}
        />
      ) : null}

      <div style={{ textAlign: 'right' }}>
        <Tooltip title="Create new folder" placement="topRight">
          <Button
            shape="circle"
            icon="plus"
            type="primary"
            onClick={handleVisible}
          />
        </Tooltip>
      </div>
      {/* create folder button, wrapped with context*/}
      {createFolderFormModal && <CreateFolder experimentId={experimentId} />}

      {/* display error received while fetching the table data */}
      {foldersListError ? (
        <ErrorAlert
          message={
            foldersListError.message
              ? foldersListError.message
              : 'Error fetching data.'
          }
        />
      ) : (
        <Fragment>
          {/* delete folder error */}
          {deleteFolderError && (
            <ErrorAlert
              message={
                deleteFolderError.message
                  ? deleteFolderError.message
                  : 'Something went wrong.'
              }
              onErrorClose={onCloseDeleteError}
              close={true}
            />
          )}

          {/* created folders table listing */}
          <ErrorBoundary>
            <FoldersListTable
              folderListData={folderListData}
              listLoading={listLoading}
              setEditFolderId={setEditFolderId}
              deleteLoading={deleteLoading}
              deleteFolderId={deleteFolderId}
              handleDeleteFolder={handleDeleteFolder}
            />
          </ErrorBoundary>

          {/* edit folder pop up */}
          {editFolderFormModal && (
            <EditFolder
              editFolderId={editFolderId}
              experimentId={experimentId}
            />
          )}
        </Fragment>
      )}
    </div>
  )
}

export default FolderList
