//container - brands list
//This is the parent container for wrapping the components of the brands in the edit and create context provider

import React, { useState, useEffect, Fragment, useContext } from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { Link } from 'react-router-dom'

//ant d
import { Tooltip, Button, Modal, Breadcrumb } from 'antd'

//graphql query
import { GET_BRANDS } from '../../Graqhql/Brands/BrandQuery'
import { DELETE_BRAND } from '../../Graqhql/Brands/BrandsMutations'

//context
import { EditModalCTX } from '../../Contexts/EditModalContext'
import { CreateModalCTX } from '../../Contexts/CreateModalContext'

//functional components
import LayoutWrap from '../../HOC/LayoutWrap'
import { SearchField } from '../../Components/Reusable_UI/Forms/SearchField'
import PageTitle from '../../Components/Reusable_UI/Headers_Titles/PageTitles'
import BrandsListTable from '../../Components/BrandsList/BrandsListTable'
import CreateBrand from '../Brands/CreateBrands'
import EditBrand from '../Brands/EditBrands'
import SuccessAlert from '../../Components/Reusable_UI/Alerts/SuccessAlerts'
import ErrorAlert from '../../Components/Reusable_UI/Alerts/ErrorAlerts'
import ErrorBoundary from '../../Utilities/ErrorBoundary'

//ant component destructring
const { confirm } = Modal

//inline styles
const titleDiv = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
}

const searchAddDiv = {
  display: 'flex',
  justifyContent: 'space-between',
  flexBasis: '21%'
}

//const title of login page
const PAGE_TITLE = 'Brands'

//Breadcrumb nav
const BrandBreadcrumb = () => {
  return (
    <Breadcrumb style={{ marginBottom: '20px' }}>
      <Breadcrumb.Item>
        <Link to="create_experiment">Create experiment</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>Brands</Breadcrumb.Item>
    </Breadcrumb>
  )
}

//React FC JSX
const BrandsList: React.FC<any> = props => {
  //get brands data from graphql
  const { data, loading, error } = useQuery(GET_BRANDS)

  //local state
  const [brandData, setBrandData] = useState([])
  const [editBrandId, setEditBrandId] = useState('')
  const [deleteId, setDeleteId] = useState()
  const [deleteError, setDeleteError] = useState()

  //context
  const { viewEditFormModal, editSuccess } = useContext(EditModalCTX)
  const { createSuccess, handleVisible, viewCreateFormModal } = useContext(
    CreateModalCTX
  )

  //update the local state, this will be used in search function.
  useEffect(() => {
    if (data) {
      setBrandData(data.brands)
    }
  }, [data])

  //SEARCH FILTER - search brand name from the list
  const handleSearch = (value: string) => {
    if (!value) {
      setBrandData(data.brands)
    } else {
      const filteredItem = data.brands.filter((brand: any) =>
        brand.name.includes(value)
      )
      setBrandData(filteredItem)
    }
  }

  //delete brand mutation
  const [deleteBrand, { loading: deleteLoading }] = useMutation(DELETE_BRAND, {
    onCompleted() {
      setDeleteId(null)
    },
    onError(error) {
      setDeleteError(error)
    },
    refetchQueries: [{ query: GET_BRANDS }]
  })

  //call delete brand mutations
  const handleBrandDelete = (brandId: string, brandName: string) => {
    setDeleteId(brandId)

    confirm({
      title: 'Delete brand',
      content: `Are you sure you want to delete ${brandName}?`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deleteBrand({ variables: { id: brandId } })
      }
    })
  }

  // close delete error popup
  const onCloseDeleteError = () => {
    setDeleteError(null)
    setDeleteId(null)
  }

  return (
    <LayoutWrap props={props}>
      {/* breadcrum navigation */}
      <BrandBreadcrumb />

      {/* title and search bar div */}
      <div style={titleDiv}>
        <PageTitle titleName={PAGE_TITLE} />

        <div style={searchAddDiv}>
          <SearchField
            handleSearch={handleSearch}
            placeholderVal="search brand"
            disabled={!brandData}
          />
          {/* create brand button */}
          <Tooltip title="Create new brand" placement="topRight">
            <Button
              shape="circle"
              icon="plus"
              type="primary"
              onClick={handleVisible}
            />
          </Tooltip>

          {/* create brand */}
          {viewCreateFormModal && <CreateBrand />}
        </div>
      </div>

      {createSuccess || editSuccess ? (
        <SuccessAlert
          message={
            createSuccess
              ? 'Brand created successfully.'
              : 'Brand updated successfully.'
          }
          closable={true}
          title={createSuccess ? 'Create brand' : 'Edit brand'}
        />
      ) : null}

      {/* error getting brand details */}
      {error ? (
        <ErrorAlert
          message={
            error.message ? error.message : 'Error fetching brand details.'
          }
        />
      ) : (
        <Fragment>
          {/* delete brand error */}
          {deleteError && (
            <ErrorAlert
              message={
                deleteError ? deleteError.message : 'Error deleting brands.'
              }
              close={true}
              onErrorClose={onCloseDeleteError}
            />
          )}

          {/* table content */}
          <ErrorBoundary>
            <BrandsListTable
              brandData={brandData}
              listLoading={loading}
              deleteLoading={deleteLoading}
              deleteId={deleteId}
              handleBrandDelete={handleBrandDelete}
              setEditBrandId={setEditBrandId}
            />
          </ErrorBoundary>

          {/* edit brand pop up */}
          {viewEditFormModal && <EditBrand editBrandId={editBrandId} />}
        </Fragment>
      )}
    </LayoutWrap>
  )
}

export default BrandsList
