//Register Form - This component will render the register form -> /register.
//The form "Field" component is from the resuable UI components - refer /Reusable_UI

import React from 'react'
import { Link } from 'react-router-dom'

//styled components
import { Form, Field } from 'formik'

//styled icons
import { Icon, Button, Form as AntForm } from 'antd'

//functional components
import { AntInputPassword, AntInput } from '../Reusable_UI/Forms/FormFields'

//utilities
import {
  validateLength,
  validateRequired,
  validateEmail
} from '../../Utilities/Validate'

//styles
import '../Reusable_UI/Styles.less'

//Antd components
const FormItem = AntForm.Item

//types for props from parent container Login.tsx
interface Props {
  loading: boolean
  submitCount: number
  values: any
}

const RegisterForm = ({ loading, submitCount, values }: Props) => {
  return (
    <Form>
      <Field
        label="Username"
        name="username"
        type="text"
        validate={validateRequired}
        hasFeedback
        submitCount={submitCount}
        prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
        component={AntInput}
      />

      <Field
        label="Email"
        name="email"
        type="email"
        validate={validateEmail}
        hasFeedback
        submitCount={submitCount}
        prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
        component={AntInput}
      />

      <Field
        label="Password"
        name="password"
        type="text"
        validate={validateLength}
        submitCount={submitCount}
        prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
        component={AntInputPassword}
      />

      <FormItem>
        <Button
          type="primary"
          htmlType="submit"
          disabled={loading}
          loading={loading}
          className="loginButton"
        >
          SIGN UP
        </Button>
        Already a User? <Link to="/signup">Sign In!</Link>
      </FormItem>
    </Form>
  )
}

export default RegisterForm
