import React, { useRef, useState } from 'react'
import { Form, Input, Select } from 'antd'
import ToggleWrapper from './ToggleWrapper'

const { Item } = Form
const { Option } = Select

interface SelectProps {
  name: string
  displayValue: string
  selectValue: string
  fieldValue: (v: string) => any
  options: any[]
  optionValue: (v: any) => string
  optionName: (v: any) => string
  setValue: (value: string) => void
  mode?: string

  //disable select is used in view permutation, once the final number is set to true
  //further editing must not be allowed
  disableSelect?: boolean
}

const ToggleSelect = ({
  name,
  displayValue,
  selectValue,
  fieldValue,
  options,
  optionValue,
  optionName,
  setValue,
  mode,
  disableSelect = false
}: SelectProps): JSX.Element => {
  //ref - to set focus for the editable element
  const ref = useRef()

  //local state
  const [isEdit, setIsEdit] = useState(false)

  return (
    <Item label={name[0].toUpperCase() + name.slice(1)}>
      <ToggleWrapper
        editable={isEdit}
        elRef={ref}
        autoFocus
        staticEl={
          <div
            onClick={() => {
              !disableSelect && setIsEdit(true)
            }}
          >
            <Input
              value={displayValue}
              disabled
              placeholder={name}
              className={
                disableSelect
                  ? 'hide-disabled d-pointer'
                  : 'hide-disabled c-pointer'
              }
            />
          </div>
        }
        editableEl={
          <Select
            {...mode}
            placeholder={'Select ' + name}
            value={selectValue}
            onChange={(value: string) => setValue(fieldValue(value))}
            onBlur={() => setIsEdit(false)}
            ref={(node: any) => (ref.current = node)}
          >
            {options &&
              options.map((o: any) => (
                <Option value={optionValue(o)} key={optionValue(o)}>
                  {optionName(o)}
                </Option>
              ))}
          </Select>
        }
      />
    </Item>
  )
}

export default ToggleSelect
