//Reducer to store the state of side nav across pages

import * as actions from './ActionTypes'

export const toggleSideNav = (state: any, action: any) => {
  switch (action.type) {
    case actions.TOGGLE_SIDENAV:
      return {
        collapsed: action.collapsed
      }
    default:
      return state
  }
}
