// mutations - Experiments

import gql from 'graphql-tag'

//create experiment
export const CREATE_EXPERIMENT = gql`
  mutation($input: ExperimentInputType, $discreteValues: [UUID!]) {
    createExperiment(input: $input, discreteValues: $discreteValues) {
      experiment {
        id
      }
    }
  }
`

//update experiment
export const UPDATE_EXPERIMENT = gql`
  mutation(
    $id: UUID!
    $input: ExperimentInputType
    $addBrands: [UUID]
    $deleteBrands: [UUID]
    $addDiscreteValues: [UUID]
    $deleteDiscreteValues: [UUID]
  ) {
    updateExperiment(
      id: $id
      input: $input
      addBrands: $addBrands
      deleteBrands: $deleteBrands
      addDiscreteValues: $addDiscreteValues
      deleteDiscreteValues: $deleteDiscreteValues
    ) {
      ok
    }
  }
`

// mutation to delete experiment
export const DELETE_EXPERIMENT = gql`
  mutation($id: UUID!) {
    deleteExperiment(id: $id) {
      ok
    }
  }
`
