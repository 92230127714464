//This component is used to display success alert, after a request is successful

import React from 'react'

//styled component
import { Alert } from 'antd'

interface Props {
  message: string
  title: string
  closable: boolean
}

const SuccessAlert = ({ message, closable, title }: Props) => {
  return (
    <div
      style={{
        position: 'fixed',
        top: '13%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 3
      }}
    >
      <Alert
        message={title}
        description={`Success: ${message}`}
        type="success"
        closable={closable}
        showIcon
      />
    </div>
  )
}

export default SuccessAlert
