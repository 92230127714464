// This is the context to handle interaction & states between the container/component buttons and modal of Folder module.
// EditFolder modal is used by the table component and the 'EditFolder' component to manage the modal state.

import React, { createContext, useState, useEffect } from 'react'

//create context
export const EditFolderModalCTX: any = createContext({})

const EditFolderModalProvider: React.FC<any> = props => {
  const [editFolderSuccess, setEditFolderSuccess] = useState(false)
  const [editFolderError, setEditFolderError] = useState(false)
  const [editFolderErrorMsg, setEditFolderErrorMsg] = useState('')
  //show or hide state of modal
  const [editFolderFormModal, setEditFolderFormModal] = useState(false)

  // mount timer and unmount cleanup - set timer for 2secs and remove timer when the component is unmounted
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (editFolderSuccess) {
        setEditFolderSuccess(false)
      }
    }, 2000)

    return () => {
      // clear timeout
      return clearTimeout(timeout)
    }
  }, [editFolderSuccess])

  const handleVisible = () => {
    if (editFolderFormModal) {
      setEditFolderError(false)
      setEditFolderErrorMsg('')
    }
    setEditFolderFormModal(prevState => !prevState)
  }
  return (
    <EditFolderModalCTX.Provider
      value={{
        editFolderFormModal,
        handleVisible,
        editFolderSuccess,
        setEditFolderSuccess,
        editFolderError,
        setEditFolderError,
        editFolderErrorMsg,
        setEditFolderErrorMsg
      }}
    >
      {props.children}
    </EditFolderModalCTX.Provider>
  )
}

export default EditFolderModalProvider
