//This is the context to handle the modal interaction & states between a container/component buttons and modal.
//Create modal is used by table/list component and a "create" modal component.

//note - context cannot be used in two or more differnt components from the same form/page

import React, { createContext, useState, useEffect } from 'react'

//create context
export const CreateModalCTX: any = createContext({})

const CreateModalProvider: React.FC<any> = props => {
  const [createSuccess, setCreateSuccess] = useState(false)
  const [createError, setCreateError] = useState(false)
  const [createErrorMsg, setCreateErrorMsg] = useState('')
  //show or hide state of modal
  const [viewCreateFormModal, setCreateViewFormModal] = useState(false)

  // mount timer and unmount cleanup - set timer for 2secs and remove timer when the component is unmounted
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (createSuccess) {
        setCreateSuccess(false)
      }
    }, 2000)

    return () => {
      // clear timeout
      return clearTimeout(timeout)
    }
  }, [createSuccess])

  const handleVisible = () => {
    if (viewCreateFormModal) {
      setCreateError(false)
      setCreateErrorMsg('')
    }
    setCreateViewFormModal(prevState => !prevState)
  }
  return (
    <CreateModalCTX.Provider
      value={{
        viewCreateFormModal,
        handleVisible,
        createSuccess,
        setCreateSuccess,
        createError,
        setCreateError,
        createErrorMsg,
        setCreateErrorMsg
      }}
    >
      {props.children}
    </CreateModalCTX.Provider>
  )
}

export default CreateModalProvider
