//Container - create permutation

import React, { useState, Fragment, useEffect, useRef, useContext } from 'react'
import { Formik } from 'formik'
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks'

//contexts
import { CreateModalCTX } from '../../Contexts/CreateModalContext'

//typeScript
import { PermutationFormValues } from '../../TypeScript_Types/Permutations'

//graphql
import {
  GET_PERMUTATIONS_OPTIONS,
  GET_PERMUTATIONS_LIST,
  GET_EDIT_PERMUTATION
} from '../../Graqhql/Permutations/QueryPermutation'
import { CREATE_PERMUTATION } from '../../Graqhql/Permutations/MutationPermutation'
import { GET_FOLDERS } from '../../Graqhql/Folders/FoldersQuery'

//utilities
import { removeCommas } from '../../Utilities/Format'
import { formatPermutationData } from '../../Utilities/Permutation'
import ErrorBoundary from '../../Utilities/ErrorBoundary'

//functional component
import CreatePermutationForm from '../../Components/Permutations/Create_Edit_Permutation'
import ModalWrap from '../../Components/Reusable_UI/Modal/ModalFormWrap'
import { LoadingSpinner } from '../../Components/Reusable_UI/Loaders_Spinners/LoadingSpinner'
import ErrorAlert from '../../Components/Reusable_UI/Alerts/ErrorAlerts'

//initial form fields
const initialFieldValues: PermutationFormValues = {
  name: '',
  brands: [],
  channel: '',
  medium: '',
  iscs: '',
  folder: null,
  estCost: null,
  actualCost: null,
  estImpressions: null,
  actualImpressions: null,
  variables: [],
  attributes: [],
  notes: ''
}

//type props
interface Props {
  experimentId: string
  clonePermutationId?: string
  setClonePermutationId: Function
}

//JSX
const CreatePermutation = ({
  experimentId,
  clonePermutationId,
  setClonePermutationId
}: Props): JSX.Element => {
  //local state
  const [createAndClone, setCreateAndClone] = useState(false)
  const [
    clonePermutationDataLoading,
    setClonePermutationDataLoading
  ] = useState(false)
  const [
    createPermutationInitialValues,
    setCreatePermutationInitialValues
  ] = useState<PermutationFormValues>()
  //error while fetching permutations form options
  const [permutationOptionsError, setPermutationOptionsError] = useState()
  const [initalDataReady, setInitialDataReady] = useState(false)
  //Error while fetching permutation details for cloning
  const [permutationCloneError, setPermutationCloneError] = useState()

  //context
  const {
    viewCreateFormModal,
    handleVisible,
    setCreateSuccess,
    createError,
    setCreateError,
    createErrorMsg,
    setCreateErrorMsg
  } = useContext(CreateModalCTX)

  //ref to call reset form
  const resetRef: any = useRef(null)

  useEffect(() => {
    if (viewCreateFormModal) {
      resetRef.current && resetRef.current.click()
    }
  }, [viewCreateFormModal])

  //this is to set the toggle for create and clone
  const handleCreateAndClone = (): void => {
    setCreateAndClone(prevState => !prevState)
  }

  //this will be called when create button is clicked, if previous createAndClone is true
  //set it to false
  const handleCreate = (): void => {
    if (createAndClone) {
      handleCreateAndClone()
    }
  }

  //close modal will check if create and clone is true, if true then
  //it must be set to false so that the form gets reset.
  const closeModal = (): void => {
    if (createAndClone) {
      handleCreateAndClone()
    }
    if (createPermutationInitialValues)
      setCreatePermutationInitialValues(undefined)
    if (clonePermutationId) setClonePermutationId(null)
    handleVisible()
  }

  //const modal buttons
  const buttons = [
    {
      buttonName: 'Create',
      key: 'create',
      action: handleCreate
    },
    {
      buttonName: 'Create & clone',
      key: 'createClone',
      action: handleCreateAndClone
    }
  ]

  //get permutation options
  const { data: permutationDataOpt, loading: permutationLoading } = useQuery(
    GET_PERMUTATIONS_OPTIONS,
    {
      variables: { id: experimentId },
      onError(error) {
        setPermutationOptionsError(error)
      }
    }
  )

  //set initial data ready for render
  useEffect(() => {
    if (permutationDataOpt) {
      setInitialDataReady(true)
    }
  }, [permutationDataOpt])

  //create permutation mutation
  const [createPermutation, { loading: createLoading }] = useMutation(
    CREATE_PERMUTATION,
    {
      onCompleted() {
        //if create and clone is false, close the modal and set success to true.
        if (!createAndClone) {
          setCreateSuccess(true)
          handleVisible()
        }
      },
      onError(error) {
        setCreateError(true)
        setCreateErrorMsg(error.message)
      },
      refetchQueries: [
        {
          query: GET_PERMUTATIONS_LIST,
          variables: { experimentId: experimentId }
        },
        { query: GET_FOLDERS, variables: { experimentId: experimentId } }
      ]
    }
  )

  //handle submit
  const handleSubmit = (values: PermutationFormValues): void => {
    const inputVal = {
      name: values.name,
      estCost: values.estCost ? parseFloat(removeCommas(values.estCost)) : null,
      actualCost: values.actualCost
        ? parseFloat(removeCommas(values.actualCost))
        : null,
      estImpressions: values.estImpressions
        ? parseFloat(removeCommas(values.estImpressions))
        : null,
      actualImpressions: values.actualImpressions
        ? parseFloat(removeCommas(values.actualImpressions))
        : null,
      iscs: values.iscs,
      notes: values.notes,
      channel: values.channel,
      medium: values.medium
    }

    const newVariables = values.variables.map(
      (v: string) =>
        permutationDataOpt?.experiment.experimentDiscreteSet.find(
          (d: any) => d.discreteValue.id === v
        )?.id
    )

    createPermutation({
      variables: {
        experimentId: experimentId,
        input: inputVal,
        brands: values.brands,
        attributes: values.attributes,
        folder: values.folder,
        variables: newVariables
      }
    })
  }

  //CLONE PERMUTATION
  //useLazyQuery returns a function in its result tuple that you can call whenever you're ready to execute.
  //The below query is used to get the permutation data for cloning
  const [
    getClonePermutationData,
    { loading: clonePermutationDataFetchLoading }
  ] = useLazyQuery(GET_EDIT_PERMUTATION, {
    onError(error) {
      // TODO: create custom error function
      setClonePermutationDataLoading(false)
      setPermutationCloneError(error)
    },
    onCompleted(data) {
      const { permutation } = data
      //set the proper value for folder select
      permutation.folder = permutation.folder?.id
      const clonePermutationData = formatPermutationData(permutation)
      setCreatePermutationInitialValues(clonePermutationData)
      setClonePermutationDataLoading(false)
    }
  })

  // clone permutation data
  useEffect(() => {
    if (clonePermutationId) {
      setClonePermutationDataLoading(true)
      getClonePermutationData({
        variables: {
          experimentId: experimentId,
          permutationID: clonePermutationId
        }
      })
    }
  }, [clonePermutationId, getClonePermutationData, experimentId])

  return (
    <Fragment>
      <Formik
        initialValues={initialFieldValues}
        onSubmit={handleSubmit}
        validateOnBlur={false}
      >
        {({ submitCount, submitForm, setFieldValue, values, handleReset }) => (
          <ModalWrap
            title="Create Permutation"
            buttons={buttons}
            submitForm={submitForm}
            loading={createLoading}
            handleVisible={closeModal}
            viewFormModal={viewCreateFormModal}
          >
            {/* error fetching permutation options */}
            {permutationOptionsError || permutationCloneError ? (
              <ErrorAlert message="Error fetching permutation details." />
            ) : (
              <>
                {permutationLoading ||
                permutationOptionsError ||
                clonePermutationDataLoading ||
                clonePermutationDataFetchLoading ? (
                  <LoadingSpinner />
                ) : (
                  <Fragment>
                    {/* error creating permutation  */}
                    {createError && (
                      <ErrorAlert
                        message={
                          createErrorMsg
                            ? createErrorMsg
                            : 'Something went wrong'
                        }
                        close={true}
                        onErrorClose={() => {
                          setCreateErrorMsg('')
                          setCreateError(false)
                        }}
                      />
                    )}
                    <ErrorBoundary>
                      <CreatePermutationForm
                        setInitialDataReady={setInitialDataReady}
                        initalDataReady={initalDataReady}
                        submitCount={submitCount}
                        setFieldValue={setFieldValue}
                        permutationData={permutationDataOpt}
                        values={values}
                        initialValues={createPermutationInitialValues}
                      />
                    </ErrorBoundary>
                  </Fragment>
                )}
              </>
            )}

            {/* reset form div ref */}
            <div onClick={handleReset} ref={resetRef} />
          </ModalWrap>
        )}
      </Formik>
    </Fragment>
  )
}

export default CreatePermutation
